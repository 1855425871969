<template>
	<div class="sq-sobre">
		  <section class="page-sobre-01">
         <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-3" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6" />
        <div class="container">
            <div class="col-lg-12">
                <div class="banner-cs">
                    <div class="banner-cs-title text-center mb-5">
                        Uma nova forma do <br/>
                        time trabalhar junto, <br/>
                        mesmo à distancia 
                    </div>
                    <p class="banner-cs-body text-center">
                        Mantenha uma equipe engajada e produtiva mesmo na modalidade home office com os recursos de</br>
                        comunicação e colaboração do Squad. O trabalho remoto funciona melhor quando todos continuam</br>
                        conectados, sem necessariamente precisar estar fisicamente presentes em um mesmo lugar.</br>
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section class="page-sobre-02">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 d-flex align-items-center">
                    <div class="banner-cs">
                        <div class="banner-cs-title-md">
                            Trabalhe a qualquer hora, </br>
                            em qualquer lugar
                        </div>
                        <p class="banner-cs-body">
                            O Squad oferece recursos para que os times façam conexões em tempo real com colegas, de maneira móvel e simplificada, estando na sala ao lado ou em qualquer lugar do mundo. Para começar a Utilizar a ferramenta, não é necessário instalar nenhum tipo de aplicação, basta acessar a versão web pelo browser.</p>
                    </div>
                </div>
                <div class="col-lg-6 d-flex justify-content-lg-end align-items-center">
                    <img class="pg-col-img" src="https://citrus.cx/wp-content/uploads/2020/03/image-2.png" alt="Img">
                </div>
            </div>
        </div>
    </section>

    <section class="page-sobre-03">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="banner-cs">
                        <div class="banner-cs-title-sm">
                            Colabore </br>
                            simultaneamente </br>
                            com seus times
                        </div>
                        <p class="banner-cs-body" style="max-width: 300px;">
                            Com o Squad, sua equipe pode trocar mensagens através de chats individuais ou em grupos. Além do suporte de texto, também é possível enviar arquivos de imagem e áudios.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="banner-cs">
                        <div class="banner-cs-title-sm">
                            Agende e realize </br>
                            reuniões com várias </br>
                            pessoas na chamada
                        </div>
                        <p class="banner-cs-body">
                            Através de áudios e videoconferência, você realiza reuniões com salas fixas, envia links e convites, compartilha telas, arquivos e mensagens via chat durante as chamadas.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="banner-cs">
                        <div class="banner-cs-title-sm">
                            Produza conteúdo em </br>
                            tempo real e engaje </br>
                            as equipes
                        </div>
                        <p class="banner-cs-body">
                            Faça treinamentos e produções de </br>
                            conteúdos sem precisar delimitar um </br>
                            espaço físico, com o Squad realize </br>
                            webinars, lives de cursos, bate papos e </br>
                            desenvolva seu time.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="page-sobre-04">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6">
        <img class="bg" src="https://citrus.cx/wp-content/uploads/2020/03/cta-bg-1-2.png"/>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12 col-sm-12 d-flex">
                    <div class="banner-cs">
                        <div class="banner-cs-title">A empresa</div>
                        <p class="banner-cs-body" style="max-width: 570px;">
                            O Squad é uma ferramenta com tecnologia 100% nacional que faz parte do 
                            ecossistema Citrus, plataforma de experiência do cliente que unifica os canais de comunicação e registra os pontos de contato da jornada de atendimento e vendas dos clientes. <br><br>
                            A Citrus é uma empresa do Grupo Digivox, referência no país fornecendo soluções de 
                            comunicação unificada para grandes clientes públicos e privados, há mais de 14 anos. são 
                            mais de 200 mil usuários ativos e 70 milhões de chamadas por mês realizadas e 
                            monitoradas.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 d-flex">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <a href="http://citrus.cx" target="_blank"><img src="https://citrus.cx/wp-content/uploads/2020/03/logo-citrus.png" class="img mb-5" alt="Squad"></a>
                        <a href="http://digivox.com.br" target="_blank"><img src="https://citrus.cx/wp-content/uploads/2020/03/logo-digivox.png" class="img" alt="Digivox"></a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="page-sobre-05">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="banner-cs">
                        <div class="banner-cs-title-sm">
                            Missão
                        </div>
                        <p class="banner-cs-body">
                            Entregar soluções inovadoras que </br>
                            melhorem a experiência do cliente por </br>
                            meio de uma equipe comprometida e </br>
                            parcerias estratégicas.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="banner-cs">
                        <div class="banner-cs-title-sm">
                            Visão
                        </div>
                        <p class="banner-cs-body">
                            Ser referência por nossas soluções </br>
                            inovadoras de alto valor agregado, </br>
                            atuando globalmente como núcleo de </br>
                            sistema de comunicação, sendo líder em </br>
                            vendas no mercado empresarial nacional, </br>
                            através de alianças estratégicas e </br>
                            profissionais altamente capacitados.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="banner-cs">
                        <div class="banner-cs-title-sm mb-2">
                            Valores 
                        </div>
                        <p class="banner-cs-body">
                            <ul class="banner-cs-body-ul">
                                <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Espírito de equipe</li>
                                <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Comprometimento</li>
                                <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Foco no cliente</li>
                                <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Iniciativa e proatividade</li>
                                <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Aprendizagem Contínua</li>
                                <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Ética, respeito e confiança</li>
                                <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Criatividade e Inovação</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

	</div>
</template>

<script>

export default {
	
	metaInfo: {
      title: 'Sobre o Squad by Citrus'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
		
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
	}),
	

	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">

  
</style>
