<template>
	<div class="sq-justica">
        <section class="header_landingpages">
            <img src="https://citrus.cx/wp-content/uploads/2021/01/Marca_Citrus_EUA.png" class="main-logo" alt="Squad" />
        </section>
		<section class="page-justica-01">
            
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center col-lg-6 text-lg-left" style="align-self: center;">
                        <img src="https://citrus.cx/wp-content/uploads/2020/05/justice.png" class="justiceFrame">
                        <div class="banner-cs pt-4">
                            <div class="squareLeft">
                                <div class="banner-cs-title">
                                    Audiências podem ser rápidas e fáceis
                                </div>
                                <div class="banner-cs-body">
                                    A <strong>videoconferência</strong> é um mecanismo indispensável para a viabilização de atos processuais à distância.
                                    <br><br>
                                   <strong> Squad</strong> é a ferramenta de videoconferências ideal para a realização de audiências em geral. Com ela, os <strong>Órgãos do Poder Judiciário</strong> podem garantir a <strong>efetivação do acesso à Justiça de maneira remota e segura.</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="banner-cs">
                            <div class="box">
                                <div class="b1 banner-cs-body">
                                    Cadastre-se e saiba como o <strong>Squad</strong> vem ajudando juristas, magistrados e colegiados em todo o Brasil. 
                                </div>

                                    <form class="row" @submit.prevent name="justica_form" id="justica_form">
                                        <div class="left col-md-12">
                                            <div class="fieldset" :class="{ 'hasError': $v.form.name.$error }">
                                                <label>Nome:</label>
                                                <input type="text" name="name"  v-model="form.name">
                                            </div>
                                            <div class="fieldset" :class="{ 'hasError': $v.form.email.$error }">
                                                <label>E-mail:</label>
                                                <input type="email" name="email" v-model="form.email">
                                            </div>
                                            <div class="fieldset" :class="{ 'hasError': $v.form.phone.$error }">
                                                <label>Celular:</label>
                                                <input type="tel" name="phone" v-model="form.phone" v-mask="['(##) ####-####', '(##) #####-####']">
                                            </div>
                                        </div>
                                       <div class="fieldset">
                                            <input class="checkbox" id="styled-checkbox-1" type="checkbox" value="value1">
                                            <label for="styled-checkbox-1">Li e concordo com os <router-link to="/termos-de-uso">Termos de Uso</router-link>  e <router-link to="/politica-de-privacidade">Políticas de Privacidade</router-link></label>
                                        </div>
                                        <div class="summary text-red theerror" v-if="$v.form.$error">
                                            Ops, para continuar preencha os campos destacados!
                                        </div>
                                        <div class="col-md-12">
                                            <button type="submit" class="form_submit" @click="submitform">Cadastrar</button>
                                        </div>
                                      
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class=" page-02 page-cs-card">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
            <div class="container">
                <div class="row mb-5 w-90 mx-auto">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="card-cs">
                    <div class="card-cs-icon boximg box-re-1"></div>
                    <h3 class="card-cs-title">
                        Vídeoconferências em  grupos e individuais
                    </h3>
                    <p class="card-cs-body">
                        Realize chamadas de vídeo com várias pessoas simultâneamente, agende audiências e envie convites previamente para todos os envolvidos. 
                    </p>
                </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="card-cs">
                    <div class="card-cs-icon boximg box-share"></div>
                    <h3 class="card-cs-title">
                        Compartilhamento de arquivos
                    </h3>
                    <p class="card-cs-body">
                        Simplifique o envio de arquivos, mídias e documentos importantes previamente ou em tempo real durante reuniões pelo chat ou compartilhamento de  tela. 
                    </p>
                </div>
            </div>
           
   
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="card-cs">
                    <div class="card-cs-icon boximg box-re-5"></div>
                    <h3 class="card-cs-title">
                        Mobilidade para uso da ferramenta
                    </h3>
                    <p class="card-cs-body">
                       Acesse o Squad a partir de qualquer dispositivo móvel IOS e Android, desktop Mac Osx e Windows ou solução web que te livra de downloads. 
                    </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="card-cs">
                    <div class="card-cs-icon boximg box-re-6"></div>
                    <h3 class="card-cs-title">
                        Segurança e sigilo da informações
                    </h3>
                    <p class="card-cs-body">
                        Hospeado em infraestrutura própria da institução ou na provedora da solução, o armazenamento de dados seguem os protocolos de segurança alinhados à LGPD.
                    </p>
                </div>
            </div>
        </div>
            </div>
    </section>

    <section class="page-justica-03">
        <div class="container">
            <div class="card-cs">
                <div class="row">
                    <div class="col-md-7 flexcentered">
                        <h1>O Ministério Público da Paraíba testou e aprovou <br>o Squad</h1>
                    </div>
                    <div class="col-md-5 flexcentered">
                        <p class="quotes">
                            Realizamos a 1ª Sessão do Colégio de Procuradores do Ministério Público da Paraíba por videoconferência, através do Squad, um sistema atualizado e de fácil utilização. Acreditamos que o uso desse instrumento deve ser cada vez mais incentivado dentro das instituições públicas, diminuindo custos de viagens e riscos de deslocamento.
                            <strong>Francisco Seráfico <br> Procurador Geral de Justiça do MPPB</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="page-justica-04">
        <div class="container">
            <h1>Benefícios da videoconferência para realização de audiências</h1>
       
            <div class="row">
                <div class="col-md-12">
                <div class="card-cs w-100">
                     <p class="banner-cs-body">
                        <ul class="banner-cs-body-ul">
                            <li><img src="https://citrus.cx/wp-content/uploads/2020/05/checkss.png" class="topic"> Celeridade processual</li>
                            <li><img src="https://citrus.cx/wp-content/uploads/2020/05/checkss.png" class="topic"> Segurança pública</li>
                            <li><img src="https://citrus.cx/wp-content/uploads/2020/05/checkss.png" class="topic"> Economia de gastos públicos </li>
                            <li><img src="https://citrus.cx/wp-content/uploads/2020/05/checkss.png" class="topic"> Redução do adiamento de atos judiciais e extrajudiciais</li>
                            <li><img src="https://citrus.cx/wp-content/uploads/2020/05/checkss.png" class="topic"> Maior amplitude do princípio do juiz natural</li>
                            <li><img src="https://citrus.cx/wp-content/uploads/2020/05/checkss.png" class="topic"> Respeito à dignidade da pessoa humana possibilitado pelo “teleinterrogatório” </li>
                        </ul>
                    </p>
               

                </div>



                </div>
            </div>
        </div>
    </section>
      <section class="downdobra">
            <div class="container">
                <div class="block-title__text2"><span>Squad é um grande aliado <br>do judiciário na solução de conflitos.</span> </div>
            </div>
        </section>      
	</div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
	metaInfo: {
      title: 'Squad para a justiça'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
		
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
        form: {
            name: "",
            email:"",
            phone: "",
        },
	}),
	validations: {
        form: {
            name: { required },
            email: { required, email },
            phone: { required },
        },
        
      },

	mounted() {
		this.active = this.selected
	},

    methods: {
        submitform() {         
          this.$v.form.$touch();
          if(this.$v.form.$error) 
            
        return
           this.$router.push("justica-finalizado") 
        },
    }
}
</script>

<style lang="scss">
.header_landingpages{
    transform: translate(-50%);
    width: 107%;
    position: absolute;
    top: 0;
    border-radius: 0 0 144% 144%;
    left: 50%;
    background: #fff;
    text-align: center;
    -webkit-box-shadow: 0px 0px 0px #f8f8f8;
    box-shadow: 0px 1px 6px 0px #f8f8f8;
    margin: 0 auto;
    padding: 15px;
    z-index: 9999;

    img{
        width: 200px;
    }
}
.sq-justica{
    .block-title__text2 {
        margin: 0 0 30px 0!important;
        font-weight: bold!important;
        color: #ffffff;
        font-size: 34px!important;
        line-height: 44px!important;
        span{
            &:before{
                display:none;
            }
        }
    
    }
    .downdobra{
        padding: 70px 0;
    }
    overflow: hidden;
    h1{
        color: #454a58;
    }
    font-family: 'Poppins'!important;

    .box{
        box-shadow: 0px 0px 10px #f2f2f2;
        padding: 48px;
        border-radius: 10px;
        width: 87%;
        float: right;
    }
    .banner-cs-body{
        a{
            color: #9cb72e;
        }
    }
    .letters-small {
        font-size: 7pt !important;
    }
    .squareLeft{
        background: #fefefc;
        padding: 10px;

        .banner-cs-body{
            margin-bottom: 0;
            line-height: 154%;
            color: #49494c;
        }
    }
    .w-90{
        width: 90%;
    }
    form{

        .fieldset{
            label{
                width: 100%;
                color: #6e6868;
                font-size: 14px;
                margin: 0;
                padding: 7px 0 0px 13px;
            }
            input {
                width: 100%;
                border-radius: 30px;
                padding: 1px 15px;
                border: solid 2px #ebebeb;
                outline: none;
                font-size: 14px;

                &:focus{
                    border-color: #9cb63b;
                }
            }
            select {
                width: 100%;
                border-radius: 30px;
                padding: 1px 15px;
                border: solid 2px #ebebeb;
                outline: none;
                font-size: 14px;

                &:focus{
                    border-color: #9cb63b;
                }
            }
        }
        .form_submit{
            display: block;
            margin: 37px auto 0;
            background: #9cb63b;
            text-align: center;
            font-size: 15px;
            border-radius: 40px;
            padding: 12px;
            max-width: 310px;
            width: 100%;
            float: none;
            cursor: pointer;
            color: #fff;
            border: solid 1px #9cb63b;
        }
        .checkbox {
          position: absolute;
          opacity: 0;
        }
        .checkbox + label {
            position: relative;
            cursor: pointer;
            padding: 0;
            font-size: 13px !important;
            margin-left: 18px !important;
         /*   display: flex;
            align-items: flex-start;*/
            margin-top: 20px;  
            padding-left: 30px;
            
        }
        .checkbox + label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 21px;
            border-radius: 15px;
            position: absolute;
            height: 21px;
            background: #ffffff;
            margin-top: 2px;
            border: solid 2px #eee;
            left: 0;
        }
        .checkbox:hover + label:before {    
            background: #8BC34A;
        }
        .checkbox:focus + label:before {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }
        .checkbox:checked + label:before {
        background: #8BC34A;
        }
        .checkbox:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }
        .checkbox:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }
        .checkbox:checked + label:after {
            content: '';
            position: absolute;
            left: 5.5px;
            top: 8px;
            background: white;
            width: 9.5px;
            height: 9px;
            border-radius: 52px;
        }
    }
.b1{
    line-height: 138%;
    max-width: 100%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 13px;

    strong{
        color: #8BC34A;
        font-weight: 600;
    }
}
.btn-200 {
    width: 200px;
}

.text-green {
    color: #9cb72e !important;
}

.box-line {
    height: 100px;
    width: 10px;
    border-radius: 5px;
    border: #c4e739 solid 5px;
}

.img-squad-do-bem{
    margin-bottom: 70px;
}

.page-justica-01 {
    position: relative;
    margin-top: 24vh;
    position: relative;

    .justiceFrame{
        position: absolute;
        top: -23vh;
        left: -16vw;
        z-index: -1;
    }
}

.page-justica-01 .bubble-1 {
    position: absolute;
    top: 0%;
    left: 10%;
}

.page-justica-01 .bubble-2 {
    position: absolute;
    top: 100%;
    left: 90%;
}

.page-justica-01 .bubble-3 {
    position: absolute;
    top: 2%;
    left: 90%;
}

.page-justica-01 .bubble-4 {
    position: absolute;
    top: 20%;
    left: 75%;
}

.page-justica-01 .bubble-5 {
    position: absolute;
    top: 22%;
    left: 25%;
}

.page-justica-01 .bubble-6 {
    position: absolute;
    top: 100%;
    left: 6%;
}

.page-justica-01 [class*=bubble-] {
    position: absolute;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-perspective: 400px;
    perspective: 400px;
}

.page-justica-02 {
    position: relative;
    margin-top: 50px;
}
.page-02{
    margin-top: 8rem;

    .boximg{
        width: 55px;
        height: 55px;
    }
    .card-cs{
        height: 335px;
        padding: 7vh 1.7vw 4vh 2vw;
        .box-share {
            background-image: url(https://citrus.cx/wp-content/uploads/2020/05/share.png);
        }
        .card-cs-title {
            margin: 0;
            font-size: 17px;
            line-height: 34px;
            font-weight: 600;
            margin-top: 25px;
            margin-bottom: 20px;
        }
        .card-cs-body {
            color: #666E82;
            font-size: 15px;
            line-height: 31px;
            margin: 0;
            -webkit-transition: all .4s ease;
            transition: all .4s ease;
        }
    }
}
.page-justica-03 {
    position: relative;
    margin-top: 80px;
}
.page-justica-03 h1{
    font-weight: bold;
    color: #454a58;
}
.page-justica-03 .bubble-1 {
    position: absolute;
    top: 23%;
    left: 10%;
}

.page-justica-03 .bubble-2 {
    position: absolute;
    top: 100%;
    left: 90%;
}
.page-justica-03 .quotes{
    position: relative;
    padding-top: 64px;
    width: 100%;
    line-height: 200%;
    color: #454a58;
    float: right;
    font-size: 15px;
    strong{
        text-align: right;
        clear: both;
        width: 100%;
        float: left;
    }
    &:before{
        content: '';
        background: url(https://citrus.cx/wp-content/uploads/2020/05/quote.png);
        position: absolute;
        top: 0;
        left: 0;
        width:55px;
        height: 45px;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.page-justica-03 .card-cs{
    height: auto!important;
    margin-bottom: 8rem;
    padding: 8vh 3.6vw 6vh!important; 
}

.page-justica-04 {
    position: relative;
    margin-top: 80px;
    margin-bottom: 34px;
}

.page-justica-04 h1 {
    text-align: center;
    max-width: 60%;
    margin: 0 auto 50px;
    font-weight: bold;
}

.page-justica-04 .card-cs {
    max-height: 460px;
    height: auto;
    padding: 8vh 3.6vw 6vh; 
}

.page-justica-04 .banner-cs-body-ul {
    list-style-type: none !important;
    margin: 0;
    padding: 0;
    font-size: 21px;
    line-height: 39px;
    color: #7e818a;
    font-weight: 500;

}

.page-justica-04 .topic {
    width: 21px;
    margin-right: 20px;
}

.page-justica-04 .bubble-5 {
    position: absolute;
    top: 27%;
    left: 28%;
}

.page-justica-04 .bubble-6 {
    position: absolute;
    top: 90%;
    left: 9%;
}

.page-justica-04 [class*=bubble-] {
    position: absolute;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-perspective: 400px;
    perspective: 400px;
}

.page-justica-05 {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.page-justica-05 .bubble-1 {
    position: absolute;
    top: 30%;
    left: 85%;
}

.page-justica-05 .bubble-2 {
    position: absolute;
    top: 67%;
    left: 5%;
}

.page-justica-05 [class*=bubble-] {
    position: absolute;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-perspective: 400px;
    perspective: 400px;
}
}

@media(max-width: 560px){
    .sq-justica .page-justica-01 .justiceFrame {
        position: absolute;
        top: -28vh;
        left: 0;
        z-index: -1;
    }
    .header_landingpages img {
        width: 100px;
    }
    .sq-justica .banner-cs .banner-cs-title{
        line-height: 40px;
    }
    .banner-cd-title{
        font-size: 30px;
        line-height: 146%;
    }
    .sq-justica .page-justica-03 h1{
        font-size: 27px;
    }
    .page-justica-01 .box{
        width: 100%;
        padding: 30px;
    }
    .sq-justica .page-justica-03 .quotes:before {
        content: '';
        background: url(https://citrus.cx/wp-content/uploads/2020/05/quote.png);
        position: absolute;
        top: 12px;
        left: 0;
        width: 48px;
        height: 36px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sq-justica .page-justica-03 .quotes strong{
        padding-top: 18px;
    }
    .sq-justica .page-justica-04 h1{
        max-width: 100%;
        font-size: 26px;
    }
    .sq-justica .page-02 .card-cs {
        padding: 3vh 5vw;
        height: auto;
    }
    .sq-justica .page-justica-04 .banner-cs-body-ul {
        list-style-type: none !important;
        margin: 0;
        padding: 0;
        font-size: 17px;
        line-height: 23px;
        color: #7e818a;
        font-weight: 500;

        li{
            display: flex;
            flex-flow: row;
            align-items: center;
            margin-bottom: 11px;
        }
    }
    .sq-justica .w-90 {
        width: 100%;

        .col-sm-12{
            padding: 0;
        }
    }
    .header_landingpages{
        width: 100%!important;
    }
}

</style>
