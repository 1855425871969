<template>
	<div class="ci-button-icon"
		:class="[
			`ci-button-icon--${theme}`,
			`ci-button-icon--${size}`,
		]"
		@click="$emit('click', $event)"
	>
		<span class="ci-button-icon__icon"
			:class="[
				name
			]"
		>
		</span>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			default: 'close'
		},
		theme: {
			type: String,
			default: 'primary'
		},
		size: {
			type: String,
			default: 'md' // sm | md | lg
		},
	}
}
</script>

<style lang="scss">
.ci-button-icon{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;
	background: transparent;
	cursor: pointer;
	transition: .2s $easeInOutQuad;
	&__icon{
		color: white;
	}

	// Sizes
	
	&--md{
		width: 62px;
		height: 62px;
		span{
			font-size: 65px!important;text-shadow: 0px 0px 20px #d3d3d3;
		}
	}
	

	// Themes
	&--primary{
		background: transparent!important;
		&:hover{
			background: transparent!important;
		}
	}
	
}
</style>
