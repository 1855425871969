<template>
		
  <div id="app" class="ci-app">
		<transition name="loading">
			<ci-loading
				v-if="
					loadingState != 'hide' &&
					(
						$route.name == 'home' 
						|| $route.name == 'sobre' 
						|| $route.name == 'termos' 
						|| $route.name == 'termosusuario' 
						|| $route.name == 'recursos' 
						|| $route.name == 'politica'
						|| $route.name == 'politicauso'
						|| $route.name == 'social'
						|| $route.name == 'faq'
						|| $route.name == 'demo'
						|| $route.name == 'cadastro_usuarios'
						|| $route.name == 'prov'
						|| $route.name == 'influenciador'
						|| $route.name == 'solucoes'
						|| $route.name == 'parajustica'
						|| $route.name == 'justicafinal'
					)
				"
				class="ci-app__loading"
				:state="loadingState"
			/>
		</transition>

		<div class="ci-app__body">
			<ld-header class="ci-app__header" v-if="$route.name != 'influenciador' && $route.name != 'parajustica'  && $route.name != 'justicafinal'"/>
			<transition name="router" mode="out-in">
				<router-view
					class="ci-app__view"
					:scroll="scroll"
					v-if="initialized"
					:key="$route.path"
				/>
			</transition> 

			<ld-footer class="ci-app__footer" 
				v-if="$route.name != 'cadastro_usuarios' &&  $route.name != 'prov' && $route.name != 'influenciador' && $route.name != 'justicafinal'" />
		</div>

  </div>
 
</template>

<script>
import LDHeader from '@/components/organisms/LDHeader'
import LDFooter from '@/components/organisms/LDFooter'
import LDHome from '@/components/pages/LDHome'
import CILoading from '@/components/molecules/CILoading'
import { disableBodyScroll } from 'body-scroll-lock'
import { TweenMax, Power2 } from 'gsap/TweenMax'
import { mapGetters } from 'vuex'
import $ from 'jquery'

require('../public/css/redefine.css');
require('../public/css/style.css');
require('../public/css/responsive.css');
require('../public/css/animate.css');
require('../public/js/theme.js');

export default {
	components: {
		'ld-header': LDHeader,
		'ld-footer': LDFooter,
		'ci-loading': CILoading,
	},

	computed: {
		...mapGetters({
			contact: 'page/getContact',
			menu: 'menu/getMenu',
     		alert: 'alert/getCurrent',
			lang: 'getLang'
		})
	},

	data: () => ({
		initialized: false,
		selected: '',
		scroll: 0,
		sidenav: false,
		sections: [],
		scrollTimer: null,
		sending: false,
		loadingState: 'idle',
		brand: 'images/lemon-desk.png'
	}),

	watch: {
		initialized(value) {
			if (value)
				this.$nextTick(this.init)
		}
	},

	created() {
		// Fetch pages data
		Promise.all([
			this.$store.dispatch('page/fetch'),
			this.$store.dispatch('menu/fetch')
		])
			.then(([pages, menu]) => {
				// Show the page
				this.initialized = true

				// Populates sections data
				this.sections = menu.map(item => item.link)
				this.sections.push('#solutions')

				// Delay to loading exit
				setTimeout(() => {
					this.loadingState = 'done'

					setTimeout(() =>
						this.loadingState = 'hide'
					, 500)
				}, 100)
			})
	},

	mounted() {
		// Start loading
		this.loadingState = 'loading'

		$(window).scroll(function () {
		    if ($(this).scrollTop() > 600) {
		      $('.scroll-top').fadeIn();
		    } else {
		      $('.scroll-top').fadeOut();
		    }
		  });

		  $('.scroll-top').click(function () {
		    $("html, body").animate({
		      scrollTop: 0
		    }, 100);
		      return false;
		  });
	},

	methods: {
		init() {
			this.$el.querySelector('.ci-app__body')
				.addEventListener('scroll', this.onScroll)
			disableBodyScroll(this.$el.querySelector('.ci-app__body'))
		},
	}
}
</script>

<style lang="scss">
	.flexcentered{
		display: flex;
		align-items: center;
	}
a, a:hover {
    text-decoration: none!important;
    cursor: pointer!important;
}
.ci-app__body{
	height: 100vh!important;
	max-width: 100vw;
}
.ci-app__header{
	z-index: 9999!important;

}
@media(max-width: 580px){

	.bubble-1,
	.bubble-2,
	.bubble-3,
	.bubble-4,
	.bubble-5,
	.bubble-6{
		display: none!important;
	}
	.banner-cs .banner-cs-title {
	    margin: 0;
	    font-weight: bold;
	    color: #49494c;
	    font-size: 35px;
	    line-height: 57px;
	    margin-bottom: 20px;
	}

	.ci-app__body{
	   overflow-y: scroll!important;
	  -webkit-overflow-scrolling: touch!important;
	}
}
 select {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
      padding: 1px 15px;
    }
</style>
