<template>
	<div class="ld-player"
		:class="[
			`ld-player--${theme}`,
			`ld-player--${aspec}`
		]"
	>
		<div class="ld-player__thumbnail">
		</div>

		<ci-button-icon
			class="ld-player__play"
			name="fa fa-play"
			:theme="theme"
			@click="playing = true"
		/>

		<transition name="fade">
			<div class="ld-player__video" v-if="playing" v-html="video">
			</div>
		</transition>
	</div>
</template>

<script>
import CIButtonIcon from '@/components/atoms/CIButtonIcon'

export default {
	components: {
		'ci-button-icon': CIButtonIcon
	},

	props: {
		thumbnail: {
			type: String,
			default: 'images/video-thumbnail.png'
		},
		theme: {
			type: String,
			default: 'primary'
		},
		aspec: {
			type: String,
			default: 'wider' // wide | wider | square
		},
		video: {
			type: String,
			default: '<iframe src="https://www.youtube.com/embed/ryCVbVK-VsU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
		}
	},

	data: () => ({
		playing: false
	})
}
</script>

<style lang="scss">
.ld-player{
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	width: 100%;
	z-index: 10;
	    box-shadow: 0px 0px 7px 0px #e7e7e7;
	&__thumbnail{
		position: absolute;
		background-color: rgba(black, .2);
		background-position: center;
		background-size: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		transition: transform $easeInOutQuad 1s .25s;
		background: url(https://citrus.cx/wp-content/uploads/2020/04/miniaturavideohome.png) no-repeat;
		background-size: cover!important;
	}
	&__play{
		position: absolute;
		top: 50%;
		left: 50%;
		transition: transform $easeInQuad .3s;
		transform: translate(-50%, -50%);
	}
	&__video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: black;
		iframe{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	&:after{
		content: "";
		display: block;
	}

	&--wider{
		&:after{
			padding-top: 65%;
		}
	}
	&--wide{
		&:after{
			padding-top: 75%;
		}
	}
	&--square{
		&:after{
			padding-top: 100%;
		}
	}

	@include grid-media($g-desktop-up) {
		.ld-player{
			&__play{
				&:hover{
					transform: translate(-50%, -50%) scale(1.2);
				}
			}
		}
		&:hover{
			.ld-player{
				&__thumbnail{
					transform: scale(1.05);
				}
			}
		}
	}
}
</style>
