<template>
	<div>
		<header class="site-header header-one">
            <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky">
                <div class="container clearfix">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="logo-box clearfix">
                        <router-link class="navbar-brand" to="/">
                            <img src="https://citrus.cx/wp-content/uploads/2021/01/Marca_Squad_Cinza.png" class="main-logo" alt="Squad" />
                        </router-link>
                        <div class="menu-toggler nav-icon">
                            <div data-target=".header-one .main-navigation"></div>
                        </div>
                    </div><!-- /.logo-box -->
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="main-navigation">
                        <ul class=" navigation-box one-page-scroll-menu ">
                            <li class="current scrollToLink">
                                <a>Conheça o Squad</a>
                                <ul class="sub-menu">
                                    <li><router-link to="/sobre">Sobre nós</router-link></li>
                                    <li><router-link to="/social">Social</router-link></li>
                                </ul><!-- /.sub-menu -->
                            </li>
                            <li class="scrollToLink">
                                <router-link to="recursos">Recursos</router-link>
                            </li>
                            <li class="scrollToLink">
                                <router-link to="/solucoes">Soluções</router-link>  
                                 <ul class="sub-menu solsub">
                                    <li><a href="http://webdoctor.com.br" target="_blank">Squad para Saúde</a></li>
                                    <li><router-link to="/squad-para-justica">Squad para Justiça</router-link></li>
                                </ul><!-- /.sub-menu -->                             
                            </li>
                            
                        </ul>
                    </div><!-- /.navbar-collapse -->
                    <div class="right-side-box">
                        <a href="https://app.citrussquad.com/login" target="_blank" class="header-one__btn btnDark">Entrar</a>
                        <a href="/demo" class="header-one__btn btnLight">Aprender a usar</a>
                    </div>
                </div>
                <!-- /.container -->
            </nav>
        </header><!-- /.header-one -->
	</div>
</template>

<script>
import $ from 'jquery'
export default {
	
    mounted() {
        $(window).on('scroll', function() {
               if ($('.scroll-to-top').length) {
                var strickyScrollPos = 100;
                if ($(window).scrollTop() > strickyScrollPos) {
                    $('.scroll-to-top').fadeIn(500);
                } else if ($(this).scrollTop() <= strickyScrollPos) {
                    $('.scroll-to-top').fadeOut(500);
                }
            }
            if ($('.stricky').length) {
                var headerScrollPos = 100;
                var stricky = $('.stricky');
                if ($(window).scrollTop() > headerScrollPos) {
                    stricky.removeClass('slideIn animated');
                    stricky.addClass('stricky-fixed slideInDown animated');
                } else if ($(this).scrollTop() <= headerScrollPos) {
                    stricky.removeClass('stricky-fixed slideInDown animated');
                    stricky.addClass('slideIn animated');
                }
            }
        });
         if ($('.main-navigation .navigation-box .sub-menu').length) {
        var subMenu = $('.main-navigation .sub-menu');
        subMenu.parent('li').children('a').append(function() {
            return '<button class="sub-nav-toggler"> </button>';
        });
        var mainNavToggler = $('.header-navigation .menu-toggler');
        var subNavToggler = $('.main-navigation .sub-nav-toggler');
        mainNavToggler.on('click', function() {
            var Self = $(this);
            var menu = Self.data('target');
            $(menu).slideToggle();
            $(menu).toggleClass('showen');
            return false;
        });
        subNavToggler.on('click', function() {
            var Self = $(this);
            Self.parent().parent().children('.sub-menu').slideToggle();
            return false;
        });
    }
        $(".nav-icon").click(function(){
            $(".main-navigation").slideToggle()
        });
        $(".main-navigation").click(function(){
            $(this).slideUp();
        })
    },    
}
</script>

<style lang="scss">
      .nav-icon {
        display: none;
      }
@media(max-width: 768px){
    .nav-icon {
        margin: 0 1em;
        width: 30px;
        position: absolute;
        right: 0;
        display: block;
        z-index: 9999;
    }

    .nav-icon:after, 
    .nav-icon:before, 
    .nav-icon div {
        background-color: #a1a1a1;
        border-radius: 3px;
        content: '';
        display: block;
        height: 4px;
        margin: 5px 0;
        transition: all .2s ease-in-out;
    }
}
@media(min-width: 990px){
    .solsub{
        margin-left: -53%;
    }
}
</style>
