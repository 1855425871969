<template>
	<sq-termosusuario
		id="termosusuario"
	/>
</template>

<script>
import SQTermosUsuario from '@/components/templates/SQTermosUsuario'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-termosusuario': SQTermosUsuario
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
