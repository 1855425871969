export default {
  pt: {
    about: {
      subhead: 'Sobre o Citrus'
    },
    suite: {
    	genesys: 'O Ecossistema Citrus se integra com suítes de comunicação do mercado, como o Unity, da Digivox e as plataformas omnichannel da Genesys. Se sua empresa já possui uma solução de comunicação, ou outros canais de mensagens instalados, também pode se integrar com as aplicações da família Citrus de Experiência do Cliente.'
    },
	lemonade: {
      hero: [
        'A Experiência',
        'do Cliente como',
        'você nunca viu.'
      ],
		about: 'Sobre o'
	},
	seed: {
		transform: {
			title: [
				'Transformando',
				'Limões em',
				'Limonada'
			]
		},
	},
	contact: {
		name: 'Nome',
		email: 'E-mail',
		company: 'Empresa',
		phone: 'Telefone',
		subject: 'Assunto',
		message: 'Mensagem',
		submit: 'Enviar',
		sending: 'Enviando'
	},
    footer: {
      group: `
Uma empresa do grupo <a href="https://digivox.com.br/" target="_blank">Digivox</a>
      `
    },
    hero: {
    	url: "/wp-content/themes/citrus-cx/dist/images/citrus-screenpt.png"
    },
    press: {
    	title: "Citrus na <STRONG>imprensa</STRONG>"
    },
    demonstracao: {
    	text: "AGENDE UMA DEMONSTRAÇÃO"
    },
    book: {
    	text: "BAIXE NOSSO E-BOOK AGORA"
    },
    ftCopy: {
      rights: "Todos os direitos reservados"
    }
  },

  en: {
    about: {
      subhead: 'About Citrus'
    },
    suite: {
    	genesys: 'You can integrate Citrus Ecosystem with any cloud communication solution available on the market. Platforms such as Unity by Digivox and Pure Cloud by Genesys already have native plugins and APIs, and no extra effort for integration is needed. Even if your company still have an "On-Prem" communication solution or other message channels installed, you can also integrate with any of the Customer Experience Citrus’ APPs.'
    },
	lemonade: {
      hero: [
        'Customer experience',
        `as like you've never`,
        'seen before.'
      ],
		about: 'About the'
	},
	seed: {
		transform: {
			title: [
				'Transforming',
				'Lemons into',
				'Lemonade'
			]
		},
	},
	contact: {
		name: 'Name',
		email: 'E-mail',
		company: 'Company',
		phone: 'Phone',
		subject: 'Subject',
		message: 'Message',
		submit: 'Submit',
		sending: 'Sending'
	},
	footer: {
		group: `
A <a href="https://digivox.com.br/" target="_blank">Digivox</a> Business
  `
	},
    hero: {
    	url: "/wp-content/themes/citrus-cx/dist/images/citrus-screeneng.png"
    },
    press: {
    	title: "Citrus in <STRONG>the Media</STRONG>"
    },
    demonstracao: {
    	text: "SCHEDULE A DEMONSTRATION"
    },
    book: {
    	text: "DOWNLOAD OUR E-BOOK NOW"
    },
    ftCopy: {
      rights: "All rights reserved"
    }
  }
}
