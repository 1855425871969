<template>
	<div class="sq-prov">
		<section class="page-prov-01">
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6" />
            <div class="container">
                <div class="col-lg-12">
                    <div class="banner-cs">
                        <div class="banner-cs-title titleone text-center mb-3">
                            <span>Recebemos a sua solicitação!</span>
                        </div>
                        <p class="banner-cs-subtitle text-center">
                           Estamos processando o cadastro dos novos usuários. Em instantes eles receberão e-mail confirmando o cadastro. <br> Obrigado.
                        </p>
                    </div>
                </div>
            </div>
        </section>  
	</div>
</template>
<script>
export default {
	
	metaInfo: {
      title: 'Citrus Squad'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
	}),
	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">

.sq-prov{
    padding: 50px 0;
    .provvideo {
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
    }
    .provvideo iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .btc{
        span{
            position: relative;
        }
        span:before{
          content: '';
          background-color: #9cb72e;
          opacity: 0.402;
          width: 100%;
          height: 7px;
          position: absolute;
          bottom: 4px;
          left: 0;
          border-radius: 4px;
          z-index: -1;
        }
    }
    font-family: "Poppins"!important;
    .banner-cs-title {
        span{
            position: relative;
            
            small{
                font-size: 100%!important;
                color: #9cb72e;
                font-weight: bold;
            }
        }
        
        
    }
    .banner-cs-subtitle {
        font-size: 24px;
        /* font-weight: bold; */
        color: #49494c;
        line-height: 155%;
        max-width: 700px;
        margin: 0 auto;
        small{
            font-weight: 500;
            font-size: 60%;
            line-height: 67px;
        }

        img{
            transform: rotate(90deg);
        }
    }
    .page-prov-01 {
        padding-top: 150px;
        position: relative;
    }

    .page-prov-01 .bubble-1 {
        position: absolute;
        top: 23%;
        left: 10%;
    }

    .page-prov-01 .bubble-2 {
        position: absolute;
        top: 100%;
        left: 90%;
    }

    .page-prov-01 .bubble-3 {
        position: absolute;
        top: 18%;
        left: 90%;
    }

    .page-prov-01 .bubble-4 {
        position: absolute;
        top: 50%;
        left: 75%;
    }

    .page-prov-01 .bubble-5 {
        position: absolute;
        top: 42%;
        left: 25%;
    }

    .page-prov-01 .bubble-6 {
        position: absolute;
        top: 100%;
        left: 6%;
    }

    .page-prov-01 [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }

    .page-prov-02 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .page-prov-02 .pg-col-img {
        width: 80%;
        opacity: 1;
        transition-duration: .5s;
    }

    .page-prov-02 .pg-col-img:hover {
        opacity: .7;
        transition-duration: .5s;
    }

    .page-prov-03 {
        position: relative;
        margin-top: 80px;
    }

    .page-prov-03 .bubble-1 {
        position: absolute;
        top: 23%;
        left: 10%;
    }

    .page-prov-03 .bubble-2 {
        position: absolute;
        top: 100%;
        left: 90%;
    }

    .page-prov-03 [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }

    }


  
</style>
