<template>
	<div class="sq-influenciadores">
		<section class="page-influenciadores-01">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="banner-cs t">
                            <div class="banner-cs-title titleone mb-3">
                                <span>Influenciador do Bem: conectando pessoas e propósitos</span>
                            </div>
                            <p class="banner-cs-body">
                               Em tempos de crise, queremos oferecer ao mundo um momento de alegria e otimismo. Junte-se a nós no programa Influenciador do Bem, mostrando às pessoas como é fácil estarmos lado a lado, mesmo distantes.
                            </p>

                            <p class="banner-cs-subtitle">
                               O mundo precisa dé nós
                            </p>
                            <a class="page-one__btn scroll-form">
                                Quero ser um Influenciador do Bem
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src="https://citrus.cx/wp-content/uploads/2020/04/page-01-in.png">
                    </div>
                </div>
            </div>
        </section>
        <section class="page-influenciadores-02">
            <div class="container">
                <div class="row mobiled">
                    <div class="col-lg-4 col-md-12 d-flex justify-content-center img-squad-do-bem">
                        <img src="https://citrus.cx/wp-content/uploads/2020/03/logo-squad-do-bem.png" class="dobem" alt="SQUAD">
                    </div>
                     <div class="col-lg-8 col-md-12 banner-cs pb-0 cust">
                        <div class="banner-cs-title titleone mb-3">
                            <span>SQUAD DO BEM: <br>Por dentro da iniciativa</span>
                        </div>
                        <p class="banner-cs-body mb-0 custom">
                           Chegou o momento de provar que a distância não impede a presença, e lembrar que os desafios fortalecem, unem e impulsionam a criatividade e a inovação.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-7">
                        <div class="banner-cs">
                            <p class="banner-cs-body mb-3">
                                Foi pensando em <b>Empatia, Solidariedade e Colaboração</b> que criamos o Squad, uma ferramenta de comunicação que oferece ao mundo a possibilidade de nos conectarmos, independente da quilometragem.
                            </p>
                             <p class="banner-cs-body mb-3">
                                Integrando recursos de chat, voz e videoconferência, buscamos oferecer um meio seguro para que empresas continuem suas operações a partir do novo modelo que o mercado propõem: o trabalho remoto.
                            </p>
                             <p class="banner-cs-body mb-3">
                                Seja parte desse movimento e mostre ao mundo que o home office pode ser um momento colaborativo, eficiente e feliz.
                            </p>

                            </p>
                        </div>

                    </div>
                    <div class="col-md-5">
                        <img class="w-100" src="https://citrus.cx/wp-content/uploads/2020/04/iniciativa.png">
                    </div>
                </div>
            </div>
        </section>
        <section class="page-influenciadores-03">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="banner-cs">
                            <div class="banner-cs-title titleone mb-3">
                                <span>Benefícios</span>
                            </div>
                            <p class="banner-cs-body bodies">
                               Além de estarmos andando lado a lado para proporcionar ao mercado <br> uma solução colaborativa, você também terá como benefício:
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="container page-cs-card">
                <div class="row mb-5">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div class="card-cs">
                            <div class="card-cs-icon boximg box-ben-1"></div>
                            <h3 class="card-cs-title thistitle">
                                Gerente de Conta Estratégico
                            </h3>
                            <p class="card-cs-body thisbody">
                                Disponibilidade de um gerente de conta dedicado, focado na cocriação de estratégias para melhores resultados e insights criativos.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div class="card-cs">
                            <div class="card-cs-icon boximg box-ben-2"></div>
                            <h3 class="card-cs-title thistitle">
                                Kit do Influenciador
                            </h3>
                            <p class="card-cs-body thisbody">
                                Kit com diversos materiais de apoio à divulgação como orientações sobre o produto e a iniciativa, mídia kit e muito mais!
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div class="card-cs">
                            <div class="card-cs-icon boximg box-ben-3"></div>
                            <h3 class="card-cs-title thistitle">
                                Licença gratuita do Squad
                            </h3>
                            <p class="card-cs-body thisbody">
                                Criação de uma conta gratuita no Squad para uso próprio do Influenciador.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div class="card-cs">
                            <div class="card-cs-icon boximg box-ben-4"></div>
                            <h3 class="card-cs-title thistitle">
                                Remuneração & Trial estendido
                            </h3>
                            <p class="card-cs-body thisbody">
                                Contrato remunerado para divulgação do Squad e disponibilidade de oferta aos seguidores de trial de 90 dias.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div class="card-cs">
                            <div class="card-cs-icon boximg box-ben-5"></div>
                            <h3 class="card-cs-title thistitle">
                                Participação da iniciativa "Abraço Virtual"
                            </h3>
                            <p class="card-cs-body thisbody">
                                Iniciativa focada em reconhecer os influenciadores com melhores resultados com brindes e bônus da remuneração.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div class="card-cs">
                            <div class="card-cs-icon boximg box-ben-6"></div>
                            <h3 class="card-cs-title thistitle">
                                Webinars & Podcasts
                            </h3>
                            <p class="card-cs-body thisbody">
                                Acesso a Webinars e Podcasts exclusivos sobre o programa, Squad e dicas de conteúdos. 
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <section class="page-influenciadores-04">
            <div class="first-dbr">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="banner-cs">
                                <div class="banner-cs-subtitle d-flex justify-content-center ">
                                    <b>Conheça também o nosso Programa de Afiliados SEED, focado em disseminar a suíte de produtos do ecossistema Citrus CX.</b>   
                                </div>
                                <a class="page-one__btn mt-5 justify-content-center">
                                    Quero conhecer!
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="video-dbr">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <br><br><br><br><br><br><br><br>
                        </div>
                    </div>
                </div>
            </div>
        </section>
           <section class="page-influenciadores-05">
            <div class="fifth-dbr">
                <div class="container">
                    <div class="col-lg-11 mx-auto col-md-12 card-form">
                        <div class="banner-cs">
                            <div id="msgthanku" class="banner-cs-subtitle titleone mb-3 titles justify-content-center">
                                Ficamos muito felizes pelo seu interesse em fazer parte do Influenciadores do Bem. Em breve, retornaremos com mais informações e detalhes 🙂
                            </div>
                            <div class="banner-cs-subtitle titleone mb-3 titles justify-content-center">
                                <span>Inscreva-se agora</span>
                            </div>
                            <form class="row" @submit.prevent name="influenciadores_form" id="influenciadores_form">
                                <div class="left col-md-6">
                                    <div class="fieldset" :class="{ 'hasError': $v.form.name.$error }">
                                        <label>Nome:</label>
                                        <input type="text" name="name"  v-model="form.name">
                                    </div>
                                    <div class="fieldset" :class="{ 'hasError': $v.form.email.$error }">
                                        <label>E-mail:</label>
                                        <input type="email" name="email" v-model="form.email">
                                    </div>
                                    <div class="fieldset" :class="{ 'hasError': $v.form.phone.$error }">
                                        <label>Telefone:</label>
                                        <input type="tel" name="phone" v-model="form.phone" v-mask="['(##) ####-####', '(##) #####-####']">
                                    </div>
                                    <div class="fieldset">
                                        <label>Você é:</label>
                                        <select id="cf_tipo" name="cf_tipo" v-model="form.tipo">
                                            <option>Pessoal física</option>
                                            <option>Pessoal jurídica</option>
                                        </select>
                                    </div>
                                    <div class="fieldset" :class="{ 'hasError': $v.form.cpf.$error }">
                                        <label>CPF/CNPJ:</label>
                                        <input type="tel" id="cf_cpf" name="cf_cpf" v-mask="['###.###.###-##', '##.###.###/####-##']"  v-model="form.cpf">
                                    </div>
                                </div>
                                <div class="right col-md-6">
                                    <div class="fieldset">
                                        <label>Possui site e/ou blog? Se sim, qual é o link dele?</label>
                                        <input type="text" name="cf_site" id="cf_site">
                                    </div>
                                    <div class="fieldset">
                                        <label>Qual é o tamanho da sua audiência no último mês?</label>
                                        <select v-model="form.acessos" name="qtdacessos" id="qtdacessos">
                                            <option>Até 1.000 acessos</option>
                                            <option>Até 10.000 acessos</option>
                                            <option>Até 50.000 acessos</option>
                                            <option>acima de 50.000 acessos</option>
                                            <option>Não sei</option>
                                        </select>
                                    </div>
                                    <div class="fieldset">
                                        <label>Possuí instagram? Se sim, nos diga seu usuário:</label>
                                        <input type="text" name="cf_instagramuser" id="cf_instagramuser">
                                    </div>
                                    <div class="fieldset" :class="{ 'hasError': $v.form.base.$error }">
                                        <label>Qual é o tamanho da sua base de seguidores?</label>
                                        <select v-model="form.base" name="cf_base_seguidores" id="cf_base_seguidores">                                        
                                            <option>Até 1.000 seguidores</option>
                                            <option>Até 5.000 seguidores</option>
                                            <option>Até 10.000 seguidores</option>
                                            <option>Até 50.000 seguidores</option>
                                            <option>acima de 50.000 seguidores</option>
                                        
                                        </select>
                                    </div>
                                    <div class="fieldset">
                                        <label>Como você ficou sabendo do nosso Programa Influenciador do Bem?</label>
                                        <select v-model="form.meio" id="cf_meios" name="cf_meios">
                                            <option>Pesquisa (Goolge e outros)</option>
                                            <option>Site do Squad</option>
                                            <option>Mídias sociais</option>
                                            <option>Amigos</option>
                                            <option>Outros</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="summary text-red theerror" v-if="$v.form.$error">
                                    Ops, para continuar preencha os campos destacados!
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="form_submit" @click="submit_PF">Quero fazer parte do movimento</button>
                                   <!-- <div >QUERO FAZER PARTE DO MOVIMENTO</div> -->
                                </div>
                                <div class="col-md-12">
                                    <p class="card-cs-body thisbody"  style="font-size: 15px;text-align: center; margin: 20px auto;width: 300px; line-height: 100%;font-weight: 500;">
                                      <small>  Ao finalizar sua inscrição, você aceita o compartilhamento de seus dados conosco.</small>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            
            </div>
        </section>

	</div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import $ from 'jquery'
export default {
	
	metaInfo: {
      title: 'Citrus Squad'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
		
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
        form: {
            name: "",
            email:"",
            phone: "",
            cpf: "",
            base: ""
        },

	}),
	validations: {
        form: {
            name: { required },
            email: { required, email },
            phone: { required },
            cpf: { required },
            base: { required }
        },
        
      },
	mounted() {
		this.active = this.selected
        $("#influenciadores_form").submit(function(e) {
            return false;
        });
	    $('.scroll-form').on('click tap', function(event) {
          var target =  $(".page-influenciadores-05").position().top - 50
          
          if (target) {
            event.preventDefault();
            if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
                 $(".page-influenciadores-05").scrollIntoView(true)
            } else {
              $('html, body').animate({
                scrollTop: target
              }, 800);
            }
          }
        });
    },
    methods: {
        submit_PF() {         
          this.$v.form.$touch();
          if(this.$v.form.$error) 

        return
            $(".titles").hide();
            $("#influenciadores_form").hide();
            $("#msgthanku").fadeIn('fast');
            let scroll = $(".page-influenciadores-05").position().top - 200
        
            $("html, body").animate({
              scrollTop: scroll
            }, 200);
              return false;
        },
    }
}
</script>

<style lang="scss">
#influenciadores_form{
    .fieldset{

    }
}
#msgthanku{
    font-size: 28px;
    width: 90%;
    display: none;
}
.sq-influenciadores{

    color: #454a58;
    
    font-family: "Poppins"!important;
    
    .page-influenciadores-01 {
        padding-top: 90px;
        padding-bottom: 50px;
        position: relative;
        background: #fafaf4;

        img{
            width: 120%
        }
        .banner-cs .banner-cs-body {
            margin-bottom: 20px;
        }
        .banner-cs-subtitle {
            font-size: 21px;
            color: #49494c;
            font-weight: bold;
            margin-bottom: 22px;
        }
    }

    .page-one__btn {
        display: inline-block;
        vertical-align: middle;
        color: #ffffff!important;
        font-size: 16px;
        font-weight: 500;
        line-height: 1em;
        padding: 19px 49px;
        background: #9cb63b;
        border-radius: 27px;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-box-shadow: 0px 10px 30px 0px #cccccc47;
        box-shadow: 0px 10px 30px 0px #cccccc47;
    }

    .page-influenciadores-01 [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }

    .page-influenciadores-02 {
        margin-top: 50px;
        margin-bottom: 50px;

        .row{
            align-items: flex-end;
        }

        .banner-cs-body{
            margin-bottom: 0;
            max-width: 584px;
        }
        img.dobem{
            width: 190px;
            object-fit: contain;
        }
        .cust{
            align-self: flex-end;
        }
        .custom{
            display: flex;
            flex-flow: column;
            align-self: center;
            margin-bottom: -9px!important;
        }
    }
    .page-influenciadores-03{
        .titleone{
            text-align: center;
        }
        .bodies{
            text-align: center;
        }
    }
    .box-ben-1 {
        background-image: url(https://citrus.cx/wp-content/uploads/2020/04/customer-service.png);
    }

    .box-ben-2 {
        background-image: url(https://citrus.cx/wp-content/uploads/2020/04/advertising.png);
    }

    .box-ben-3 {
        background-image: url(https://citrus.cx/wp-content/uploads/2020/04/certificate.png);
    }

    .box-ben-4 {
        background-image: url(https://citrus.cx/wp-content/uploads/2020/04/money.png);
    }

    .box-ben-5 {
        background-image: url(https://citrus.cx/wp-content/uploads/2020/04/hug.png);
    }

    .box-ben-6 {
        background-image: url(https://citrus.cx/wp-content/uploads/2020/04/podcast.png);
    }

    .thistitle {
        font-size: 19px;
        margin-top: 15px;
    }
    .thisbody{
        font-size: 15px;
    }
    .page-influenciadores-04{
        display: none;
        .banner-cs{
            text-align: center;

            .page-one__btn{
                font-size: 17px;
            }
        }
        .first-dbr{
            background: #efefef;
            padding: 80px 0;
            .banner-cs-subtitle{
                b{
                    font-size: 24px;
                    text-align: center;
                    max-width: 815px;
                    font-weight: 600;
                }
            }
        }
        .video-dbr{
            background: url(https://citrus.cx/wp-content/uploads/2020/04/base04.png) center center;
            background-size: 100%;
        }
    }
    .page-influenciadores-05{
        .card-form{
            background: #fff;
            border-radius: 15px;
            box-shadow: 0px 0 10px #ededed;
            padding: 30px 56px 56px 56px
        }
        .banner-cs{
            .banner-cs-subtitle {
                font-size: 36px;
                color: #797777;
                font-weight: bold;
                margin-bottom: 22px;
                text-align: center;
            }
            .page-one__btn{
                font-size: 17px;
            }
        }
        form{

            .fieldset{
                label{
                    width: 100%;
                    color: #6e6868;
                    font-size: 14px;
                    margin: 0;
                    padding: 12px 0 4px 15px;
                }
                input {
                    width: 100%;
                    border-radius: 30px;
                    padding: 1px 15px;
                    border: solid 2px #ebebeb;
                    outline: none;
                    font-size: 14px;

                    &:focus{
                        border-color: #9cb63b;
                    }
                }
                select {
                    width: 100%;
                    border-radius: 30px;
                    padding: 1px 15px;
                    border: solid 2px #ebebeb;
                    outline: none;
                    font-size: 14px;

                    &:focus{
                        border-color: #9cb63b;
                    }
                }
            }
            .form_submit{
                display: block;
                margin: 37px auto 0;
                background: #9cb63b;
                text-align: center;
                font-size: 15px;
                border-radius: 40px;
                padding: 15px;
                width: 310px;
                float: none;
                cursor: pointer;
                color: #fff;
                border: solid 1px #9cb63b;
            }
        }
    }
}

.hasError{
    label{
        color: #fc5d5d!important;
    }
    input{
        border-color: #fc5d5d!important;
    }
    select{
        border-color: #fc5d5d!important;
    }
}
.theerror{
    width: 100%;
    text-align: center;
    padding: 13px;
    margin: 42px 0 0 0;
    background: #fc5d5d;
    color: #fff;
    text-shadow: 1px 1px 2px #000;
    border-radius: 8px;
}
  @media(max-width: 520px){
    .card-form{
        padding: 30px 0!important;

        .theerror{
            margin-top: 10px;
        }
    }
  }

  @media(max-width: 600px){
    .sq-influenciadores .page-one__btn{
        width: 100%;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }
    .sq-influenciadores .page-influenciadores-01 img{
        width: 100%;
        margin-top: 50px;
    }
    .mobiled{
        flex-flow: column
    }
    .sq-influenciadores .banner-cs .banner-cs-title {
        margin: 0 !important;
        font-weight: bold !important;
        color: #49494c !important;
        font-size: 32px !important;
        line-height: 44px !important;
        margin-bottom: 20px!important;
    }
    .img-squad-do-bem{
        margin-bottom: 30px;
    }
    .page-influenciadores-01 {
        padding-top: 40px;
    }
    .sq-influenciadores .page-influenciadores-01 {
        padding-top: 40px;
        padding-bottom: 50px;
        position: relative;
        background: #fafaf4;
    }
}
</style>
