<template>
	<div class="sq-social">
		<section class="page-social-01">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center img-squad-do-bem">
                        <img src="https://citrus.cx/wp-content/uploads/2020/03/logo-squad-do-bem.png" alt="SQUAD">
                    </div>
                    <div class="col-12 text-center col-lg-6 text-lg-left">
                        <div class="banner-cs pt-4">
                            <div class="banner-cs-title">
                                EMPATIA SOLIDARIEDADE E COLABORAÇÃO
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="banner-cs">
                            <div class="banner-cs-body">
                                É tempo que cuidar de si, pensando no bem do outro. Fazer </br>
                                companhia e dar apoio, mesmo sem estar ao lado, dar a mão ou </br>
                                abraçar.
                                <div class="m-3"></div>
                                Chegou o momento de provar que a distância não impede a </br>
                                presença. E lembrar que os desafios fortalecem, unem e </br>
                                impulsionam a criatividade e a inovação.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-social-02">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <div class="banner-cs">
                            <div class="banner-cs-title-md mb-0">
                            Empatia
                            </div>
                            <div class="banner-cs-title-sm">
                            Conter uma pandemia global
                            </div>
                            <div class="banner-cs-body">
                            O surto da COVID-19, doença provocada pelo novo <a href="https://news.un.org/pt/events/coronavirus" target="_blank">coronavírus</a>, disseminou uma crise global da saúde. Seu impacto afetou todos os setores da sociedade e será sentido no curto e no longo prazo. Para conter o surto, líderes empresariais de todo o mundo vêm se unindo para <a href="https://nacoesunidas.org/pacto-global-sugere-respostas-das-empresas-a-covid-19/" target="_blank">apoiar comunidades e empresas afetadas</a>.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="banner-cs">
                            <div class="banner-cs-title-md mb-0">
                                Solidariedade
                            </div>
                            <div class="banner-cs-title-sm">
                                Facilitar o trabalho remoto de organizações
                            </div>
                            <div class="banner-cs-body">
                                É preciso intensificar medidas para proteger colaboradores de empresas de diferentes lugares, garantindo que o trabalho essencial às essas organizações continue. Contribuindo com as ações de prevenção e contenção do coronavírus, disponibilizamos uma solução com recursos de colaboração e videoconferência para possibilitar o trabalho remoto, a fim de preservar vidas.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="banner-cs">
                            <div class="banner-cs-title-md mb-0">
                                Colaboração
                            </div>
                            <div class="banner-cs-title-sm">
                                Squad para te apoiar quando você precisar
                            </div>
                            <div class="banner-cs-body">
                                <ul class="banner-cs-body-ul mt-2">
                                    <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Não precisa instalar</li>
                                    <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Use sua conexão wi-fi ou internet móvel</li>
                                    <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Envie arquivos que poderão ficar armazenados</li>
                                    <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Participe de reuniões com seus grupos de trabalho via áudio e videoconferência</li>
                                    <li><img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png" class="topic"> Conte com diversos recursos de colaboração para um home office produtivo</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-social-03">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
            <div class="container">
                <div class="banner-cs row">
                    <div class="col-12 banner-cs-title m-0 text-center">
                        Quem faz o nosso Squad do
                    </div>
                    <div class="col-12 banner-cs-title m-0 text-center">
                        bem ser presente? <span class="text-green">Você.</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-social-04">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-5">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6">
            <div class="container d-flex flex-column justify-content-center align-items-center">
                <div class="banner-cs mt-5">
                    <div class="banner-cs-title text-center">
                        Como funciona?
                    </div>
                </div>
                <div class="banner-cs mt-3">
                    <div class="d-flex justify-content-center">
                        <img src="https://citrus.cx/wp-content/uploads/2020/03/icone-1.png" alt="img1">
                    </div>
                    <div class="banner-cs-body mt-3 text-center">
                        Liberaremos licenças gratuitas* da nossa </br>
                        ferramenta de colaboração e videoconferência </br>
                        para empresas por até 90 dias
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="box-line"></div>
                    </div>
                </div>
                <div class="banner-cs mt-3">
                    <div class="d-flex justify-content-center">
                        <img src="https://citrus.cx/wp-content/uploads/2020/03/icone-2.png" alt="img1">
                    </div>
                    <div class="banner-cs-body mt-3 text-center">
                        Para manifestar seu interesse em juntar sua </br>
                        equipe com o Squad, basta clicar no botão </br>
                        <strong>Participar</strong> e preencher o formulário com as </br>
                        informações de sua empresa
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="box-line"></div>
                    </div>
                </div>
                <div class="banner-cs mt-3">
                    <div class="d-flex justify-content-center">
                        <img src="https://citrus.cx/wp-content/uploads/2020/03/icone-3.png" alt="img1">
                    </div>
                    <div class="banner-cs-body mt-3 text-center">
                        Analisaremos a necessidade e a quantidade de </br>
                        usuários de sua organização
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="box-line"></div>
                    </div>
                </div>
                <div class="banner-cs mt-3">
                    <div class="d-flex justify-content-center">
                        <img src="https://citrus.cx/wp-content/uploads/2020/03/icone-4.png" alt="img1">
                    </div>
                    <div class="banner-cs-body mt-3 text-center">
                        Retornaremos com orientações sobre a </br>
                        liberação das licenças e uso
                    </div>
                </div>
            </div>
        </section>
        <section class="page-social-05">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
            <!-- <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2"> -->
            <div class="container banner-cs">
                <div class="banner-cs-title-sm m-0 text-center">
                    Essa atitude irá ajudar a conter o coronavírus e </br>
                    proteger milhares de pessoas. Toda nossa </br>
                    comunidade agradece!
                </div>
                <div class="d-flex justify-content-center">
                    <a href="https://materiais.citrus.cx/juntos-squad" target="_blank" class="header-one__btn btnLight m-5 btn-200 text-center">Participar</a>
                </div>
                <div class="banner-cs-body text-center letters-small">
                    *O interessado deverá arcar com os custos de operação. Para aderir ao uso da ferramenta, entre em contato e consulte condições.
                </div>
            </div>
        </section>
	</div>
</template>

<script>

export default {
	metaInfo: {
      title: 'SQUAD DO BEM'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
		
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
	}),
	

	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">

.sq-social{
    .banner-cs-body{
        a{
            color: #9cb72e;
        }
    }
    .letters-small {
        font-size: 7pt !important;
    }

.btn-200 {
    width: 200px;
}

.text-green {
    color: #9cb72e !important;
}

.box-line {
    height: 100px;
    width: 10px;
    border-radius: 5px;
    border: #c4e739 solid 5px;
}

.img-squad-do-bem{
    margin-bottom: 70px;
}

.page-social-01 {
    position: relative;
    margin-top: 160px;
}

.page-social-01 .bubble-1 {
    position: absolute;
    top: 0%;
    left: 10%;
}

.page-social-01 .bubble-2 {
    position: absolute;
    top: 100%;
    left: 90%;
}

.page-social-01 .bubble-3 {
    position: absolute;
    top: 2%;
    left: 90%;
}

.page-social-01 .bubble-4 {
    position: absolute;
    top: 20%;
    left: 75%;
}

.page-social-01 .bubble-5 {
    position: absolute;
    top: 22%;
    left: 25%;
}

.page-social-01 .bubble-6 {
    position: absolute;
    top: 100%;
    left: 6%;
}

.page-social-01 [class*=bubble-] {
    position: absolute;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-perspective: 400px;
    perspective: 400px;
}

.page-social-02 {
    position: relative;
    margin-top: 50px;
}

.page-social-03 {
    position: relative;
    margin-top: 80px;
}

.page-social-03 .bubble-1 {
    position: absolute;
    top: 23%;
    left: 10%;
}

.page-social-03 .bubble-2 {
    position: absolute;
    top: 100%;
    left: 90%;
}

.page-social-03 [class*=bubble-] {
    position: absolute;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-perspective: 400px;
    perspective: 400px;
}

.page-social-04 {
    position: relative;
    margin-top: 80px;
}

.page-social-04 .bubble-1 {
    position: absolute;
    top: 23%;
    left: 85%;
}

.page-social-04 .bubble-2 {
    position: absolute;
    top: 80%;
    left: 90%;
}

.page-social-04 .bubble-3 {
    position: absolute;
    top: 63%;
    left: 15%;
}

.page-social-04 .bubble-4 {
    position: absolute;
    top: 53%;
    left: 85%;
}

.page-social-04 .bubble-5 {
    position: absolute;
    top: 27%;
    left: 28%;
}

.page-social-04 .bubble-6 {
    position: absolute;
    top: 90%;
    left: 9%;
}

.page-social-04 [class*=bubble-] {
    position: absolute;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-perspective: 400px;
    perspective: 400px;
}

.page-social-05 {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.page-social-05 .bubble-1 {
    position: absolute;
    top: 30%;
    left: 85%;
}

.page-social-05 .bubble-2 {
    position: absolute;
    top: 67%;
    left: 5%;
}

.page-social-05 [class*=bubble-] {
    position: absolute;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-perspective: 400px;
    perspective: 400px;
}
}

</style>
