<template>
	<div class="sq-algar">
      <section class="banner-one" id="home">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="banner-one__content">

                            <div class="block-title">
                                <div class="block-title__text termostitle"><span>TERMOS GERAIS DE USO DA PLATAFORMA SQUAD</span></div><!-- /.block-title__text -->
                            </div><!-- /.block-title -->
                            
                            <p class="termos-one__text mb-3">Pelo presente instrumento, a Citrus Serviços em Tecnologia da Informação e Comunicação LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob nº 12.806.448/0001-86, com sede em João Pessoa, Paraíba, Brasil, concede a você o direito de uso dos serviços oferecidos pela plataforma SQUAD , desde que você aceite os termos e condições a seguir descritos. Caso você não concorde com quaisquer termos e/ou condições destes termos de uso, você não deverá contratar e/ou fazer uso do dos serviços do SQUAD.</p>

                            <p class="termos-one__text mb-3" style="border: 1px solid rgb(208, 206, 206);background: rgb(233, 233, 233);padding: 16px 17px;border-radius: 7px;">Ao aceitar estes termos de uso, você reconhece que leu e entendeu o seu conteúdo, que aceita seus termos e condições e concorda que se vinculou integralmente de forma livre e espontânea às suas condições, bem como à política de privacidade da Licenciante.</p>

                            <h2 class="termos-one__text mb-2"><strong>1. DISPOSIÇÕES GERAIS</strong></h2>              

                            <p class="termos-one__text mb-2">1.1 Estes Termos de Uso estabelecem os termos e condições para utilização e acesso ao site, produtos, serviços e aos Softwares associados, compreendendo o programa de computador e podendo incluir os meios físicos associados, bem como quaisquer materiais impressos e qualquer documentação online ou eletrônica, entre Você (“Licenciado”) e a Citrus Serviços em Tecnologia da Informação e Comunicação LTDA (“Citrus” ou “Licenciante”).</p>
                            
                            <p class="termos-one__text mb-2">1.2 A "Licenciante" é titular e proprietária do Software SQUAD e concede ao “Licenciado”, por meio dos presentes Termos de Uso, uma licença temporária, não exclusiva, restrita e onerosa para uso da plataforma SQUAD, com a finalidade de acesso e uso da plataforma que dispõe ferramentas de colaboração e produtividade que facilitam a comunicação entre equipes internas e externas através de recursos de voz, vídeo, mensagens e conferência.</p>

                            <p class="termos-one__text mb-2">1.3  A aceitação destes Termos de Uso sujeita o “Licenciado” ao cumprimento integral dos termos e condições aqui inseridos bem como daqueles previstos na Política de Privacidade do "Licenciado”, Termos do Usuário e a Política de Uso Aceitável, mesmo que você utilize a plataforma SQUAD de forma parcial ou a título de teste.</p>
                            
                            <p class="termos-one__text mb-2">1.4 “Licenciado” e “Licenciado” declaram que o presente pacto tem natureza eminentemente civil-empresarial, ambiente em que as partes gozam de ampla autonomia da vontade, sendo certo que o conteúdo do presente negócio jurídico empresarial tem como objeto livre estipulação das partes pactuantes, de forma a aplicar as regras de direito empresarial apenas de maneira subsidiária ao aqui avençado, excluindo-se completamente a aplicação do Código de Defesa do Consumidor, nos termos dos artigos 1o, §1o, § 2o, 2o e 3o, VII da Lei no 13.874/2019.</p>
                            
                            <p class="termos-one__text mb-2">1.5 O “Licenciado”, entendido como CONTROLADOR DE DADOS, se obriga a obter o consentimento dos seus clientes de acordo com a Lei 13.709/2018, para o eventual tratamento dos dados com a finalidade de acesso, gerenciamento, gravação de áudio ou vídeo através da plataforma, bem como através de monitoramento operacional ativo, suporte e backoffice do cliente “Licenciado” por parte da "Licenciante", entendida como OPERADOR.</p>

                            <h2 class="termos-one__text mb-2"><strong>2. LICENÇA DE USO</strong></h2> 

                            <p class="termos-one__text mb-2">2.1 O Software SQUAD e suas atualizações, upgrades, versões modificadas e suplementos são protegidos por direitos autorais e de legítima propriedade da "Licenciante" e está sendo licenciado, não transferido, para o “Licenciado”, de forma que se aplica a este contrato o disposto nas Leis 9.609/98 e 9.610/98. Assim, nenhuma disposição contida neste Termo de Uso deve ser interpretada como uma transferência ou cessão de titularidade de quaisquer direitos de propriedade intelectual relacionados a plataforma SQUAD incluindo, entre outros, quaisquer direitos de propriedade intelectual sobre criações/invenções, sejam eles patentes, desenhos, marcas, direitos autorais ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como todo o conteúdo disponibilizado na plataforma, incluindo, mas não se limitando a textos, gráficos, imagens, logotipos, ícones, fotografias, conteúdo editorial, notificações, Softwares e qualquer outro material, sobre ou relacionados a plataforma ou nenhuma parte dele, anteriores ou posteriores a aceitação deste Termo de Uso.</p>
                            
                            <p class="termos-one__text mb-2">2.2 Mediante o pagamento dos valores previstos no website ou proposta de adesão, a "Licenciante" concede ao “Licenciado”, na qualidade de usuário final, uma licença temporária, não exclusiva, onerosa e intransferível para uso da plataforma SQUAD, observados os presentes Termos de Uso, Política de Privacidade e Termo de Usuário Autorizado. O “Licenciado” terá o direito de uso da plataforma em âmbito interno, bem como conceder acesso a terceiros (“Usuário Autorizado”), em benefício próprio e dentro do escopo das atividades exercidas pelo ”Licenciado.</p>
                            
                            <p class="termos-one__text mb-2">2.3 Esta Licença permite o uso da plataforma SQUAD pelo número de usuários e conforme módulos contratados entre as partes através do “PORTAL SQUAD ”.</p>
                            
                            <p class="termos-one__text mb-2">2.4 Por se tratar de uma licença de uso de Software de acesso remoto (Software as a Service - Saas), caberá ao “Licenciado” adquirir hardware, equipamentos e contratar serviços de acesso/conexão à internet adequados e outros, conforme recomendações da "Licenciante", para possibilitar a adequada performance da plataforma SQUAD.</p>
                            
                            <p class="termos-one__text mb-2">2.5 Fica expressamente vedado ao “Licenciado”, em relação ao Software: modificar (ainda que para fins de correção de erro), ceder, doar, alugar, vender, arrendar, emprestar, reproduzir, modificar, adaptar, traduzir, incorporar a outros programas ou sistemas, próprios ou de terceiros; oferecer em garantia ou penhor; alienar ou transferir, total ou parcialmente, a qualquer título, de forma gratuita ou onerosa; descompilar, mudar a engenharia (reengenharia), enfim, dar qualquer outra destinação ao Software, ou parte dele, que não seja a simples utilização.</p>

                            <h2 class="termos-one__text mb-2"><strong>3. VIGÊNCIA E POLÍTICA DE CANCELAMENTO</strong></h2> 

                            
                            <p class="termos-one__text mb-2">3.1 Os presentes Termos de Uso entrarão em vigor a partir da data de sua assinatura e permanecerão vigentes até que haja o seu eventual cancelamento nas hipóteses aqui previstas.</p>
                            <p class="termos-one__text mb-2">3.2 O cancelamento do serviço se dará das seguintes formas:</p>
                            <p class="termos-one__text mb-2">I) Plano mensal:</p>
                            <p class="termos-one__text mb-2">a. Cancelamento mediante solicitação do “Licenciado”: Hipótese em que o “Licenciado” solicita, por escrito, a rescisão contratual, efetuando eventual pagamento de saldo devedor do plano de licenciamento contratado, se existente. Para os planos de licenciamento com pagamento antecipado, caso o “Licenciado” decida rescindir este termo antes do término do prazo contratado, o "Licenciante" não restituirá ao “Licenciado” o saldo restante do plano de licenciamento contratado. Este valor será retido pela "Licenciante" para cobrir os custos operacionais.</p>
                            <p class="termos-one__text mb-2">b. Cancelamento por ausência de pagamento: caso o “Licenciado” não pague o valor mensal, o acesso ao SQUAD será automaticamente bloqueado. Caso a regularização financeira não seja efetuada dentro do prazo de 60 dias a contar do vencimento da fatura, os dados serão definitivamente eliminados, conforme item 3.4.</p>
                            <p class="termos-one__text mb-2">II) Plano anual:</p>
                            <p class="termos-one__text mb-2">a. Cancelamento mediante solicitação: Hipótese em que o “Licenciado” possui contrato anual e solicita, por escrito, o cancelamento do produto na vigência do ano contratado, efetuando eventual pagamento de saldo devedor do plano de licenciamento. Para os planos de licenciamento com pagamento antecipado, caso o “Licenciado” decida rescindir este termo antes do término do prazo contratado, o "Licenciante" não restituirá ao “Licenciado” o saldo restante do plano de licenciamento contratado. Este valor será retido pela "Licenciante" para cobrir os custos operacionais.</p>
                            <p class="termos-one__text mb-2">b. Cancelamento por ausência de pagamento: caso o “Licenciado” não pague o valor anual, e não opte pelo pagamento mensal, o acesso ao SQUAD será automaticamente bloqueado. Caso a regularização financeira não seja efetuada dentro do prazo de 60 dias a contar do vencimento da fatura, os dados serão definitivamente eliminados, conforme item 3.7.</p>
                            <p class="termos-one__text mb-2">3.3 A "Licenciante" poderá rescindir este TERMO a qualquer tempo, desde que comunique o “Licenciado”, por escrito, com antecedência mínima de 30 (trinta) dias, devendo neste caso restituir ao “Licenciado” o saldo devedor do plano de licenciamento contratado, se existente.</p>
                            <p class="termos-one__text mb-2">3.4 Para os planos de licenciamento anual com pagamento antecipado, caso a "Licenciante" decida rescindir este TERMO antes do término do prazo contratado, a "Licenciante" restituirá ao “Licenciado” o saldo restante do plano de licenciamento contratado, realizando as deduções referentes a custos operacionais e descontos financeiros.</p>
                            <p class="termos-one__text mb-2">3.5 A "Licenciante" poderá rescindir o TERMO a qualquer momento em caso de violação pelo “Licenciado” dos Termos Gerais de Uso da Plataforma SQUAD, bem como da Política de Privacidade e do Termo de Usuário Autorizado, ou em caso de atraso de pagamento não sanado no prazo de 60 (sessenta) dias.</p>
                            <p class="termos-one__text mb-2">3.6 Ao término, por qualquer motivo, do Contrato de Licença firmado entre a "Licenciante" e o “Licenciado”, esta deverá cessar imediatamente seu uso da plataforma SQUAD.</p>
                            <p class="termos-one__text mb-2">3.7 Em conformidade com as diretrizes da Lei 13.709/2018, em caso de cancelamento ou expiração do contrato, a "Licenciante" conceda ao “Licenciado” a opção de exportação dos dados dos clientes e históricos de atendimento inseridos pelo “Licenciado” na plataforma SQUAD, no prazo de até 45 dias após o fim do contrato. Após o envio, todos os dados serão permanentemente excluídos dos bancos de dados da "Licenciante". </p>

                            <h2 class="termos-one__text mb-2"><strong>4. PREÇO E PAGAMENTO</strong></h2> 

                             <p class="termos-one__text mb-2">4.1 O “Licenciado” deverá indicar no ato do cadastramento o responsável financeiro pelo pagamento.</p>
                             <p class="termos-one__text mb-2">4.2 O “Licenciado” se compromete a pagar pontualmente os valores devidos pela licença de uso da plataforma SQUAD, mediante boleto ou cartão de crédito, conforme contratado no “PORTAL SQUAD ”.</p>
                             <p class="termos-one__text mb-2">4.3 A Citrus mantém contratos e serviços com subcontratantes terceirizados para, além de fornecer serviços de infraestrutura, ajudar-nos a prestar suporte ao cliente e enviar notificações por e-mail. </p>
                            
                             <p class="termos-one__text mb-2">Antes de envolver subcontratados terceirizados, a Citrus realiza uma avaliação cuidadosa de suas práticas de privacidade, segurança e confidencialidade e executa um contrato implementando todas as obrigações aplicáveis. O “Licenciado” reconhece que termos de uso e políticas de privacidade diferentes poderão ser aplicáveis ao uso desses serviços e conteúdos de terceiros e a plataforma SQUAD não será, em hipótese alguma, responsável por nenhum produto ou serviço desses terceiros quando prestados diretamente ao “Licenciado”.</p>

                            <table style="margin: 10px 0;width: 100%;color: #6f6f6f;" border="1px" cellpadding="5px" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Empresa</th>
                                        <th>Atividades</th>
                                        <th>Localização</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Amazon Web Services</td>
                                        <td>Provedora de Serviço da Nuvem</td>
                                        <td>Estados Unidos</td>
                                    </tr>
                                    <tr>
                                        <td>Digivox</td>
                                        <td>Fornecedor de serviços de VOIP para serviços de telefonia</td>
                                        <td>Brasil</td>
                                    </tr>
                                    <tr>
                                        <td>SQUAD </td>
                                        <td>Serviços de apoio ao cliente, baseado na nuvem</td>
                                        <td>Brasil</td>
                                    </tr>
                                    <tr>
                                        <td>VINDI TECNOLOGIA E MARKETING S.A </td>
                                        <td>Processamento de cobrança e pagamento</td>
                                        <td>Brasil</td>
                                    </tr>
                                </tbody>
                            </table>

                             <p class="termos-one__text mb-2">4.4 A falta de pagamento de quaisquer valores nas respectivas datas de vencimento não acarretará na rescisão automática do serviço, mas causará a suspensão do acesso do “Licenciado” ao SQUAD  até que as pendências financeiras tenham sido regularizadas, observado o disposto no item 3. O acesso ao SQUAD  somente será reestabelecido após a identificação pela "Licenciante" do pagamento integral de todos os valores devidos enquanto o mesmo esteve suspenso. A identificação poderá ocorrer em até dois dias úteis após a data de pagamento pelo “Licenciado”.</p>
                            
                             <p class="termos-one__text mb-2">4.5 Os valores dos planos de licenciamento da plataforma SQUAD serão atualizados anualmente pelo IGPM-FGV acumulado no período, ou no caso de extinção deste, de outro índice oficial que venha a substituí-lo.</p>
                            
                             <p class="termos-one__text mb-2">4.6 Em situações excepcionais, ou a critério da Citrus poderá ser liberada uma  licenças para  teste a título gratuito, pelo prazo improrrogável de 90 (noventa) dias, não desobriga o “Licenciado” ao pagamento dos valores correspondentes ao plano de licenciamento escolhido no ato da contratação dos serviços. </p>

                             <p class="termos-one__text mb-2">A gratuidade da licença não contempla a infra-estrutura necessária para funcionamento da solução</p>

                            <h2 class="termos-one__text mb-2"><strong>5. COLETA DE DADOS</strong></h2> 

                            <p class="termos-one__text mb-2">5.1 O “Licenciado” é o único responsável, e isenta a "Licenciante", pela coleta dos dados/informações de seus clientes, pela obtenção de autorização expressa para coleta, processamento e armazenamento de seus dados/informações, pelo cumprimento da legislação aplicável e normas de coleta e proteção de dados e de privacidade, e outros regulamentos, leis, padrões de mercado e direitos de terceiros aplicáveis a qualquer de suas atividades.</p>
                            
                            <p class="termos-one__text mb-2">5.2 O “Licenciado” se compromete a indenizar e isentar a "Licenciante" de qualquer responsabilidade relativa a quaisquer danos, reivindicações, perdas, acordos, honorários advocatícios, custas legais e judiciais e demais despesas relacionadas às suas atividades e de quaisquer demandas relacionadas. </p>


                            <h2 class="termos-one__text mb-2"><strong>6. DAS OBRIGAÇÕES DO LICENCIADO</strong></h2> 



                            <p class="termos-one__text mb-2">6.1 O “Licenciado” reconhece sua integral responsabilidade pela escolha da plataforma SQUAD para atingir os resultados por ela pretendidos, bem como pelo uso adequado da plataforma e resultados obtidos através dele.</p>
                            
                            <p class="termos-one__text mb-2">6.2 A "Licenciante" isenta-se expressamente de quaisquer responsabilidades e indenizações, perdas e danos, lucros cessantes, como por exemplo, mas não limitado a: prejuízos de quaisquer espécies, perdas de negócios, perda ou extravio de dados, falhas de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao uso ou inabilidade em usar o Software, operação por pessoas não autorizadas, hipóteses em que não haja culpa da "Licenciante", danos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com base em informações fornecidas pelo Software, danos causados a terceiros em razão de qualquer conteúdo gerado pelo "Licenciante" através do Software, defeitos de computador, equipamentos ou periféricos, ou pelo custo incorrido na recuperação de referidos dados ou programas ou quaisquer outros danos diretos, indiretos ou incidentais decorrentes da utilização do sistema.</p>
                            
                            <p class="termos-one__text mb-2">6.3 O “Licenciado” expressamente reconhece e concorda que a eventual responsabilidade da "Licenciante" será limitada ao valor pago pela licença de uso da plataforma SQUAD, pelo “Licenciado” à "Licenciante", nos primeiros 12 (doze) meses do respectivo contrato de licença. esta disposição é aplicável a todas as causas de pedir, incluindo, sem limitação, inadimplemento contratual, violação de garantia, negligência, responsabilidade objetiva, falsa declaração e outros danos. O “Licenciado” também reconhece que as limitações de responsabilidade desta cláusula constituem elemento essencial deste contrato e que, na ausência de tais limitações, os preços e outras condições aqui estabelecidas seriam substancialmente diferentes.</p>
                            
                            <p class="termos-one__text mb-2">6.4 O “Licenciado” se obriga a manter pessoal treinado para a operação do SQUAD e para comunicação com a "Licenciante" e prover, sempre que ocorrerem quaisquer problemas no uso da plataforma, toda a documentação, relatórios e demais informações que relatem as circunstâncias em que os problemas ocorreram, objetivando facilitar a assistência fornecida.</p>
                            
                            <p class="termos-one__text mb-2">6.5 O “Licenciado” obriga-se manter, às suas expensas, linha de telecomunicação, modem, Software de comunicação, endereço de correio eletrônico e outros recursos necessários à comunicação com a "Licenciante".</p>
                           
                           <p class="termos-one__text mb-2"> 6.6 O “Licenciado” é responsável pelas informações inseridas na plataforma SQUAD, pelo cadastramento, permissões, senhas e modo de utilização de seus usuários, bem como do Usuário Autorizado, pelo disposto nos Termos de Usuário Autorizado. A "Licenciante" em hipótese alguma será responsável pelo conteúdo (INFORMAÇÕES, senhas, cópias de informações, etc.) incluído na plataforma, não sendo, portanto, estas INFORMAÇÕES revisadas em momento algum. A responsabilidade pelas INFORMAÇÕES inseridas na plataforma é sempre do “Licenciado”.</p>
                            
                            <p class="termos-one__text mb-2">6.7 O “Licenciado” se compromete a não utilizar a plataforma SQUAD de qualquer forma que possa implicar em ato ilícito, infração, violação de direitos ou danos à "Licenciante" ou terceiros, incluindo, mas não se limitando ao uso para invasão de dispositivo informático com o objetivo de obter, adulterar ou destruir dados ou informações sem a autorização expressa do titular de tais dados ou do dispositivo ou servidor nos quais estes estejam armazenados.</p>
                            
                            <p class="termos-one__text mb-2">6.8 O “Licenciado” se compromete a não publicar, enviar ou transmitir qualquer arquivo que contenha vírus, worms, cavalos de troia ou qualquer outro programa que possa contaminar, destruir ou interferir no bom funcionamento da plataforma.</p>
                            
                            <p class="termos-one__text mb-2">6.9 O “Licenciado” se compromete a informar a "Licenciante" sempre que houver qualquer alteração das INFORMAÇÕES fornecidas à "Licenciante" e que possam impedir, limitar ou prejudicar o acesso da "Licenciante" às INFORMAÇÕES necessárias para a execução das funcionalidades ofertadas pela plataforma.</p>
                            
                            <p class="termos-one__text mb-2">6.10 Caso o “Licenciado” acredite que seu login e senha de acesso à plataforma tenham sido roubados ou sejam de conhecimento de outras pessoas, por qualquer razão, o “Licenciado” deverá imediatamente comunicar tal fato à "Licenciante", sem prejuízo da alteração da sua senha imediatamente, por meio da plataforma.</p>
                            
                            <p class="termos-one__text mb-2">6.11 O “Licenciado” se responsabiliza de forma solidária e integral pelos atos do Usuário Autorizado, sendo estes os convidados do Licenciado para utilizar o ambiente online de colaboração do Squad, em conformidade com os Termos de Usuário Autorizado.</p>



                            <h2 class="termos-one__text mb-2"><strong>7. DAS OBRIGAÇÕES DA LICENCIANTE</strong></h2> 

                            <p class="termos-one__text mb-2">7.1 A "Licenciante" garante ao “Licenciado” que a plataforma deverá funcionar regularmente, se respeitadas as condições de uso definidas na documentação. Na ocorrência de falhas de programação (“bugs”), a "Licenciante" obrigar-se-á a corrigir tais falhas, podendo a seu critério substituir a cópia dos Programas com falhas por cópias corrigidas.</p>
                            
                            <p class="termos-one__text mb-2">7.2 Fornecer, ato contínuo ao aceite deste Termo, acesso à plataforma durante a vigência deste Termo.</p>
                            
                            <p class="termos-one__text mb-2">7.3 Suspender o acesso à plataforma do “Licenciado” que esteja desrespeitando as regras de conteúdo aqui estabelecidas ou as normas legais em vigor ou ainda, ao final do prazo de validade deste instrumento, independentemente de aviso prévio.</p>
                            
                            <p class="termos-one__text mb-2">7.4 Alterar as especificações e/ou características do Software “Licenciado” para a melhoria e/ou correções de erros.</p>
                            
                            <p class="termos-one__text mb-2">7.5 Disponibilizar acesso aos serviços de suporte de acordo com o pacote contratado no portal Lemon Desk.</p>
                            
                            <p class="termos-one__text mb-2">7.6  A plataforma SQUAD, se compromete a, dentro dos limites do estado atual da técnica, proteger por meio de armazenamento em servidores ou quaisquer outros meios magnéticos de alta segurança a confidencialidade de todas as informações, dados pessoais, dados sensíveis relativos ao “Licenciado”, não possuindo responsabilidade sobre reparação de eventuais prejuízos que possam ser derivados de captura e cooptação de dados por parte de terceiros que, burlando os sistemas de segurança, consigam acessar as informações de maneira ilícita.</p>


                             <h2 class="termos-one__text mb-2"><strong>8.GARANTIA</strong></h2> 

                             <p class="termos-one__text mb-2">8.1 A "Licenciante" garante que a plataforma SQUAD disponibilizado ao “Licenciado” corresponde às especificações e funcionalidades previstas nestes Termos de Uso, em todos os seus aspectos substanciais; e a sua validade técnica durante a vigência do Contrato de Licença.</p>
                            
                            <p class="termos-one__text mb-2">8.2 A garantia prevista no item 8.1 não se aplica se qualquer defeito no funcionamento do SQUAD  decorrer de: mau uso da plataforma SQUAD  e/ou uso em desconformidade com as suas especificações e/ou finalidades; alteração, modificação ou conversão do Software pelo “Licenciado” ou por qualquer terceiro; mau funcionamento de hardware, equipamentos ou serviços contratados pelo “Licenciado”, incluindo serviços de acesso/conexão à internet; serviços de suporte técnico ou reparo realizados por terceiro não autorizado; uso, pelo “Licenciado”, de Software de terceiro em conjunto com o Software; e/ou qualquer outro evento ocorrido após a entrega inicial da plataforma ao “Licenciado”.</p>
                            
                            <p class="termos-one__text mb-2">8.3 A "Licenciante" não garante que as funções da plataforma satisfarão os requisitos do “Licenciado”, ou que os produtos de Software trabalharão juntos na seleção que foi escolhida, ou que eles serão executados sem interrupção e sem falhas, ou que todas as eventuais falhas da plataforma podem ser removidas.</p>
                            
                            <p class="termos-one__text mb-2">8.4 A "Licenciante" adota as medidas de segurança adequadas de acordo com os padrões de mercado para proteção das informações do “Licenciado”, todavia, nenhuma transmissão de dados por meio da Internet é absolutamente segura. A "Licenciante" não poderá assegurar que as informações transmitidas para o SQUAD  durante a navegação neste site estarão imunes a ataques e/ou invasões de hackers e outros agentes maliciosos, não sendo a "Licenciante" responsável por qualquer exclusão, obtenção, utilização ou divulgação não autorizada de INFORMAÇÕES resultantes de ataques que a "Licenciante" não poderia razoavelmente evitar por meio dos referidos padrões de segurança. Desta forma, cabe ao usuário proceder medidas de segurança no uso da Internet e na manutenção de sua infraestrutura computacional, mantendo sistema de antivírus atualizado, utilizando Softwares seguros, evitando downloads de sites desconhecidos e não acessando links oferecidos por e-mails suspeitos.</p>
                            
                            <p class="termos-one__text mb-2">8.5 O “Licenciado” declara, reconhece e aceita que o estado atual da técnica não permite a elaboração de programas de computador totalmente isentos de vícios ou defeitos e que, assim sendo, a "Licenciante" não pode garantir que o programa operará ininterruptamente ou livre de vícios ou defeitos.</p>
                            
                            <p class="termos-one__text mb-2">8.6 A plataforma SQUAD reserva-se o direito de suspender, temporariamente, a prestação dos Serviços durante período de atualização e/ou reconstrução da plataforma, ou ainda, durante trabalhos de manutenção de sistema, viabilizando a melhoria da experiência do “Licenciado”.</p>

                            <h2 class="termos-one__text mb-2"><strong>9. ANTICORRUPÇÃO</strong></h2> 

                            <p class="termos-one__text mb-2">9.1 A "Licenciante" declara que a plataforma SQUAD foi concebida dentro das boas práticas de gestão e respeito às disposições legais brasileiras, sendo responsabilidade exclusiva do “Licenciado” o uso indevido da plataforma, acarretando eventuais desvios de finalidade.</p>
                            
                            <p class="termos-one__text mb-2">9.2 Para a manutenção da Licença objeto dos presentes termos, nenhuma das partes poderá oferecer, dar ou se comprometer a dar a quem quer que seja, ou aceitar ou se comprometer a aceitar de quem quer que seja, tanto por conta própria quanto através de outrem, qualquer pagamento, doação, compensação, vantagens financeiras ou não financeiras ou benefícios de qualquer espécie que constituam prática ilegal ou de corrupção sob as leis de qualquer país, seja de forma direta ou indireta quanto ao objeto destes termos, ou de outra forma que não relacionada a este, devendo garantir, ainda, que seus prepostos e colaboradores ajam da mesma forma.</p>

                            <h2 class="termos-one__text mb-2"><strong>10. DISPOSIÇÕES FINAIS</strong></h2> 

                            <p class="termos-one__text mb-2">10.1 A "Licenciante" poderá ceder estes Termos de Uso a qualquer afiliada ou coligada da "Licenciante", ou a qualquer eventual e futuro cessionário dos direitos de propriedade intelectual e titularidade da plataforma SQUAD. o “Licenciado” não poderá ceder, no todo ou em parte, estes Termos de Uso ou a Licença de uso da plataforma, exceto se expressamente autorizado por escrito pela "Licenciante".</p>
                            
                            <p class="termos-one__text mb-2">10.2 Os termos e condições poderão ser atualizados e modificados a qualquer tempo, independente de notificação prévia, cabendo ao “Licenciado” manter-se atualizado. Em face de alteração superveniente, para que o “Licenciado” possa continuar tendo acesso aos serviços da plataforma SQUAD, deverá declarar que leu e que concorda com as alterações, sob pena de cessar o acesso ao SQUAD.</p>
                            
                            <p class="termos-one__text mb-2">10.3 O “Licenciado” aceita que a "Licenciante" poderá, ao seu critério, alterar a prestação de serviços da plataforma SQUAD, interromper seu funcionamento temporariamente ou definitivamente.</p>
                            
                            <p class="termos-one__text mb-2">10.4 O “Licenciado” se compromete a respeitar as marcas da "Licenciante", não devendo utilizá-las sem prévia autorização, por escrito, nem praticar atos que possam prejudicar tais marcas.</p>
                            
                            <p class="termos-one__text mb-2">10.5 O “Licenciado” autoriza a aplicação de sinais gráficos distintivos e característicos de marca, exclusivamente de sua titularidade, para customização da plataforma e ampliação da experiência do usuário, como, por exemplo, mas não exclusivamente, a expressão “Licenciado powered by Citrus”.</p>
                            
                            <p class="termos-one__text mb-2">10.6 Se qualquer disposição dos Termos Gerais de Uso da plataforma SQUAD for considerada, pelo Judiciário ou Tribunal Arbitral, como contrária à lei, as outras disposições permanecerão em vigor.</p>
                            
                            <p class="termos-one__text mb-2">10.7 Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil. As partes elegem o foro da Comarca de João Pessoa, Paraíba, Brasil, com exclusão de qualquer outro, por mais privilegiado que seja, como único competente para decidir as questões que eventualmente decorram deste instrumento.</p>

                        </div><!-- /.banner-one__content -->
                    </div><!-- /.col-lg-5 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.banner-one -->
	</div>
</template>

<script>

export default {
	
	metaInfo: {
    title: 'Termos de gerais de uso'
  },

	mounted() {
		this.active = this.selected

	}
}
</script>

<style lang="scss">


.sq-algar{


}
</style>
