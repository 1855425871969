<template>
	<sq-politica-uso
		id="politica-uso"
	/>
</template>

<script>
import SQPoliticaUso from '@/components/templates/SQPoliticaUso'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-politica-uso': SQPoliticaUso
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
