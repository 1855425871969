<template>
	<div class="sq-politica-uso">
      <section class="banner-one" id="home">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="banner-one__content">
                           
                             <div class="block-title">
                                <div class="block-title__text termostitle"><span>Política de Uso Aceitável do SQUAD</span></div><!-- /.block-title__text -->
                            </div><!-- /.block-title -->
                            <p class="termos-one__text mb-3">Este é um serviço destinado a organizações. Assim, se você estiver usando nosso serviço em um local de trabalho ou em um dispositivo ou conta atribuída a você por seu empregador ou outra organização, essa organização provavelmente terá as próprias políticas para o uso do SQUAD, e o uso que você faz do serviço deverá estar de acordo com essas políticas. Entre em contato com sua Organização se tiver dúvidas sobre as políticas aplicadas por ela. </p>

                            <p class="termos-one__text mb-3">Como usar o Squad de forma responsável</p>

                            <p class="termos-one__text mb-3">O Squad criou as regras a seguir para ajudar a garantir o uso responsável de nossos serviços.</p>

                            <p class="termos-one__text mb-3">O que não fazer:</p>

                             <p class="termos-one__text mb-3">
                            <ul>
                                <li>Ocultar sua identidade, fingir ser outra pessoa ou mentir sobre sua afiliação a qualquer pessoa ou entidade.</li>
                                <li>Envolver-se em atividade que explore, fira ou ameace ferir crianças.</li>
                                <li>Criar ou exibir conteúdo ilegal, discriminatório, prejudicial, fraudulento, enganoso ou difamatório ou que promova ou incentive violência, violação de leis, automutilação, distúrbios alimentares ou abuso de drogas.</li>
                                <li>Violar as leis ou infringir os direitos do SQUAD ou de qualquer terceiro.</li>
                                <li>Interferir no funcionamento normal do SQUAD ou no uso do SQUAD por outra pessoa.</li>
                                <li>Carregar de vírus, malware ou outro código mal-intencionado ou fazer algo que possa danificar, inutilizar, sobrecarregar ou prejudicar o Squad ou sistemas relacionados a ele. </li>
                                <li>Permitir que terceiros que não sejam Usuários autorizados acessem ou usem um nome de usuário ou uma senha dos Serviços;</li>
                                <li>Compartilhar, transferir ou fornecer a terceiros acesso de outra forma a uma conta originalmente designada a você;</li>
                                <li>Usar os Serviços para armazenar ou transmitir Dados do Cliente que possam violar ou desapropriar a marca registrada, os direitos autorais ou outra propriedade intelectual de alguém ou que possam ser danosos ou ilícitos;</li>
                                <li>Acessar os Serviços para criar um produto ou serviço semelhante e concorrente ou copiar ideias, recursos, funções ou gráficos dos Serviços;</li>
                                <li>Usar os Serviços para fins de consumo, já que o Squad possui seu uso pretendido para empresas e organizações;</li>
                                <li>Não usar os serviços do Squad se for menor de 18 anos. </li>
                            </ul>
                            </p>

                            <p class="termos-one__text mb-3">O que fazer:</p>

                             <p class="termos-one__text mb-3">
                            <ul>
                                
                            <li>Ler e cumprir com todos os Termos Uso, Termo de Usuário Autorizado e Política de Privacidade, incluindo os termos desta Política de Uso aceitável;</li>
                            <li>Cumprir com todas as leis e normas governamentais aplicáveis, incluindo, sem limitações, leis de propriedade intelectual, dados, privacidade.</li>
                           <li> Fazer upload e divulgar somente Dados de Cliente dos quais o Cliente possui todos os direitos necessários por lei e de acordo com relações contratuais e fiduciárias (como informações proprietárias e informações confidenciais descobertas ou divulgadas como parte da relação empregatícia ou de acordo com contratos de não divulgação) e faz isso somente em consistência com a lei aplicável;</li>
                            <li>Empreender esforços comerciais razoáveis para impedir o acesso ou uso não autorizado dos Serviços;</li>
                            <li>Manter todas as senhas e outras informações de login em sigilo;</li>
                            <li>Monitorar e controlar todas as atividades conduzidas em sua conta em conexão aos Serviços;</li>
                            <li>Notificar-nos prontamente se você tomar conhecimento ou tenha suspeitas razoáveis de atividades ilegais ou não autorizadas ou uma violação de segurança envolvendo suas contas ou equipes, incluindo perda, roubo, divulgação ou uso não autorizado de um nome de usuário, senha ou conta;</li>
                            <li>Cumprir, em todos os aspectos, com todos os termos aplicáveis de apps de terceiros, incluindo qualquer Cliente eleito para integrar-se aos Serviços que você acessa ou assina em relação aos Serviços.</li>
                           <li> Manter-se atualizado com relação aos Termos de Uso, Termos de Usuário Autorizado, Política de Privacidade e Política de Uso Aceitável.</li>

                            </ul>
                        </p>

                        </div><!-- /.banner-one__content -->
                    </div><!-- /.col-lg-5 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.banner-one -->
	</div>
</template>

<script>

export default {
	metaInfo: {
        title: 'Política de Uso Aceitável do SQUAD'
      },
	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">
.sq-politica{
    .block-title__text span:before {
        content: '';
        background-color: #f7f8e7;
        opacity: 0.202;
        width: 100%;
        height: 8px;
        position: absolute;
        bottom: 0px;
        left: 0;
        border-radius: 4px;
    }
}
</style>
