<template>
<div>
	<footer class="site-footer">
            <!-- <img src="images/banner-icon-1-1.png" alt="Awesome Image" class="bubble-1" /> -->
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" alt="Awesome Image" class="bubble-2" />
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" alt="Awesome Image" class="bubble-3" />
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" alt="Awesome Image" class="bubble-4" />
            
            <div class="site-footer__main-footer">
                <button class="scroll-top" data-scroll="up" type="button"><i class="fa fa-arrow-up"></i></button>
                <div class="container">
                    <div class="row">
                        
                        <div class="col-lg-2 col-md-6 col-sm-12">
                            <div class="footer-widget">
                                <h3 class="footer-widget__title">
                                    Empresa
                                </h3><!-- /.footer-widget__title -->
                                <ul class="footer-widget__link-list">
                                    <li><router-link to="/sobre">Sobre nós</router-link></li>
                                    <li><a href="http://citrus.cx/blog">Blog</a></li>
                                    <li><router-link to="/social">Social</router-link></li>
                                    <li><a href="http://citrus.cx">Conheça o ecossistema Citrus</a></li>
                                </ul><!-- /.footer-widget__link-list -->
                            </div><!-- /.footer-widget -->
                        </div><!-- /.col-lg-2 -->
                        <div class="col-lg-2 col-md-6 col-sm-12">
                            <div class="footer-widget">
                                <h3 class="footer-widget__title">
                                    Produtos
                                </h3><!-- /.footer-widget__title -->
                                <ul class="footer-widget__link-list">
                                    <li><router-link to="/recursos">Recursos</router-link></li>
                                    <li><router-link to="/demo">Demonstração</router-link></li>
                                <!--     <li><a href="#">Segurança</a></li>
                                    <li><a href="#">Preços</a></li>
                                    <li><a href="#">Integrações</a></li>
                                    <li><a href="#">Demonstração</a></li> -->
                                    <li><a href="https://materiais.citrus.cx/falar-com-vendas" target="_blank">Falar com vendas</a></li>
                                </ul><!-- /.footer-widget__link-list -->
                            </div><!-- /.footer-widget -->
                        </div><!-- /.col-lg-3 -->
                        <!-- <div class="col-lg-2 col-md-6 col-sm-12">
                            <div class="footer-widget">
                                <h3 class="footer-widget__title">
                                    Parceiros
                                </h3>
                                <ul class="footer-widget__link-list">
                                    <li><a href="#">Encontre um parceiro</a></li>
                                    <li><a href="#">Seja um parceiro</a></li>
                                </ul>
                            </div>
                        </div> -->
                        <div class="col-lg-2 col-md-6 col-sm-12">
                            <div class="footer-widget">
                                <h3 class="footer-widget__title">
                                    Ajuda
                                </h3><!-- /.footer-widget__title -->
                                <ul class="footer-widget__link-list"><!-- 
                                    <li><a href="#">Materiais grátis</a></li>
                                    <li><a href="#">Central de ajuda</a></li> -->
                                    <li><router-link to="/faq">FAQ</router-link></li>
                                    <li><a href="https://materiais.citrus.cx/citrus-squad-fale-conosco" target="_blank">Fale conosco</a></li>
                                </ul><!-- /.footer-widget__link-list -->
                            </div><!-- /.footer-widget -->
                        </div><!-- /.col-lg-2 -->

                        <div class="col-lg-2 col-md-6 col-sm-12">
                            <div class="footer-widget">
                                <h3 class="footer-widget__title">
                                    Termos
                                </h3><!-- /.footer-widget__title -->
                                <ul class="footer-widget__link-list mb-3">
                                    <li><router-link to="/termos-de-uso">Termos gerais de uso</router-link></li>
                                    <li><router-link to="/termos-usuario-autorizado">Termos do usuário</router-link></li>
                                </ul><!-- /.footer-widget__link-list -->
                                
                            </div><!-- /.footer-widget -->
                        </div><!-- /.col-lg-2 -->

                         <div class="col-lg-2 col-md-6 col-sm-12">
                            <div class="footer-widget">
                            
                                 <h3 class="footer-widget__title">
                                    Política
                                </h3><!-- /.footer-widget__title -->
                                <ul class="footer-widget__link-list">
                                    <li><router-link to="/politica-de-privacidade">Política de privacidade</router-link></li>
                                    <li><router-link to="/politica-de-uso">Política de uso</router-link></li>
                                </ul><!-- /.footer-widget__link-list -->
                            </div><!-- /.footer-widget -->
                        </div><!-- /.col-lg-2 -->

                        <div class="col-lg-2 col-md-6 col-sm-12 d-flex">
                            <div class="footer-widget my-auto">
                                <div class="social-block">
                                    <a href="https://twitter.com/citruscx" target="_blank"><i class="fa fa-twitter"></i></a>
                                    <a href="https://www.facebook.com/citrus.cx/" target="_blank"><i class="fa fa-facebook-f"></i></a>
                                    <a href="https://www.linkedin.com/company/citruscx" target="_blank"><i class="fa fa-linkedin"></i></a>
                                    <a href="https://www.youtube.com/channel/UCRjhPkpLlh0S6muu8y6KlmA" target="_blank"><i class="fa fa-youtube-play"></i></a>
                                </div><!-- /.social-block -->
                            </div><!-- /.footer-widget -->
                        </div><!-- /.col-lg-2 -->
                        
                    </div><!-- /.row -->
                </div><!-- /.container -->
            </div><!-- /.site-footer__main-footer -->
            <div class="site-footer__bottom-footer text-center">
                <div class="container">
                    <p>&copy; Squad </p>
                    <p>Powered by <a style="color: #9cb72e;" href="https://www.digivox.com.br/" target="_blank">Digivox</a></p>
                </div><!-- /.container -->
            </div><!-- /.site-footer__bottom-footer -->
        </footer><!-- /.site-footer -->
</div>
</template>

<script>

export default {

	
	methods: {
		onSocialClick(item) {
			window.open(item.link, '_blank')
		}
	}
}
</script>

<style lang="scss">

.scroll-top {
  width: 55px;
  height: 55px;
  position: fixed;
  bottom: 25px;
  right: 20px;
  border:none;
  border-radius: 100px;
  display: none;
  outline: none!important;  
  background: #9cb72e!important;
  z-index: 999999;
}
.scroll-top i {
  display: inline-block;
  color: #fff;
}
</style>
