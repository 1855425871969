<template>
	<div class="sq-cadastro">
		<section class="page-cadastro-01">
           <!--  <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" alt="Awesome Image" class="bubble-1" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" alt="Awesome Image" class="bubble-2" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" alt="Awesome Image" class="bubble-3" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" alt="Awesome Image" class="bubble-4" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" alt="Awesome Image" class="bubble-5" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" alt="Awesome Image" class="bubble-6" /> -->
            <div class="container">
                <div class="col-lg-12">
                    <div class="banner-cs">
                        <div class="banner-cs-title titleone text-center mb-3">
                            <span>Cadastro de usuários</span>
                        </div>
                         <div class="banner-cs-body text-center">
                            Preencha os campos com os dados do(s) usuário(s)
                         </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="page-cadastro-02">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 align-items-center">

                   
                        <form id="se" method="POST" v-on:submit.prevent="addNewUser">
                           <div class="row">
                                <div class="col-md-5">
                                    <div class="site-footer__subscribe-form">
                                        <input id="name" placeholder="Digite o Nome do usuário *" v-model="user.name" />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                     <div class="site-footer__subscribe-form">
                                        <input id="email" placeholder="Digite o e-mail do usuário *" type="email" v-model="user.email" />
                                    </div>
                                </div>
                                <button class="submiter col-md-2"  type="submit">adicionar</button>
                               </div>
                            
                          </form><div style="clear: both"></div>
                          
                          <table class="tableLista">
                            <thead>
                                <tr>
                                    <th style="width: 50%;font-weight: 600;">Nome</th>
                                    <th style="width: 50%;font-weight: 600;">E-mail</th>
                                </tr>
                            </thead>
                              <tr v-for="user in users">
                                  <td>{{user.name}}</td>
                                  <td>{{user.email}}</td>
                              </tr>
                          </table>
                          <!-- 
                          {{json_string}} -->

                          <div v-on:click="handleSubmit()"><button class="submiter2 col-md-2"  type="submit">Enviar</button></div>
                    </div>                  
                </div>
            </div>
        </section>

  	</div>
</template>

<script>
import $ from 'jquery'
export default {
	
	metaInfo: {
      title: 'Cadastro Citrus Squad'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
		
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
        users: [],
        user: { nome:"", email: ""}
	}),
	

	mounted() {
		this.active = this.selected
        

	},
     computed: {
        json_string: function() {
          return JSON.stringify(this.users, null, '    ');
        }
      },
    methods: {
     addNewUser() {

        if($("#name").val() == '' ||  $("#email").val() == ''){
            alert("Preencha corretamente os campos");
        } else { 
            let newUser = {
               name: this.user.name,
               email: this.user.email
            };

            this.users.push(newUser);
            
        }
        
        $("#name").focus()

     },
    
     send() {
        console.log(this.users)

     },
        handleSubmit() {
            const URI = 'https://digivox.citrus.cx/api/atendimentos/novo';

            let nome = $("#name").val();
            let email = $("#email").val();
            // let data = this.json_string;
            // console.log(this.json_string)
            let data = new FormData()
            data.append('wsAtendimentoDTO.occurrence', "356831");
            data.append('wsAtendimentoDTO.channel', "3");
            data.append('wsAtendimentoDTO.clientId', "a73453f0-b498-7eb0-7176-3b7f0b76a90a");
            
            data.append('wsAtendimentoDTO.requesterId', "aab3bae8-95b7-e760-e2ea-b443bec6d110");
            data.append('wsAtendimentoDTO.contractId', "8fb1fe5a-9bc4-6acd-4ceb-227e0001b95a");
            data.append('wsAtendimentoDTO.siteId', "288c3378-f13d-c490-8c27-38f60aee2e5a");
            
            data.append('wsAtendimentoDTO.description', this.getDescription());
            console.log(data)
            $.ajax({
                type: "POST",
                enctype: 'multipart/form-data',
                url: URI,
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                timeout: 600000,
                success: function (data) {
                    this.cleanForm();
                     this.$router.push('/cadastro-concluido');
                },
                complete: () => {
                    this.cleanForm();
                    alert("Recebemos sua solicitação.");
                }
                // error: function (e) {
                //  console.log(e)
                // }
            
            });
        },
        cleanForm() {
            $('#se input').val('');
        },
        getDescription() {
            const urlParams = new URLSearchParams(window.location.search);
            let domain = urlParams.has('dominio') ? urlParams.get('dominio') : 'Domínio não informado!';

            return `Solicitação de instalação do Squad <br /> <strong>Domínio: </strong> ${domain} <br /><strong>Usuários:</strong><br />` + 
                this.users.map((client) => {return `<strong>NOME:</strong> ${client.name} | <strong>E-MAIL:</strong> ${client.email}`}).join('</br>');
        }
    }
}
</script>

<style lang="scss">

.sq-cadastro{
    .tableLista{
        border: solid 1px #dedede;
        border-radius: 5px;
        padding: 6px;
        width: 100%;
        margin: 20px auto;

        th{
            padding: 10px;
            background: #95959521;
        }
        td{
            padding: 5px;
            border-bottom: solid 1px #e0e0e0;
        }
    }
    .cadastrovideo {
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
    }
    .cadastrovideo iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    form{
        width: 90%;
        margin: 0 auto;
    }
    .btc{
        span{
            position: relative;
        }
        span:before{
          content: '';
          background-color: #9cb72e;
          opacity: 0.402;
          width: 100%;
          height: 7px;
          position: absolute;
          bottom: 4px;
          left: 0;
          border-radius: 4px;
          z-index: -1;
        }
    }
    font-family: "Poppins"!important;
    .banner-cs-title {
        span{
            position: relative;
            
            small{
                font-size: 100%!important;
                color: #9cb72e;
                font-weight: bold;
            }
        }
        
        
    }
    .banner-cs-subtitle {
        font-size: 30px;
        font-weight: bold;
        color: #49494c;
        line-height: 120%;

        small{
            font-weight: 500;
            font-size: 60%;
            line-height: 67px;
        }

        img{
            transform: rotate(90deg);
        }
    }
    .page-cadastro-01 {
        padding-top: 150px;
        position: relative;
    }

    .page-cadastro-01 .bubble-1 {
        position: absolute;
        top: 23%;
        left: 10%;
    }

    .page-cadastro-01 .bubble-2 {
        position: absolute;
        top: 100%;
        left: 90%;
    }

    .page-cadastro-01 .bubble-3 {
        position: absolute;
        top: 18%;
        left: 90%;
    }

    .page-cadastro-01 .bubble-4 {
        position: absolute;
        top: 50%;
        left: 75%;
    }

    .page-cadastro-01 .bubble-5 {
        position: absolute;
        top: 42%;
        left: 25%;
    }

    .page-cadastro-01 .bubble-6 {
        position: absolute;
        top: 100%;
        left: 6%;
    }

    .page-cadastro-01 [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }

    .page-cadastro-02 {
        margin-top: 0px;
        margin-bottom: 50px;
    }

    .page-cadastro-02 .pg-col-img {
        width: 80%;
        opacity: 1;
        transition-duration: .5s;
    }

    .page-cadastro-02 .pg-col-img:hover {
        opacity: .7;
        transition-duration: .5s;
    }


}  
</style>
