<template>
	<sq-politica-privacidade
		id="politica-privacidade"
	/>
</template>

<script>
import SQPoliticaPrivacidade from '@/components/templates/SQPoliticaPrivacidade'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-politica-privacidade': SQPoliticaPrivacidade
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
