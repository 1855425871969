<template>
    <div>
	<div class="sq-justicafinal">
        <section class="header_landingpages">
            <img src="https://citrus.cx/wp-content/uploads/2021/01/Marca_Citrus_EUA.png" class="main-logo" alt="Citrus Squad" />
        </section>
		<section class="page-justicafinal-01">
            
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center col-lg-12 text-lg-center" style="align-self: flex-end;">
                        <div class="banner-cs pt-4">
                            
                            <div class="banner-cs-title">
                                Obrigado pelo seu interesse em conhecer o <strong>Citrus Squad</strong>.
                            </div>
                            <div class="banner-cs-body">
                                Em breve um dos nossos consultores entrará em contato para lhe explicar como nossos recursos têm ajudado a garantir a efetivação do acesso à Justiça de maneira remota e segura para a população, advogados, magistrados e agentes públicos.

                            </div>
                            <router-link to="/" class="form_submit">Visitar site do Citrus Squad</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
        
	</div>
    <section class="footerextra">
        <ul>
            <li><a href="https://www.facebook.com/citrus.cx/" target="_blank"><img src="https://citrus.cx/wp-content/uploads/2020/05/fb.png"></a></li>

            <li><a href="https://www.instagram.com/citrus.cx" target="_blank"><img src="https://citrus.cx/wp-content/uploads/2020/05/ig.png"></a></li>
            <li><a href="https://www.linkedin.com/company/citruscx" target="_blank"><img src="https://citrus.cx/wp-content/uploads/2020/05/in.png"></a></li>
        </ul>
        <p>
            © Citrus Squad 2020 <br>  

            Powered by <a href="http://www.digivox.com.br">Digivox</a>
        </p>
    </section>
</div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
	metaInfo: {
      title: 'Squad para a justiça'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
		
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
        form: {
            name: "",
            email:"",
            phone: "",
        },
	}),
	validations: {
        form: {
            name: { required },
            email: { required, email },
            phone: { required },
        },
        
      },

	mounted() {
		this.active = this.selected
	},
    methods: {
        submitform() {         
          this.$v.form.$touch();
          if(this.$v.form.$error) 

        return
            $(".titles").hide();
            $("#influenciadores_form").hide();
            $("#msgthanku").fadeIn('fast');
            let scroll = $(".page-influenciadores-05").position().top - 200
        
            $("html, body").animate({
              scrollTop: scroll
            }, 200);
              return false;
        },
    }
}
</script>

<style lang="scss">
.header_landingpages{
    transform: translate(-50%);
    width: 107%;
    position: absolute;
    top: 0;
    border-radius: 0 0 144% 144%;
    left: 50%;
    background: #fff;
    text-align: center;
    -webkit-box-shadow: 0px 0px 0px #f8f8f8;
    box-shadow: 0px 1px 6px 0px #f8f8f8;
    margin: 0 auto;
    padding: 15px;
    z-index: 9999;

    img{
        width: 200px;
    }
}
.sq-justicafinal {
    max-width: 800px;
    margin: 0 auto;
    height: 95vh!important;
    font-family: "Poppins" !important;
    display: flex;
    align-items: center;

    .banner-cs-title{
        color: #454a58;
        font-weight: 600;

        strong {
            font-weight: 700
        }
    }
    .banner-cs-body {
        margin: 0;
        font-size: 19px;
        line-height: 29px;
        color: #666E82;
        margin-bottom: 40px;
    }
    font-family: 'Poppins'!important;

    .form_submit{
        display: block;
        margin: 37px auto 0;
        background: #9cb63b;
        text-align: center;
        font-size: 15px;
        border-radius: 40px;
        padding: 18px;
        max-width: 310px;
        width: 100%;
        float: none;
        cursor: pointer;
        font-weight: 500;
        color: #fff;
        border: solid 1px #9cb63b;   
    }
}
.footerextra{
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    font-size: 20px;
    color: #c9cbce;
    line-height: 140%;
    border-top: solid 1px #c9cbce;
    padding-top: 20px;
    img{
        width: 33px;
    }
        a{
            color: #c9cbce;
            text-decoration: none;

            &:hover{
                color: #000;
            }
        }
        ul{
            display: inline-flex;
            list-style: none;
            padding-left: 0;

            li{
                margin: 0 4px;
            }
        }
    }
@media(max-width: 560px){
    .sq-justicafinal{
        height: auto;
    }
    .sq-justicafinal .page-justicafinal-01 .justiceFrame {
        position: absolute;
        top: -28vh;
        left: 0;
        z-index: -1;
    }
    .header_landingpages img {
        width: 100px;
    }
    .banner-cd-title{
        font-size: 30px;
        line-height: 146%;
    }

    .sq-justicafinal .page-justicafinal-03 h1{
        font-size: 27px;
    }
    .page-justicafinal-01 .box{
        width: 100%;
        padding: 30px;
    }
    .sq-justicafinal .page-justicafinal-03 .quotes:before {
        content: '';
        background: url(https://citrus.cx/wp-content/uploads/2020/05/quote.png);
        position: absolute;
        top: 12px;
        left: 0;
        width: 48px;
        height: 36px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sq-justicafinal .banner-cs .banner-cs-title {
    margin: 0;
    font-weight: bold;
    color: #49494c;
    font-size: 31px;
    line-height: 33px;
    margin-bottom: 20px;
}
    .sq-justicafinal .page-justicafinal-03 .quotes strong{
        padding-top: 18px;
    }
    .sq-justicafinal .page-justicafinal-04 h1{
        max-width: 100%;
        font-size: 27px;
    }
    .sq-justicafinal .page-justicafinal-04 .banner-cs-body-ul {
        list-style-type: none !important;
        margin: 0;
        padding: 0;
        font-size: 17px;
        line-height: 30px;
        color: #7e818a;
        font-weight: 500;
    }
    .sq-justicafinal .w-90 {
        width: 100%;
    }
    .sq-justicafinal .banner-cs-body {
    margin: 0;
    font-size: 16px;
}
    .footerextra{
    position: relative;
    
    img{
        width: 33px;
    }
        a{
            color: #c9cbce;
            text-decoration: none;

            &:hover{
                color: #000;
            }
        }
        ul{
            display: inline-flex;
            list-style: none;
            padding-left: 0;

            li{
                margin: 0 4px;
            }
        }
    }
}

</style>
