import Vue from 'vue'

export default {
	namespaced: true,

	state: {
		pages: {
			'home': {},	
			
		}
	},

	getters: {
		getHero(state) {
			return state.pages['home']
		},
		
	},

	mutations: {
		set(state, payload) {
			// Organize generic data
			let page = {
				...payload,
				...payload.acf,
				title: payload.title.rendered,
				content: payload.content.rendered,
				excerpt: payload.excerpt.rendered
			}

			// Organize pages data
			switch (page.slug) {

				case 'home':
					page.thumbnails = page.thumbnails ? page.thumbnails.url : undefined
					break
			}

			Vue.set(state.pages, page.slug, page)
		}
	},

	actions: {
		fetch({ state, commit }) {
			return new Promise((resolve, reject) => {
				Vue.http.get('pages')
					.then(response => {
						// Set pages
						response.body.forEach(page =>
							commit('set', page)
						)
						// Return pages
						return resolve(state.pages)
					})
			})
		}
	}
}
