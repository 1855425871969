var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ci-app",attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"loading"}},[(
					_vm.loadingState != 'hide' &&
					(
						_vm.$route.name == 'home' 
						|| _vm.$route.name == 'sobre' 
						|| _vm.$route.name == 'termos' 
						|| _vm.$route.name == 'termosusuario' 
						|| _vm.$route.name == 'recursos' 
						|| _vm.$route.name == 'politica'
						|| _vm.$route.name == 'politicauso'
						|| _vm.$route.name == 'social'
						|| _vm.$route.name == 'faq'
						|| _vm.$route.name == 'demo'
						|| _vm.$route.name == 'cadastro_usuarios'
						|| _vm.$route.name == 'prov'
						|| _vm.$route.name == 'influenciador'
						|| _vm.$route.name == 'solucoes'
						|| _vm.$route.name == 'parajustica'
						|| _vm.$route.name == 'justicafinal'
					)
				)?_c('ci-loading',{staticClass:"ci-app__loading",attrs:{"state":_vm.loadingState}}):_vm._e()],1),_c('div',{staticClass:"ci-app__body"},[(_vm.$route.name != 'influenciador' && _vm.$route.name != 'parajustica'  && _vm.$route.name != 'justicafinal')?_c('ld-header',{staticClass:"ci-app__header"}):_vm._e(),_c('transition',{attrs:{"name":"router","mode":"out-in"}},[(_vm.initialized)?_c('router-view',{key:_vm.$route.path,staticClass:"ci-app__view",attrs:{"scroll":_vm.scroll}}):_vm._e()],1),(_vm.$route.name != 'cadastro_usuarios' &&  _vm.$route.name != 'prov' && _vm.$route.name != 'influenciador' && _vm.$route.name != 'justicafinal')?_c('ld-footer',{staticClass:"ci-app__footer"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }