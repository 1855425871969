<template>
	<div class="sq-solucoes">
	  <section id="home" class="banner-one mb-5">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2 bub">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-3 bub">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4 bub">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5 bub">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6 bub">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="banner-cs">
                        <div class="banner-cs-title">
                           Trabalho remoto e colaborativo em qualquer organização
                        </div>
                        <p class="banner-cs-body">
                            O Squad reúne ferramentas de comunicação e colaboração para que os times conduzam o trabalho em qualquer lugar  sem perder a produtividade.
                        </p>
                        <div class="solucoesbuttons">
                            <router-link to="/demo"  class="header-one__btn btnDark">Assistir demonstração</router-link>
                            <a href="https://materiais.citrus.cx/falar-com-vendas" target="_blank" class="header-one__btn btnLight">Falar com vendas</a>
                        </div>
                    </div>
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/ilsutrasolucoes.png" class="banner-cs-img">
                </div>
            </div>
        </div>
    </section>
    <section class="container page-cs-card">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
        <div class="row mb-5">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div class="card-cs">
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/city-hall.png" class="card-cs-icon">
                    <h3 class="card-cs-title">
                        Governo
                    </h3>
                    <p class="card-cs-body">
                        Conecte seus servidores de todas as secretarias e órgãos.
                    </p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div class="card-cs">
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/health.png" class="card-cs-icon">
                    <h3 class="card-cs-title">
                        Saúde
                    </h3>
                    <p class="card-cs-body">
                        Solução rápida para integração de colaboradores em momentos necessários.
                    </p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div class="card-cs">
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/target.png" class="card-cs-icon">
                    <h3 class="card-cs-title">
                        ONGs
                    </h3>
                    <p class="card-cs-body">
                        Mantenha a autonomia, sem prejudicar as ações das equipes envolvidas.
                    </p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div class="card-cs">
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/payment.png" class="card-cs-icon">
                    <h3 class="card-cs-title">
                        Financeiro
                    </h3>
                    <p class="card-cs-body">
                        Solucione demandas rapidamente com times conectados no mesmo ambiente.
                    </p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div class="card-cs">
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/shopper.png" class="card-cs-icon">
                    <h3 class="card-cs-title">
                        Varejo
                    </h3>
                    <p class="card-cs-body">
                        Facilite o compartilhamento de informações entre equipes externas e internas.
                    </p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div class="card-cs">
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/mortarboard.png" class="card-cs-icon">
                    <h3 class="card-cs-title">
                        Educação
                    </h3>
                    <p class="card-cs-body">
                        Proporcione aulas à distância através de vídeos e conteúdos em tempo real.
                    </p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div class="card-cs">
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/team.png" class="card-cs-icon">
                    <h3 class="card-cs-title">
                        Coworking
                    </h3>
                    <p class="card-cs-body">
                        Tenha espaços de trabalhos compartilhados de maneira virtual e colaborativa.
                    </p>
                </div>
            </div>
             <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div class="card-cs">
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/suitcase.png" class="card-cs-icon">
                    <h3 class="card-cs-title">
                        Corporativo
                    </h3>
                    <p class="card-cs-body">
                        Estabelece um canal centralizado de comunicação entre os times remotos.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section id="" class="drb3 banner-one mt-4 mb-5">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2 bub">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-3 bub">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4 bub">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5 bub">
        <img src="https://citrus.cx/wp-content/uploads/2020/04/base3.png" class="base03">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="banner-cs">
                        <div class="banner-cs-title">
                           Conecte seu time <br>com o Squad
                        </div>
                        <p class="banner-cs-body">
                            Faça o trabalho remoto acontecer com recursos de áudio e videoconferências para várias pessoas simultaneamente, trocas de mensagens via chat, envio de arquivos, gravação de reuniões, agendamento de conferências e link de acesso para convidados.
                        </p>
                        <div class="solucoesbuttons">
                            <router-link to="/recursos"  class="header-one__btn btnLight">Veja todos os recursos</router-link>
                        </div>
                    </div>
                    <img src="https://citrus.cx/wp-content/uploads/2020/04/destaque3.png" class="banner-cs-img-2">
                </div>
                
            </div>
        </div>
    </section>
    <section class="separation-page-cs mb-5">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
        <div class="container">
            <div class="separation-page-cs-title text-center">
                Trabalhe junto. </br>
                Em qualquer lugar.
            </div>
            <router-link to="/demo" class="header-one__btn btnLight mb-2 mb-md-0">Assistir Demonstração</router-link>
            <a href="https://materiais.citrus.cx/falar-com-vendas" target="_blank" class="header-one__btn btnTransparent bg-black">Falar com vendas</a>
        </div>
    </section>
		
	</div>
</template>

<script>

export default {
	
	metaInfo: {
      title: 'Soluções'
    },
	
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
	}),
	

	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">
.sq-solucoes{
    .banner-one{
        background: none!important;
    }
    font-family: 'Poppins'!important;

    .banner-cs-img {
        position: absolute;
        top: -51px;
        right: -613px;
        width: 613px;
    }
    .banner-cs-img-2{
        position: absolute;
        top: -81px;
        right: -580px;
        width: 500px;
    }

    .banner-cs {
        padding: 10px;
    }

    .banner-cs .banner-cs-title {
        margin: 0;
        font-weight: bold;
        color: #454a58;
        font-size: 45px;
        line-height: 56px;
        margin-bottom: 20px;
    }

    .banner-cs .banner-cs-body {
        margin: 0;
        font-size: 15.5px;
        line-height: 32px;
        color: #666E82;
        margin-bottom: 28px;
    }

    .page-cs-card {
        position: relative;
    }

    .page-cs-card [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }

    .page-cs-card .bubble-2 {
        position: absolute;
        top: 80%;
        left: -12%;
    }

    .page-cs-card .bubble-1 {
        position: absolute;
        top: 30%;
        left: 110%;
    }

    .card-cs {
        height: 360px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 45px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-radius: 4px;
        background-color: white;
        -webkit-box-shadow: 0px 10px 60px 0px rgba(46,61,98,0.1);
        box-shadow: 0px 10px 60px 0px rgba(46,61,98,0.1);
        overflow: hidden;
        position: relative;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
    }

    .card-cs:hover{
        margin-top: -15px;
    }

    .card-cs-icon {
        width: 60px;
        height: auto;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
    }

    .card-cs-title {
        margin: 0;
        font-size: 18px;
        line-height: 34px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 22px;
        color: #454a58;
    }

    .card-cs-body {
        color: #454a58;
        font-size: 14.5px;
        line-height: 31px;
        margin: 0;
        margin-bottom: 10px;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
    }

    .separation-page-cs {
        background-color: rgb(244, 248, 235);
        text-align: center;
        padding: 125px 0;
        position: relative;
    }

    .separation-page-cs .separation-page-cs-title {
        margin: 0 0 30px 0;
        font-weight: bold;
        color: #49494c;
        font-size: 46px;
        line-height: 57px;
    }

    .separation-page-cs .header-one__btn.btnTransparent.bg-black {
        color: #49494c;
        border-color: #49494c;
    }

    .separation-page-cs .bubble-1 {
        position: absolute;
        top: 46%;
        left: 22%;
    }

    .separation-page-cs .bubble-2 {
        position: absolute;
        top: 41%;
        left: 74%;
    }

    .separation-page-cs [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }


    @media only screen and (max-width: 1140px) {
        .banner-cs-img {
            display: none;
        }
        .bub{
            opacity: 0.4!important;
        }
    }

    .solucoesbuttons{
        .header-one__btn{
            display: inline-block;
            vertical-align: middle;
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            line-height: 0.50em;
            padding: 22px 46px;
            border-radius: 27px;
            margin-bottom: 10px;
            -webkit-transition: all .4s ease;
            transition: all .4s ease;
            -webkit-box-shadow: 0px 10px 30px 0px #cccccc47;
            box-shadow: 0px 10px 30px 0px #cccccc47;
        }
        .btnDark{

        }
    }
}

.drb3{
    position: relative;
}
.base03{
    position: absolute;
    right: -6%;
    top: -3%;
    width: 53%;
    z-index: 0;
}
@media(max-width: 800px){
    .base03 {
        position: absolute;
        right: 0;
        top: -3%;
        width: 83%;
        z-index: 0;
    }
    .drb3{
        padding: 80px 0!important;
    }
    .banner-cs-img-2{
        display: none;
    }
}

@media(max-width: 680px){
    .sq-solucoes .banner-cs .banner-cs-title {
        margin: 0;
        font-weight: bold;
        color: #454a58;
        font-size: 35px;
        line-height: 46px;
        margin-bottom: 20px;
    }
    .sq-solucoes .card-cs{
        height: auto;
    }
    .sq-solucoes .drb3{
        padding-bottom: 100px;
        padding-top: 0!important;
    }
    .sq-solucoes .separation-page-cs .separation-page-cs-title {
        margin: 0 0 30px 0;
        font-weight: bold;
        color: #49494c;
        font-size: 36px;
        line-height: 46px;
    }
    .base03 {
        position: absolute;
        right: 0;
        top: -9%;
        width: 163%;
        z-index: 0;
    }
}
</style>
