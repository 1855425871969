<template>
	<sq-social
		id="social"
	/>
</template>

<script>
import SQSocial from '@/components/templates/SQSocial'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-social': SQSocial
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
