<template>
<div class="sq-demo">
	<section class="page-demo-01">
        <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1" />
        <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2" />
        <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-3" />
        <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4" />
        <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5" />
        <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6" />
        <div class="container">
            <div class="col-lg-12">
                <div class="banner-cs">
                    <div class="banner-cs-title titleone text-center mb-3">
                        <span>Faça o <small>trabalho <br>remoto</small> acontecer</span>
                    </div>
                    <p class="banner-cs-subtitle text-center">
                       Conecte seu time <br>usando o SQUAD.
                       <br>
                       <small>Veja como funciona <img src="https://citrus.cx/wp-content/uploads/2020/03/shape2.png"></small>
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section class="page-demo-02">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 mx-auto d-flex align-items-center">
                     <div class="demovideo">
                        <iframe width="100%" src="https://www.youtube-nocookie.com/embed/YyIPpkD--q0?autoplay=1;mute=1?controls=0?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                     </div>
                </div>              
            </div>
        </div>
    </section>

    <section class="page-demo-03">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 align-items-center">
                    <div class="banner-cs">
                        <p class="banner-cs-subtitle text-center mb-0">
                            Trabalhe em equipe.
                        </p>
                        <p class="banner-cs-subtitle text-center mb-4 btc">
                            <span>Colabore em qualquer lugar.</span>
                        </p>
                        <div class="d-flex justify-content-center">
                            <a style="margin: 0 auto;" href="https://materiais.citrus.cx/juntos-squad" target="_blank" class="header-one__btn btnLight">Junte-se a nós</a>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </section>
</div>
</template>

<script>

export default {
	
	metaInfo: {
      title: 'Demo Citrus Squad'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
		
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
	}),
	

	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">

.sq-demo{

    .demovideo {
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        overflow: hidden;
        border-radius: 9px;
        box-shadow: -1px 1px 15px 0px #cdcdcd;
    }
    .demovideo iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .btc{
        span{
            position: relative;
        }
        span:before{
          content: '';
          background-color: #9cb72e;
          opacity: 0.402;
          width: 100%;
          height: 7px;
          position: absolute;
          bottom: 4px;
          left: 0;
          border-radius: 4px;
          z-index: -1;
        }
    }
    font-family: "Poppins"!important;
    .banner-cs-title {
        span{
            position: relative;
            
            small{
                font-size: 100%!important;
                color: #9cb72e;
                font-weight: bold;
            }
        }
        
        
    }
    .banner-cs-subtitle {
        font-size: 30px;
        font-weight: bold;
        color: #49494c;
        line-height: 120%;

        small{
            font-weight: 500;
            font-size: 60%;
            line-height: 67px;
        }

        img{
            transform: rotate(90deg);
        }
    }
    .page-demo-01 {
        padding-top: 150px;
        position: relative;
    }

    .page-demo-01 .bubble-1 {
        position: absolute;
        top: 23%;
        left: 10%;
    }

    .page-demo-01 .bubble-2 {
        position: absolute;
        top: 100%;
        left: 90%;
    }

    .page-demo-01 .bubble-3 {
        position: absolute;
        top: 18%;
        left: 90%;
    }

    .page-demo-01 .bubble-4 {
        position: absolute;
        top: 50%;
        left: 75%;
    }

    .page-demo-01 .bubble-5 {
        position: absolute;
        top: 42%;
        left: 25%;
    }

    .page-demo-01 .bubble-6 {
        position: absolute;
        top: 100%;
        left: 6%;
    }

    .page-demo-01 [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }

    .page-demo-02 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .page-demo-02 .pg-col-img {
        width: 80%;
        opacity: 1;
        transition-duration: .5s;
    }

    .page-demo-02 .pg-col-img:hover {
        opacity: .7;
        transition-duration: .5s;
    }

    .page-demo-03 {
        position: relative;
        margin-top: 80px;
    }

    .page-demo-03 .bubble-1 {
        position: absolute;
        top: 23%;
        left: 10%;
    }

    .page-demo-03 .bubble-2 {
        position: absolute;
        top: 100%;
        left: 90%;
    }

    .page-demo-03 [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }

}


  
</style>
