<template>
	<sq-influenciadores
		id="influenciadores"
	/>
</template>

<script>
import SQInfluenciadores from '@/components/templates/SQInfluenciadores'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-influenciadores': SQInfluenciadores
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
