<template>
	<sq-justicafinal
		id="justicafinal"
	/>
</template>

<script>
import SQJusticaFinal from '@/components/templates/SQJusticaFinal'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-justicafinal': SQJusticaFinal
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
