<template>
	<div class="ci-loading"
		:class="[
			`ci-loading--${state}`
		]"
	>
		<img class="loader" src="https://citrus.cx/wp-content/uploads/2020/03/csquad.png">
	</div>
</template>

<script>

import { TweenMax, Power2 } from 'gsap/TweenMax'

export default {
	

	props: {
		theme: {
			type: String,
			default: 'primary' // primary | accent | warn
		},
		state: {
			type: String,
			default: 'idle' // idle | loading | done
		}
	},

	data: () => ({
		progress: 0,
		tweenedProgress: 0,
		duration: 8
	}),

	computed: {
		animatedProgress: function() {
			return this.tweenedProgress.toFixed(0);
		}
	},

	watch: {
		state(value) {
			switch(value) {
				case 'idle':
					this.progress = 0
					this.duration = .5
					break
				case 'loading':
					this.progress = 70
					this.duration = 10
					break
				case 'done':
					this.progress = 100
					this.duration = .5
					break
			}
		},

		progress(value) {
			TweenMax.to(this.$data, this.duration, {
				tweenedProgress: value,
				ease: Power2.easeOut
			})
		},
	},
}
</script>

<style lang="scss">
.ci-loading{
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: #fff;
	margin-left: -1px;
	margin-top: -1px;
	z-index: 999!important;

	.loader{
		position: absolute;
		width: 50px;
		top: 50%;left: 50%;
		transform: translate(50%, 50%!important);
	}
}
.loader{
  animation: animationFrames linear 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: animationFrames linear 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationFrames linear 4s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationFrames linear 4s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationFrames linear 4s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

@keyframes animationFrames{
  0% {
    transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-moz-keyframes animationFrames{
  0% {
    -moz-transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -moz-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -moz-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -moz-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -moz-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -moz-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -moz-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -moz-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -moz-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -moz-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -moz-transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -webkit-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -webkit-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -webkit-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -webkit-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -webkit-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -webkit-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -webkit-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -webkit-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -webkit-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -webkit-transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -o-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -o-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -o-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -o-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -o-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -o-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -o-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -o-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -o-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -o-transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -ms-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -ms-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -ms-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -ms-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -ms-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -ms-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -ms-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -ms-transform:  translate(-25px,-21px)  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -ms-transform:  translate(-25px,-21px)  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -ms-transform:  translate(-25px,-21px)  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}
</style>
