<template>
	<div class="sq-politica">
      <section class="banner-one" id="home">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="banner-one__content">
                           <div class="block-title">
                                <div class="block-title__text termostitle"><span>POLÍTICA DE PRIVACIDADE DA PLATAFORMA SQUAD</span></div><!-- /.block-title__text -->
                            </div><!-- /.block-title -->
                            <p class="termos-one__text mb-3">LEIA COM ATENÇÃO ESTA POLÍTICA DE PRIVACIDADE. AO CONTRATAR UMA LICENÇA DE USO DO SOFTWARE SQUAD, A LICENCIADA RECONHECE A INTEGRIDADE DESTA POLÍTICA DE PRIVACIDADE E CONCORDA QUE SE VINCULOU INTEGRALMENTE ÀS CONDIÇÕES ABAIXO DE FORMA LIVRE E ESPONT NEA. CASO NÃO ESTEJA DE ACORDO COM O CUMPRIMENTO DOS TERMOS E CONDIÇÕES AQUI PREVISTOS, VOCÊ NÃO DEVE CONTRATAR E/OU FAZER USO DO SOFTWARE SQUAD. </p>

                            <h2 class="termos-one__text mb-2"><strong>1. INTRODUÇÃO</strong></h2>

                            <p class="termos-one__text mb-2">1.1 A Licenciante respeita a privacidade de seus dados e de seus clientes e está empenhada em proteger as informações fornecidas e/ou disponibilizadas a partir do uso da plataforma SQUAD. Abaixo se encontram os princípios, regras e obrigações que devem nortear as práticas de privacidade relacionadas à plataforma SQUAD. Ao contratar uma licença de uso do Software, Você concorda em aceitar e cumprir com os termos e condições deste documento.</p>

                            <p class="termos-one__text mb-2">1.2 Pelo presente instrumento, Você concorda que a “Licenciante” terá acesso a Informações. Este documento estabelece como se dará o acesso a tais Informações, como serão armazenadas e como poderão ser utilizadas pelo SQUAD. A “Licenciante” compromete-se com a privacidade e proteção das Informações nos termos da legislação brasileira e desta Política de Privacidade.</p> 


                            <p class="termos-one__text mb-2">1.3 Todas as Informações que possam identificar o “Licenciado” isoladamente, incluindo quaisquer Informações sobre seus clientes, não serão disponibilizadas voluntariamente pela Licenciante a terceiros, salvo nas hipóteses previstas nesta Política de Privacidade.</p> 

                             <p class="termos-one__text mb-2">1.3 Todas as Informações que possam identificar o “Licenciado” isoladamente, incluindo quaisquer Informações sobre seus clientes, não serão disponibilizadas voluntariamente pela Licenciante a terceiros, salvo nas hipóteses previstas nesta Política de Privacidade.</p> 

                             <h2 class="termos-one__text mb-2"><strong>2.SOFTWARE SQUAD</strong></h2>

                             <p class="termos-one__text mb-2">2.1. É aqui denominado como “Plataforma SQUAD”, o software para download ou acessado via “nuvem” (Cloud service) de titularidade da “Licenciante”, licenciado para uso do “Licenciado” conforme os Termos Gerais de Uso da Plataforma SQUAD, plataforma que dispõe ferramentas de colaboração e produtividade que facilitam a comunicação entre equipes internas e externas através de recursos de voz, vídeo, mensagens e conferência.</p>

                            <p class="termos-one__text mb-2">2.2. A Licenciante poderá promover correções e aperfeiçoamentos da Plataforma SQUAD a qualquer tempo, a seu exclusivo critério, ocasiões na qual o software atualizado estará automaticamente sujeito aos termos desta Política de Privacidade. </p>

                            <h2 class="termos-one__text mb-2"><strong>3.INFORMAÇÕES COLETADAS E ARMAZENADAS</strong></h2>

                            <p class="termos-one__text mb-2"> 3.1 A Plataforma SQUAD coleta e armazena as informações inseridas voluntariamente pelo “Licenciado” e seus Usuários Autorizados na condução de suas atividades, para o devido funcionamento e operação, bem como para o funcionamento dos serviços de banco de dados, backup e cópia de segurança. Ao utilizar a plataforma SQUAD, o “Licenciado” concorda com a coleta e armazenamento de dados objetivando unicamente a execução dos fins acima delineados. O Software SQUAD coleta Informações estritamente necessárias para o desempenho de suas funcionalidades e aplicações, conforme inseridas pelo “Licenciado” no Software.</p>
                            
                            <p class="termos-one__text mb-2"> 3.2 Ao utilizar a Plataforma SQUAD para a consecução de seus objetivos, o “Licenciado” coletará e armazenará informações de seus clientes e Usuários Autorizados para o desempenho de suas próprias atividades. O “Licenciado” reconhece a titularidade e sensibilidade de tais Informações e concorda em dar aos dados pessoais porventura coletados o tratamento de acordo com os termos da Lei no 13.709/2018. Além disso, o “Licenciado” declara e garante possuir autorização expressa e por escrito de seus clientes e Usuários Autorizados para a inclusão de seus dados na plataforma SQUAD.</p>
                            
                            <p class="termos-one__text mb-2"> 3.3 Nos termos da Lei no 13.709/2018, ao tratar informação relacionada a pessoa natural identificada ou identificável, sensíveis ou não, inseridas na plataforma SQUAD pelo “Licenciado”, este se qualifica como Controlador de dados, pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais, qualificando a “Licenciante”, por sua vez, como Operador de dados, pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.</p>
                            
                            <p class="termos-one__text mb-2"> 3.4 A “Licenciante” se compromete a realizar o tratamento de dados pessoais do “Licenciado” e do “Usuário Autorizado”, porventura coletados em razão da utilização da plataforma SQUAD, exclusivamente seguindo as diretrizes apontadas pelo Controlador de Dados, nos termos da Lei no 13.709/2018, conforme cláusulas 3.5 e 3.6 a seguir.</p>
                            
                            <p class="termos-one__text mb-2"> 3.5 O “Licenciado”, na qualidade de Controlador de dados, deverá garantir ao titular os direitos de confirmação da existência de tratamento, acesso aos dados, correção de dados incompletos, inexatos ou desatualizados, anonimização, portabilidade, bloqueio ou eliminação de dados.</p>
                            
                            <p class="termos-one__text mb-2"> 3.6 A “Licenciante”, na qualidade de Operador de dados, realizará o tratamento de dados em conformidade com as diretrizes do Licenciado, na qualidade de Controladora, garantindo a realização das operações expressamente solicitadas pela Licenciada e elencadas na cláusula 3.5, bem como demais direitos garantidos na Lei nº 13.709/2018.</p>
                            
                            <p class="termos-one__text mb-2"> 3.7 A plataforma SQUAD armazena Informações de acesso e conexão, tais como hora, tipo de navegador, linguagem do navegador e, endereço de Protocolo de Internet, durante a utilização. Estas informações poderão ser utilizadas para geração de relatórios internos a respeito da maneira e hábitos de uso do Software com o objetivo de aperfeiçoar a experiência do usuário junto à plataforma SQUAD. Tais informações serão tratadas segundo os parâmetros da Lei nº 13.709/2018, e, apenas quanto a estas informações a CITRUS se classifica como Controlador de Dados.</p>

                            <p class="termos-one__text mb-2"> 3.8 As informações inseridas pelo “Licenciado” ou seus “Usuários Autorizados” e coletadas pela “Licenciante” são armazenadas em servidores e geridos pela CITRUS exclusivamente para consecução dos objetivos da plataforma SQUAD, guardando relação de adequação e finalidade quanto à proteção de dados.</p>

                            <p class="termos-one__text mb-2"> 3.9 As Informações disponibilizadas pelo “Licenciado” ou seus “Usuários Autorizados” por meio do uso da plataforma SQUAD serão armazenadas em servidores que permitem o acesso a informações remotamente, mediante senha de acesso pessoal e intransferível.</p>

                             <h2 class="termos-one__text mb-2"><strong>4.COOKIES E BEACONS</strong></h2>

                             <p class="termos-one__text mb-2">4.1 Ao acessar a plataforma SQUAD a partir de um navegador, um cookie será enviado para o computador no qual o Software foi acessado. Um cookie é um fragmento de dados que o identifica como usuário do Software SQUAD. Caso o “Licenciado” ou o “Usuário Autorizado” configure o navegador para recusar cookies, os serviços disponibilizados através da plataforma SQUAD poderão não funcionar corretamente.</p>

                            <p class="termos-one__text mb-2">4.2 Os beacons auxiliam no gerenciamento e aperfeiçoamento do Software SQUAD quando utilizado a partir de navegadores. Informações de acesso e/ou de conexão (Internet Protocol - IP, tipo de browser e linguagem, prestador de serviços de internet, páginas de referência e de saída, data/hora, etc) poderão ser identificadas e coletadas pela CITRUS através da ferramenta beacon.</p>

                            <h2 class="termos-one__text mb-2"><strong>5.USO LIMITADO DAS INFORMAÇÕES</strong></h2>

                            <p class="termos-one__text mb-2">5.1 Algumas das Informações poderão ser armazenadas com o objetivo de (i) analisar as preferências e hábitos do “Licenciado” ou “Usuário Autorizado” para aprimorar e aperfeiçoar a experiência de uso da plataforma SQUAD; (ii) contatar o “Licenciado” diretamente (ou por meio de parceiros) para comunicar sobre atualizações e correções necessárias à plataforma SQUAD; e/ou (iii) classificar e agrupar Informações, assim como consolidar dados estatísticos a respeito do uso da plataforma SQUAD, inclusive para definir a composição de “Licenciados” e o volume de tráfego nos servidores da “Licenciante”.</p>
                            
                            <p class="termos-one__text mb-2">5.2 A “Licenciante” não divulga a terceiros Informações identificáveis. A “Licenciante” poderá, no entanto, compartilhar Informações identificáveis com parceiros de negócios, de suporte e assistência, para fins de segurança, de aperfeiçoamento da experiência e usabilidade da plataforma SQUAD, dentre outras. Além disso, a Licenciante poderá disponibilizar Informações armazenadas (i) caso seja solicitado por uma ordem expedida emitida por autoridade competente; (ii) em caso de violação ou suspeita de violação desta Política de Privacidade, dos Termos Gerais de Uso da Plataforma SQUAD, do Termo de Usuário Autorizado, da Política de Uso Aceitável ou da legislação aplicável; ou ainda (iii) para proteger os direitos da “Licenciante” ou de terceiros. Em qualquer hipótese, a disponibilização das informações limitar-se-á ao mínimo necessário para atingimento dessas finalidades.</p>
                            
                            <p class="termos-one__text mb-2">5.3 A “Licenciante” poderá, ainda, transferir as Informações a terceiros, em caso de operações societárias, como fusão, aquisição ou venda de seus ativos. Caso o processamento das Informações após tais eventos seja alterado, a “Licenciante” ou o terceiro envolvido deverá notificar o “Licenciado” por escrito.
                            5.4 O uso das Informações coletadas e/ou armazenadas exige o processamento de dados que poderá ser feito pela própria “Licenciante” ou por parceiros contratados para esse fim, no Brasil ou no exterior, que também estarão sujeitos a esta Política de Privacidade. O “Licenciado” expressamente concorda com a transferência desses dados aos parceiros da “Licenciante”.</p>
                            
                            <p class="termos-one__text mb-2">5.5 A “Licenciante” emprega sistemas de segurança em consonância com o estado da técnica e usualmente adotados pelo mercado para proteção das Informações contra acessos desautorizados, não se responsabilizando, contudo, por violações advindas de terceiros não autorizados. </p>

                            <h2 class="termos-one__text mb-2"><strong>6. ALTERAÇÕES E REGISTRO DAS INFORMAÇÕES DO LICENCIADO</strong></h2>

                            <p class="termos-one__text mb-2">6.1 É dever do “Licenciado” manter as Informações institucionais atualizadas, tais como endereço, razão social, contatos, dentre outros.</p>
                            
                            <p class="termos-one__text mb-2">6.2 Caso a Licença de Uso da plataforma expire ou seja rescindida, a “Licenciante” possibilitará a exportação dos dados dos clientes e históricos de atendimento inseridos pelo “Licenciado” na plataforma SQUAD, no prazo de até 45 dias após o cancelamento. Após o envio em formato .csv, todos os dados serão permanentemente excluídos dos bancos de dados da “Licenciante”.</p>
                            
                            <p class="termos-one__text mb-2">6.3 Não obstante o disposto acima, as informações referentes à data e hora de acesso e ao endereço de protocolo de internet utilizado pelo “Licenciado” ou seu “Usuário Autorizado” para acessar o Site e o SQUAD permanecerão armazenadas pela “Licenciante” por 6 (meses) a contar da data de cada acesso realizado, independentemente do término da relação jurídica e comercial entre a “Licenciante” e o “Licenciado”, em cumprimento ao disposto no Artigo 15 da Lei nº 12.965/2014, podendo ser armazenados por um período maior de tempo mediante ordem judicial.</p>

                            <h2 class="termos-one__text mb-2"><strong>7. PROTEÇÃO DAS INFORMAÇÕES</strong></h2>

                            <p class="termos-one__text mb-2">7.1 A “Licenciante” utiliza rigorosos padrões de segurança para autenticação de serviços pela internet, garantindo desta forma a segurança dos dados coletados, através de procedimentos eletrônicos de gestão, incluindo a certificação EV SSL (“Extended Validation SSL”). Todos os dados são armazenados em nuvem e controlados por acesso limitado.</p>
                            
                            <p class="termos-one__text mb-2">7.2 Não obstante os mecanismos de proteção das Informações armazenadas, a “Licenciante” apenas pode garantir a segurança das Informações que o “Licenciado” disponibilizar a partir de um acesso/conexão segura de internet. O Licenciado se compromete, portanto, a tomar todas as precauções necessárias para proteger os seus dados/Informações assim como o de seus clientes e Usuários Autorizados, sempre que estiver em um ambiente virtual desconhecido.</p>
                            
                            <p class="termos-one__text mb-2">7.3 O uso e a proteção dos dados/senha/login de acesso e uso da plataforma SQUAD são de inteira responsabilidade do “Licenciado”. O “Licenciado” se compromete a proteger os dados/senhas/login em local seguro e a não fornecer a terceiros. A “Licenciante” não se responsabilizará pela utilização inadequada ou indevida do software SQUAD e/ou por uso e/ou divulgação de dados/informações por terceiros que o tiverem acessado com os dados/senha/login do “Licenciado” ou de seus Usuários Autorizados.</p>

                            <h2 class="termos-one__text mb-2"><strong>8. RESPONSABILIDADE</strong></h2>

                            <p class="termos-one__text mb-2">8.1 A “Licenciante” não garante que os seus sistemas de proteção de dados sejam isentos de erros, fraudes ou ataques, não podendo, por consequência, ser responsabilizada por falhas eventuais, fraude ou ataques em tais sistemas de segurança.</p>
                            
                            <p class="termos-one__text mb-2">8.2 O “Licenciado” concorda em manter a “Licenciante” isenta de qualquer incidente decorrente de terceiros, incluindo seus clientes e Usuários Autorizados, diante do uso indevido ou divulgação não autorizada de informações de tais terceiros, pelo “Licenciado”, seus sócios, administradores, diretores, empregados, contratados e quaisquer prepostos, a não ser nas hipóteses em que seja comprovada, judicialmente, culpa por parte da “Licenciante”. O “Licenciado” se compromete, ainda, a indenizar a “Licenciante” por qualquer dano/prejuízo nesse sentido, incluindo despesas, encargos e honorários advocatícios.</p>

                            <h2 class="termos-one__text mb-2"><strong>9.ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</strong></h2>

                            <p class="termos-one__text mb-2">9.1 A “Licenciante” poderá alterar esta Política de Privacidade a qualquer tempo, a seu exclusivo critério, independente de notificação prévia, cabendo ao “Licenciado” manter-se atualizada através do portal SQUAD bem como da própria plataforma.</p>

                            <h2 class="termos-one__text mb-2"><strong>10.INFORMAÇÕES ADICIONAIS</strong></h2>                            

                            <p class="termos-one__text mb-2">10.1 Esta Política de Privacidade é regida pelas leis da República Federativa do Brasil. As partes elegem o foro da Comarca de João Pessoa, Paraíba, Brasil, com exclusão de qualquer outro, por mais privilegiado que seja, como único competente para decidir as questões que eventualmente decorram deste instrumento.</p>
                            
                            <p class="termos-one__text mb-2">10.2 Caso tenha quaisquer questões ou preocupações acerca da coleta, uso, armazenagem ou divulgação de Informações disponibilizadas pela Licenciada, entrar em contato com a “Licenciante” através pelo e-mail atendimento@citrussquad.com.br ou pelo portal de atendimento ao cliente (“Portal do Cliente”).</p>

                        </div><!-- /.banner-one__content -->
                    </div><!-- /.col-lg-5 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.banner-one -->
	</div>
</template>

<script>

export default {
	metaInfo: {
        title: 'Política de privacidade da plataforma Squad'
      },
	mounted() {
		this.active = this.selected
	}
}
</script>
<style lang="scss">
.sq-politica{
    .block-title__text span:before {
        content: '';
        background-color: #f7f8e7;
        opacity: 0.202;
        width: 100%;
        height: 8px;
        position: absolute;
        bottom: 0px;
        left: 0;
        border-radius: 4px;
    }
}
</style>
