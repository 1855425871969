import Vue from 'vue'
import Router from 'vue-router'
import LDHome from '@/components/pages/LDHome'
import LDTermos from '@/components/pages/LDTermos'
import LDTermosUsuario from '@/components/pages/LDTermosUsuario'
import LDPoliticaPrivacidade from '@/components/pages/LDPoliticaPrivacidade'
import LDPoliticaUso from '@/components/pages/LDPoliticaUso'
import LDSobre from '@/components/pages/LDSobre'
import LDSocial from '@/components/pages/LDSocial'
import LDRecursos from '@/components/pages/LDRecursos'
import LDFaq from '@/components/pages/LDFaq'
import LDDemo from '@/components/pages/LDDemo'
import LDProv from '@/components/pages/LDProv'
import LDCadastro from '@/components/pages/LDCadastro'
import LDInfluenciadores from '@/components/pages/LDInfluenciadores'
import LDSolucoes from '@/components/pages/LDSolucoes'
import LDJustica from '@/components/pages/LDJustica'
import LDJusticaFinal from '@/components/pages/LDJusticaFinal'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
		{
			path: '/',
			name: 'home',
			component: LDHome
		},
		{
			path: '/sobre',
			name: 'sobre',
			component: LDSobre
		},
		{
			path: '/recursos',
			name: 'recursos',
			component: LDRecursos
		},
		{
			path: '/termos-de-uso',
			name: 'termos',
			component: LDTermos
		},
		{
			path: '/termos-usuario-autorizado',
			name: 'termosusuario',
			component: LDTermosUsuario
		},
		{
			path: '/politica-de-privacidade',
			name: 'politica',
			component: LDPoliticaPrivacidade
		},
		{
			path: '/politica-de-uso',
			name: 'politicauso',
			component: LDPoliticaUso
		},
		{
			path: '/social',
			name: 'social',
			component: LDSocial
		},
		{
			path: '/faq',
			name: 'faq',
			component: LDFaq
		},
		{
			path: '/demo',
			name: 'demo',
			component: LDDemo
		},
		{
			path: '/listar_usuarios',
			name: 'cadastro_usuarios',
			component: LDCadastro
		},
		{
			path: '/cadastro-concluido',
			name: 'prov',
			component: LDProv
		},
		{
			path: '/influenciador-do-bem',
			name: 'influenciador',
			component: LDInfluenciadores
		},
		{
			path: '/solucoes',
			name: 'solucoes',
			component: LDSolucoes
		},
		{
			path: '/squad-para-justica',
			name: 'parajustica',
			component: LDJustica
		},
		{
			path: '/justica-finalizado',
			name: 'justicafinal',
			component: LDJusticaFinal
		}
	],
	scrollBehavior() {
	    window.scrollTo(0,0);
  	}
})

export default router
