<template>
	<div class="sq-home">
	    <section class="banner-one" id="home">
        	<img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-3" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6" />
        	<img  src="https://citrus.cx/wp-content/uploads/2020/04/tela-squad-home.png" class="banner-one__moc" alt="Awesome Image"/>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="banner-one__content">
                            <div class="block-title">
                                <div class="block-title__text"><span>TODO O SEU TIME ESTÁ DE HOME OFFICE... <br>E AGORA?</span></div><!-- /.block-title__text -->
                            </div>
                            <p class="banner-one__text">Conte com o Squad para seu time continuar engajado colaborando mesmo de maneira remota.  Ofereça acesso ao máximo de informações atualizadas a um clique, pelo celular ou computador.</p>
                            <div class="field_input">
                                <a href="https://materiais.citrus.cx/juntos-squad" target="_blank" class="banner-one__btn btnDark">Junte-se a nós</a>
                            </div>
                            <p class="banner-one__text">Já se juntou ao Squad? <a href="http://app.citrussquad.com/login" target="_blank">Entre</a></p><!-- /.banner-one__text -->
                        </div>
                    </div>
                </div>
            </div>
        </section>        
        <section class="videohome">
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2" />
            <img  src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-3" />
            <div class="container">
               <div class="row">
                   <div class="col-md-6">
                        <ld-player class="ci-about__player ci-about__animate--scale">
                        </ld-player>
                   </div>
                   <div class="col-lg-6">
                        <div class="banner-one__content">
                            <div class="block-title">
                                <div class="block-title__text"><span>Conecte-se a <br> sua equipe</span></div>
                            </div>
                            <p class="banner-one__text">Faça reuniões por áudio, videoconferência ou chat. Comunique-se remotamente e de maneira segura com sua equipe como se estivessem todos na mesma sala.</p>  
                        </div>
                    </div>
               </div>
            </div>
        </section>
        <section class="service-one" id="services">
            <div class="container">
                <div class="block-title text-center">
                    <div class="block-title__text"><span>O apoio que <br> seu time precisa </span></div><!-- /.block-title__text -->
                </div>
                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="service-one__single">
                            <div class="service-one__icon boximg box1"></div>
                            <h3 class="service-one__title">Móvel e fácil</h3><!-- /.service-one__title -->
                            <p class="service-one__text">O Squad está em todos os seus dispositivos. Use direto no navegador, onde estiver e sem precisar fazer downloads e instalação. É mais simples! </p>
                        </div><!-- /.service-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="service-one__single">
                            <div class="service-one__icon boximg box2"></div>
                            <h3 class="service-one__title">Espaço <br> colaborativo</h3><!-- /.service-one__title -->
                            <p class="service-one__text">Faça videochats e conferências com sala fixa, agende através de envios de links para usuários externos e grave reuniões para que todo o time possa assistir.</p><!-- /.service-one__text -->
                        </div><!-- /.service-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="service-one__single">
                            <div class="service-one__icon boximg box3"></div>
                            <h3 class="service-one__title">Compartilhamento de vídeos e arquivos</h3><!-- /.service-one__title -->
                            <p class="service-one__text">Colabore com sua equipe durante as interações, envie links arquivos de mídia e documentos via chat a qualquer momento, inclusive durante uma videoconferência.</p><!-- /.service-one__text -->
                        </div><!-- /.service-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="service-one__single">
                            <div class="service-one__icon boximg box4"></div>
                            <h3 class="service-one__title">Conteúdo em tempo real</h3><!-- /.service-one__title -->
                            <p class="service-one__text">Comunique avisos, realize treinamentos ou reuniões através de webinars e transmissões ao vivo (lives) para todos os integrantes do seu grupo, onde quer que estejam.</p><!-- /.service-one__text -->
                        </div><!-- /.service-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-md-12" style="text-align: center;">
                        <a href="https://www.citrussquad.com/recursos" target="_blank" class="header-one__btn btnLight mt-5">Colabore com seu time onde estiver →</a>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.service-one -->
        <section class="cta-three" id="conheca">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-1" />
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-2" />
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-3" />
            <div class="container">
                <img src="https://citrus.cx/wp-content/uploads/2020/03/cta-moc-1-1.png" alt="Awesome Itmage" class="cta-three__moc" />
                <div class="row no-gutters justify-content-end">
                    <div class="col-lg-6 col-md-12 col-sm-12 d-flex">
                        <div class="cta-three__content my-auto">
                            <div class="block-title">
                                <div class="block-title__text">Times trabalhando juntos remotamente</div><!-- /.block-title__text -->
                            </div>
                            <p class="cta-three__text">Resolva problemas em equipe de forma rápida, mesmo que estejam em lugares diferentes do mundo. O Squad reúne todos como se estivessem na mesma sala. </p>
                            <ul class="cta-three__list">
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Vídeochamada para várias pessoas simultaneamente </li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Chats com envio de arquivos</li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Gravação de reuniões na nuvem</li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Agendamento de conferências</li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Link de acesso para convidados</li>
                            </ul><!-- /.cta-three__list -->
                        </div><!-- /.cta-three__content -->
                    </div><!-- /.col-lg-6 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.cta-three -->

        <section class="testemony">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <agile :navButtons="false">
                            <div class="slide">
                                <div class="row">
                                    <div class="sl">
                                        <h1>Realizamos a 1ª Sessão do Colégio de Procuradores do Ministério Público da Paraíba por videoconferência, através do Squad, um sistema atualizado e de fácil utilização. Acreditamos que o uso desse instrumento deve ser cada vez mais incentivado dentro das instituições públicas, diminuindo custos de viagens e riscos de deslocamento.
                                        <br><strong>Francisco Seráfico <br> <span> Procurador Geral de Justiça do MPPB</span></strong>
                                    </h1>
                                    </div>
                                </div>
                            </div>
                                                
                            <div class="slide">
                                <div class="row">
                                    <div class="sl">
                                        <h1>Em decorrência da pandemia do COVID-19 suspendemos atos presenciais do Poder Judiciário, como medida para preservar a saúde da população. No entanto, o Judiciário não parou, estamos trabalhando em Home Office nos casos de audiências que não podem esperar. Realizamos 4 audiências concentradas muito proveitosas através de videoconferência, com o Squad. Conseguimos reavaliar situações de menores que estavam em situação de acolhimento e fazer as devidas determinações necessárias para os casos.
                                            <br><strong>Isabelle Guimarães <br> <span> Juíza Títular da 2ª Vara Comarca de Ingá-PB</span></strong>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </agile>
                    </div>
                </div>
            </div>
        </section>
        <!--  -->

        <section class="cta-two" id="screens">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-1" />
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-2" />
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-3" />
            <img src="https://citrus.cx/wp-content/uploads/2020/04/cta-moc-1-2.png" class="cta-two__moc" />
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                       <div class="cta-three__content my-auto">
                            <div class="block-title">
                                <div class="block-title__text">Esteja presente mesmo à distância</div><!-- /.block-title__text -->
                            </div><!-- /.block-title -->
                            <p class="cta-three__text">Videoconferência para + de 40 pessoas simultaneamente</p><!-- /.cta-three__text -->
                            <ul class="cta-three__list">
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Fácil de usar: use pelo app ou navegador </li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Produtividade: reuniões focadas em resultado</li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Mais agilidade: trabalhe no momento que precisar</li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Crie conteúdo: Webinars e lives para usuários</li>
                            </ul><!-- /.cta-three__list -->
                        </div><!-- /.cta-three__content -->
                    </div><!-- /.col-lg-6 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.cta-two -->
    
        <section class="blog-one" id="solucoes">
            <div class="container">               
                <div class="row">
                    <div class="col-lg-4 col-sm-12">
                        <div class="block-title__text"><span>Soluções para contribuir com organizações e empresas</span></div>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-2 points">
                        <figure>
                            <img src="https://citrus.cx/wp-content/uploads/2020/03/city-hall.png">
                        </figure>
                        <h2>Governo</h2>
                        <p>Conecte seus servidores de todas as secretarias e órgãos.</p>
                    </div>
                    <div class="col-md-2 points">
                        <figure>
                            <img src="https://citrus.cx/wp-content/uploads/2020/03/health.png">
                        </figure>
                        <h2>Saúde</h2>
                        <p>Solução rápida para integração de colaboradores em momentos necessários</p>
                    </div>
                    <div class="col-md-2 points">
                        <figure>
                            <img src="https://citrus.cx/wp-content/uploads/2020/03/suitcase.png">
                        </figure>
                        <h2>ONGs</h2>
                        <p>Mantenha a autonomia, sem prejudicar as ações das equipes envolvidas.</p>
                    </div>
                </div>                      
            </div><!-- /.container -->
        </section><!-- /.blog-one -->
        <section class="downdobra">
            <div class="container">
                <div class="block-title__text2"><span>Trabalhe junto a qualquer <br>momento, de qualquer lugar</span></div>
                <p class="cta-six">A liberação de uso por 90 dias grátis passa por um processo de cadastro e nossos times estão trabalhando 24 horas para atendê-lo.</p>
                <a href="https://materiais.citrus.cx/juntos-squad" target="_blank" class="header-one__btn btnLight">Junte-se a nós</a>
            </div>
        </section>		
	</div>
</template>
<script>
import LDPlayer from '@/components/molecules/LDPlayer'

export default {
	components: {
        'ld-player': LDPlayer
    },
	metaInfo: {
      title: 'Squad by Citrus',
      keywords: 'colaboração, trabalho remoto, simultâneo, à distância, trabalho em equipe'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},		
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
	}),
	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">
    .videohome{
        position: relative;   

        .row{

            align-items: center;
        }
        .block-title {
        margin-bottom: 23px!important;
        }
        .ld-player{
            margin-bottom: 30px;
        }
    }
    .cta-six{
        color: #fff;
        font-size: 18px;
        max-width: 441px;
        margin: 0 auto 30px;
    }
    @media(min-width: 990px){
        .videohome .banner-one__content{
            padding-left: 21px;
        }
        #home .block-title__text{
            font-size: 46px;
        }
    }

    .testemony{
        margin-bottom: 25vh;
        .agile__slides{
            height: auto;
            padding: 30px 0px;
            
        }
        .slide{
            width: 100%;
            border-radius: 10px;
            background: #fff;
        }
        .agile__dot.agile__dot--current button{
            width: 10px;
            height: 10px;
            padding: 0;
            background: #c7dd2e;
            border-radius: 100px;
            border:none;
        }
        .agile__dot button{
            width: 9px;
            margin: 6px;
            height: 9px;
            padding: 0;
            background: #d7dbe1;
            border-radius: 100px;
            border: none;
            margin-top: 25px;
        }
    }
    .agile__list{
    border-radius: 6px;
    }

    .sl{
        width: 95%;margin: 0 auto;border-right: 4px;max-width: 980px;
    padding: 72px 100px;
        box-shadow: 0px 0px 10px #dedede;
    }
    .agile__dots{
        margin: 0 auto;
    }
.slide h1{
        position: relative;
    margin: 0 auto;
    font-size: 17px;
    color: #6f7688;
    line-height: 140%;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 168%;
    padding-left: 70px;

    &:before{
        content: '';
        width: 52px;
        float: left;
        height: 100%;
        background-size: contain !important;
        background: url(https://citrus.cx/wp-content/uploads/2020/05/quotes2.png) no-repeat center top;
        
        position: absolute;
        left: 0;
    }
    strong{
        float: right;
        text-align: right;
        color: #464b58;
        margin-top: 15px;

        span{
            font-weight: 400;
            color: #6f7688;
            font-size: 15px;
            position: relative;

            &:after{
                content: '';
                width: 150px;
                height: 3px;
                border-radius: 16px;
                background: #b5c634;
                position: absolute;
                right: 0;
                bottom: -9px;
            }
        }
    }
}

@media(max-width: 580px){
    .slide{
        width: 90%;
        margin: 0 auto;
        padding: 16px;
    }
    .sl{
        padding:30px;
    }
    .testemony .agile__slides{
        padding: 0;
    }
    .slide h1 {
        position: relative;
        margin: 0 auto;
        font-size: 16px;
        color: #6f7688;
        line-height: 140%;
        font-family: 'Poppins';
        font-weight: 400;
        line-height: 168%;
        padding-left: 36px;
    }
    .slide h1:before {
        content: '';
        width: 28px;
    }
    .cta-three {
        padding-bottom: 30px!important;
    }
    .testemony{
        padding-bottom: 4vh;
        margin-bottom: 4vh;
    }
}
    .agile{position:relative}.agile--rtl .agile__actions,.agile--rtl .agile__dots,.agile--rtl .agile__slides,.agile--rtl .agile__track{-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse}.agile:active,.agile :active,.agile:focus,.agile :focus{outline:none}.agile__list{display:block;overflow:hidden;position:relative;width:100%}.agile__track{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:nowrap;flex-wrap:nowrap}.agile__actions,.agile__track{display:-webkit-box;display:-ms-flexbox;display:flex}.agile__actions{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.agile__slides{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;-ms-flex-negative:0;flex-shrink:0;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}.agile--disabled .agile__slides{display:block}.agile__slide{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;-ms-flex-negative:0;flex-shrink:0}.agile--fade .agile__slide{opacity:0;position:relative;z-index:0}.agile--fade .agile__slide--active{opacity:1;z-index:2}.agile--fade .agile__slide--expiring{opacity:1;-webkit-transition-duration:0s;transition-duration:0s;z-index:1}.agile__nav-button[disabled]{cursor:default}.agile__dots{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;list-style:none;padding:0;white-space:nowrap}.agile__dot button{cursor:pointer;display:block;font-size:0;line-height:0}
</style>
