<template>
	<div class="sq-recursos">
		<section id="home" class="banner-one mb-5">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-3">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6">

            <div class="container mt-5">

                <div class="banner-cs mt-5">
                    <div class="d-flex justify-content-center justify-content-xl-start">
                        <img src="@/assets/image/logo-live.png" alt="squad" />
                    </div>
                    <div class="banner-cs-title mt-3 mb-3 text-center text-xl-left">
                        Stream de<br> 
                        alta qualidade,<br>
                        simples e seguro
                    </div>
                    <p class="banner-cs-body text-center text-xl-left">
                        Realize transmissões ao vivo com qualidade HD e toda a facilidade <br>
                        de uma aplicação web. Conte com recursos para elevar a interação <br>
                        durante sua transmissão com enquetes e chats em tempo real, e <br>
                        ainda, tenha seu conteúdo gravado e criptografado em nuvem. 
                    </p>
                    <div class="d-flex justify-content-center">
                        <img class="img-01" src="@/assets/image/01.png" alt="img" />
                    </div>
                </div>

            </div>

        </section>

        <section class="container banner-one">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-3">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5">
            
            <div class="text-center">
                <div class="title">Recursos para <strong>elevar o nível</strong> da sua live</div>
                <div class="d-flex justify-content-center">
                    <div class="line-green"></div>
                </div>
            </div>

            <div class="row">

                <div class="col-12 col-xl-5">
                    <div class="box">
                        <div class="box-img">
                            <img src="@/assets/image/hd-film.png" alt="img" />
                        </div>
                        <div class="box-text">
                            <div class="box-title">
                                Transmissão em
                                alta qualidade
                            </div>
                            <div>
                                Realize transmissões com as melhores configurações de áudio e vídeo em HD.
                            </div>
                        </div>
                    </div>
        
                    <div class="box">
                        <div class="box-img">
                            <img src="@/assets/image/conversation.png" alt="img" />
                        </div>
                        <div class="box-text">
                            <div class="box-title">
                                Interação em tempo real
                            </div>
                            <div>
                                <p>Compartilhe arquivos e vídeos locais ou da web</p>
                                <p>Troque mensagens pelo chat em tempo real com participantes</p>
                                <p>Personalize o layout das telas de transmissão</p>
                                <p>Realize enquetes com resultado em tempo real</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="box">
                        <div class="box-img">
                            <img src="@/assets/image/security.png" alt="img" />
                        </div>
                        <div class="box-text">
                            <div class="box-title">
                                Segurança
                            </div>
                            <div>
                                Garanta a segurança das informações com alto nível de criptografia 
                            </div>
                        </div>
                    </div>
                    
                    <div class="box">
                        <div class="box-img">
                            <img src="@/assets/image/responsive.png" alt="img" />
                        </div>
                        <div class="box-text">
                            <div class="box-title">
                                Mobile e Desktop
                            </div>
                            <div>
                                Acesso mobile dedicado para organizadores e participantes
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 offset-xl-2 col-xl-5">
                    <div class="box">
                        <div class="box-img">
                            <img src="@/assets/image/group-chat.png" alt="img" />
                        </div>
                        <div class="box-text">
                            <div class="box-title">
                                Acesso ilimitado de participantes
                            </div>
                            <div>
                                <p>Tenha uma audiência com o número de participantes ilimitados;</p>
                                <p>Convide participantes externos por meio de links;</p>
                                <p>Registre participações oficiais com auditoria.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="box">
                        <div class="box-img">
                            <img src="@/assets/image/rec (1).png" alt="img" />
                        </div>
                        <div class="box-text">
                            <div class="box-title">
                                Gravação de conteúdo
                            </div>
                            <div>
                                Conte com alta disponibilidade e gravações seguras das transmissões em nuvem
                            </div>
                        </div>
                    </div>
                    
                    <div class="box">
                        <div class="box-img">
                            <img src="@/assets/image/coding.png" alt="img" />
                        </div>
                        <div class="box-text">
                            <div class="box-title">
                                Integração
                            </div>
                            <div>
                                <p>Inclua em suas transmissões a integração com serviços de certificação eletrônica por token e blockchain</p>
                                <p>API de integração com base de usuários através de LDAP (recurso que permite a validação de usuários aptos em processos de votação) e webservice. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="separation-page-cs mt-5 mb-5">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
            <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
            <div class="container">
                <div class="separation-page-cs-title text-center">
                    Eleve o nível das suas transmissões<br />
                    agora mesmo com o Squad Live
                </div>
                <a href="https://materiais.citrus.cx/falar-com-vendas" target="_blank" class="header-one__btn btnLight bg-black">QUERO FALAR COM VENDAS</a>
            </div>
        </section>
		
	</div>
</template>

<script>

export default {
	
	metaInfo: {
      title: 'Recursos'
    },
	
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
	}),
	

	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss" >

    @media screen and (max-width: 1199px) {
        .img-01 {
            width: 80vw;
        }

        .box {
            flex-direction: column;
            align-items: center;

            .box-img {
                padding-right: 0 !important;
                margin-bottom: 15px;
                width: auto !important;
            }

            .box-text {
                text-align: center !important;

                .box-title {
                    text-align: center !important;
                    width: 100%;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) {
        .img-01 {
            position: absolute;
            top: calc(40vh - 13vw);
            right: 0;
            width: 50vw;
        }
    }

    .title {
        font-size: 40px;
        margin-bottom: 25px;
        color: #49494c;
    }

    .line-green {
        width: 120px;
        height: 5px;
        background-color: #c8df00;
        margin-bottom: 75px;
        border-radius: 5px;
    }

    .box {
        display: flex;
        margin-bottom: 50px;
    }

    .box .box-img {
        width: 120px;
        display: flex;
        align-items: flex-start;
        padding-right: 35px;
    }

    .box .box-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #49494c;
    }

    .box .box-text .box-title {
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 10px;
        color: #000000;
    }

    .separation-page-cs {
        background-image: url('./images/Rectangle931.png') !important;
        background-color: rgba(0,0,0,0);
        padding: 80px 0;

        .separation-page-cs-title {
            color: #ffffff !important;
        }
    }
    
</style>
