<template>
	<sq-home
		id="home"
	/>
</template>

<script>
import SQHome from '@/components/templates/SQHome'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-home': SQHome
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
