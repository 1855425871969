<template>
	<sq-cadastro
		id="cadastro"
	/>
</template>

<script>
import SQCadastro from '@/components/templates/SQCadastro'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-cadastro': SQCadastro
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
