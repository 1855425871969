<template>
	<sq-prov
		id="prov"
	/>
</template>

<script>
import SQProv from '@/components/templates/SQProv'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-prov': SQProv
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
