<template>
	<div class="sq-termos-usuario">
      <section class="banner-one" id="home">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="banner-one__content">
                            
                            <div class="block-title">
                                <div class="block-title__text termostitle"><span>Termos do Usuário Autorizado</span></div><!-- /.block-title__text -->
                            </div><!-- /.block-title -->
                            <p class="termos-one__text mb-3">Estes Termos do Usuário Autorizado regem seu acesso e uso da plataforma e das ferramentas on-line de produtividade do Squad como Usuário Autorizado por um Licenciado e são legalmente vinculativos entre você e o Squad. 
                            No âmbito dos Termos do Usuário Autorizado, você concorda em cumprir com a versão mais recente da nossa Política de Uso Aceitável, incorporada por referência nestes Termos do Usuário. Ao acessar ou usar o Squad, ou continuar acessando ou usando o Squad, após ser notificado de uma alteração nos Termos do Usuário ou na Política de Uso aceitável, você confirma que leu, compreendeu e concordou em cumprir os Termos do Usuário e a Política de Uso aceitável. 
                            "Nós" e "nosso(a)" referem-se à entidade aplicável do Squad no Contrato </p>


                            <h2 class="termos-one__text mb-2"><strong>1 Instruções, orientações iniciais e poderes do Licenciado</strong></h2> 

                            <p class="termos-one__text mb-3">1.1 O Squad é uma ferramenta de colaboração que facilita a comunicação entre equipes internas e externas através de voz, vídeo, mensagens e conferência em uma mesma plataforma, sendo simples intermediador de dados (Operador de Dados).</p>

                            <p class="termos-one__text mb-3">1.2 Você é um “Usuário Autorizado” e controlado por um "Cliente Licenciado”.</p>

                            <p class="termos-one__text mb-3">1.3 Uma organização ou outro terceiro ("Cliente Licenciado") convidou você para um ambiente de colaboração on-line, ou seja, um domínio exclusivo pelo qual um grupo de usuários pode acessar o Squad. O “Licenciado” pode ser seu empregador ou uma outra pessoa ou corporação utilizando o endereço de email dela.</p>

                            <p class="termos-one__text mb-3">1.4 A plataforma Squad e suas atualizações, upgrades, versões modificadas e suplementos são protegidos por direitos autorais e de legítima propriedade da Citrus e está sendo licenciado, não vendido, de forma que se aplica a este contrato o disposto nas Leis 9.609/98 e 9.610/98.</p>

                            <p class="termos-one__text mb-3">1.5 O Usuário Autorizado se compromete a respeitar as marcas da Citrus, não devendo utilizá-las sem prévia autorização, por escrito, nem praticar atos que possam prejudicar tais marcas. </p>

                            <p class="termos-one__text mb-3">1.6 Fica expressamente vedado ao Usuário Autorizado , em relação ao software: modificar (ainda que para fins de correção de erro), ceder, doar, alugar, vender, arrendar, emprestar, reproduzir, modificar, adaptar, traduzir, incorporar a outros programas ou sistemas, próprios ou de terceiros; oferecer em garantia ou penhor; alienar ou transferir, total ou parcialmente, a qualquer título, de forma gratuita ou onerosa; descompilar, mudar a engenharia (reengenharia), enfim, dar qualquer outra destinação ao software/plataforma, ou parte dele(a), que não seja a simples utilização. </p>


                             <h2 class="termos-one__text mb-2"><strong>2 O que isso significa para Você e para Nós</strong></h2> 

                            <p class="termos-one__text mb-3">2.1 O “Licenciado” concordou com nossos Termos de Uso ou outro contrato escrito, o que permitiu a este criar e configurar um ambiente online para que “Usuários Autorizados” como você pudesse obter acesso aos serviços. </p>

                            <p class="termos-one__text mb-3">2.2 Os dados (mensagens, arquivos, imagens) dos “Usuários Autorizados” a ingressar em um ambiente de colaboração online passam a ser de propriedade do “Cliente Licenciado”, que dispõe de diversas opções e controle sobre esses dados.</p>

                            <p class="termos-one__text mb-3">2.3 A Citrus não possui qualquer tipo de ingerência sobre os dados dos Usuários Autorizados, sendo simples ferramenta de colaboração.</p>

                            <p class="termos-one__text mb-3">2.4 O “Licenciado” pode conceder acesso ou cancelar a concessão de acesso aos serviços, ativar ou desativar integrações de terceiros, gerenciar permissões, configurações de retenção e exportação, transferir ou atribuir ambientes de colaboração online, compartilhar canais ou consolidar o próprio ambiente online ou canais com outros ambientes online ou canais. Estas escolhas e instruções podem resultar no acesso, no uso, na divulgação, na modificação ou na exclusão de alguns ou todos os dados do “Usuário Autorizado”.</p>

                            <p class="termos-one__text mb-3">2.5 Com o Squad os usuários poderão realizar lives, webinars, conferências, etc, ou seja, comunicação por vídeo. Estas poderão ser armazenadas de acordo com o critério do “Licenciado”.</p>

                            <p class="termos-one__text mb-3">2.6 Em razão da funcionalidade indicada no item 2.5 o “Usuário Autorizado” fornece a devida autorização para uso de sua imagem, sem qualquer ônus, em caráter definitivo e por tempo indeterminado.</p>

                            <p class="termos-one__text mb-3">2.6.1 A presente autorização abrange o uso e armazenamento da imagem, a título gratuito, abrangendo inclusive a licença a terceiros, de forma direta ou indireta e a inserção eventual em materiais para toda e qualquer finalidade (comercial, publicidade, jornalística, didática, etc.), em território nacional ou internacional. Assim, o Usuário Autorizado declara não haver nada a ser reclamado a título de direitos conexos à sua imagem ou a qualquer outro.</p>

                            <h2 class="termos-one__text mb-2"><strong>3 A relação entre Você, o Licenciado e Nós</strong></h2>

                            <p class="termos-one__text mb-3">3.1 Você (“Usuário Autorizado”) concorda que é de exclusiva responsabilidade do “Licenciado”, não excluindo-se outras:</p>

                            <p class="termos-one__text mb-3">3.1.1. Informar a você e qualquer outro usuário autorizado de todas as políticas e práticas relevantes e de qualquer cenário que possa impactar o processamento dos dados dos “Usuários Autorizados”.</p>

                            <p class="termos-one__text mb-3">3.1.2 Obter qualquer direito, permissão ou consentimento seu de qualquer outro Usuário Autorizado que seja necessário para o uso legal de dados, imagem, e para operação do serviço, inclusive aquela prevista no art. 7º da Lei nº 13.709/2018.</p>

                            <p class="termos-one__text mb-3">3.1.3 Garantir que a transferência e o processamento de dados dos Usuários Autorizados sejam feitos de forma legal, fornecendo, inclusive, mecanismos de modificação, exclusão, correção de dados, e semelhantes, disponibilizando ao Usuário Autorizado o canal através do qual isso deve ser feito. </p>

                            <p class="termos-one__text mb-3">3.1.4 Responder e solucionar todas as demandas e disputas com você e qualquer usuário autorizado relativas ou baseadas em dados, serviços ou falha em cumprir com obrigações.</p>

                            <p class="termos-one__text mb-3">3.2 O Squad não fornece representações nem garantias de nenhum tipo, seja expressa ou implícita, a você em relação aos serviços, que são fornecidos a você "conforme disponível".</p> 

                            <p class="termos-one__text mb-3">3.3 O “Usuário Autorizado” declara, reconhece e aceita que o estado atual da técnica não permite a elaboração de programas e plataformas de computador totalmente isentos de vícios ou defeitos e que, assim sendo, nós não podemos garantir que a ferramenta operará ininterruptamente ou livre de vícios ou defeitos.</p>

                            <p class="termos-one__text mb-3">3.4  A Citrus informa que não há garantia se qualquer defeito no funcionamento da plataforma decorrer de: mau uso e/ou uso em desconformidade com as suas especificações e/ou finalidades; alteração, modificação ou conversão da plataforma pelo Usuário Autorizado ou por qualquer terceiro; mau funcionamento de hardware, equipamentos ou serviços contratados, incluindo serviços de acesso/conexão à internet; serviços de suporte técnico ou reparo realizados por terceiro não autorizado; uso, pelo “Usuário Autorizado” ou pelo “Licenciado”, de software de terceiro em conjunto com o Software; e/ou qualquer outro evento ocorrido após a entrega inicial da plataforma.</p>

                            <p class="termos-one__text mb-3">3.6 A plataforma também não garante que as funções do software satisfarão os requisitos do Usuário Final ou que os produtos de software trabalharão juntos na seleção que foi escolhida, que eles serão executados sem interrupção e sem falhas, ou, ainda, que todas as falhas da ferramenta podem ser removidas.</p>

                            <p class="termos-one__text mb-3">3.7 A Citrus adota as medidas de segurança adequadas de acordo com os padrões de mercado para proteção das informações, todavia, nenhuma transmissão de dados por meio da Internet é absolutamente segura.</p> 

                            <p class="termos-one__text mb-3">3.8 A Citrus não poderá́ assegurar que as informações transmitidas para a plataforma durante a navegação neste site estarão imunes a ataques e/ou invasões de hackers e outros agentes maliciosos, não sendo a Citrus responsável por qualquer exclusão, obtenção, utilização ou divulgação não autorizada de INFORMAÇÕES resultantes de ataques que não poderia razoavelmente evitar por meio dos referidos padrões de segurança. </p>

                            <p class="termos-one__text mb-3">3.9 Compete ao Usuário Autorizado proceder com as medidas de segurança no uso da Internet e na manutenção de sua infraestrutura computacional, mantendo sistema de antivírus atualizado, utilizando softwares licenciados, evitando downloads de sites desconhecidos e não acessando links oferecidos por e-mails suspeitos.  </p>

                            <h2 class="termos-one__text mb-2"><strong>4 Idade Mínima do destinatário dos serviços</strong></h2>

                            <p class="termos-one__text mb-3">4.1 O “Usuário Autorizado”:</p>

                            <p class="termos-one__text mb-3">4.1.1 Declara que tem 18 anos completos ou idade superior no momento da utilização da plataforma. </p>

                            <p class="termos-one__text mb-3">4.1.2 Declara ser o destinatário pretendido do convite do Licenciado para os Serviços. </p>

                            <p class="termos-one__text mb-3">4.1.3 Fica proibido de acessar e usar os Serviços para qualquer finalidade se alguma das declarações dos itens anteriores não for verdadeira. </p>

                            <h2 class="termos-one__text mb-2"><strong>5 Cumprimento da Política de Uso Aceitável</strong></h2>

                            <p class="termos-one__text mb-3">5.1 Para ajudar a garantir um ambiente de trabalho seguro e produtivo, todos os Usuários autorizados precisam cumprir com nossa Política de Uso Aceitável e com as demais políticas aplicáveis estabelecidas pelo Licenciado. </p>

                            <p class="termos-one__text mb-3">5.2 Caso você identifique algum comportamento ou conteúdo inadequado, denuncie-o para o Licenciado que lhe convidou ou empregador.</p>

                            <h2 class="termos-one__text mb-2"><strong>6  O Usuário Autorizado está aqui a critério do Licenciado</strong></h2>
                            

                            <p class="termos-one__text mb-3">6.1 Os presentes Termos de Usuário Autorizado permanecerão em vigor pelo prazo em que viger a licença concedida pela Citrus ao Cliente Licenciado e, cumulativamente, pelo prazo de acesso concedido pelo Cliente Licenciado para você. </p>

                            <p class="termos-one__text mb-3">6.2 Caso o “Usuário Autorizado” deseje, por qualquer razão, encerrar sua conta e acesso à plataforma, deverá entrar em contato com o Cliente Licenciado.</p>


                            <h2 class="termos-one__text mb-2"><strong>7 Limitação de responsabilidade e Violação Contratual</strong></h2>

                            <p class="termos-one__text mb-3">7.1 Se a Citrus acreditar que há uma violação do Contrato, dos Termos do Usuário, da Política de Uso aceitável ou de qualquer uma das nossas outras Políticas que possa simplesmente ser remediada pela remoção por parte do Cliente Licenciado de certos dados ou outra ação, iremos, na maior parte dos casos, notificar o Cliente Licenciado para que tome a ação em vez de intervirmos.</p>

                            <p class="termos-one__text mb-3">7.2 A Citrus se reserva ao direito de intervir diretamente e tomar medidas que julgar adequadas, inclusive através da desativação da conta, se o Cliente Licenciado não realizar a ação apropriada ou se houver risco para os Serviços, Usuários Autorizados ou qualquer terceiro.</p>

                            <p class="termos-one__text mb-3">7.3 O “Usuário Autorizado” e o “Cliente Licenciado” reconhecem sua integral responsabilidade pela escolha da plataforma para atingir os resultados por ela pretendidos, bem como pelo uso adequado e resultados obtidos através dele.</p>

                            <p class="termos-one__text mb-3">7.4 A Citrus isenta-se expressamente de quaisquer responsabilidades e indenizações, perdas e danos, lucros cessantes, como por exemplo, mas não limitado a: prejuízos de quaisquer espécies, perdas de negócios, perda ou extravio de dados, falhas de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao uso ou inabilidade em usar o software, operação por pessoas não autorizadas, danos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com base em informações fornecidas pelo software, danos causados a terceiros em razão de qualquer conteúdo gerado pelo através da plataforma, defeitos de computador, equipamentos ou periféricos, ou pelo custo incorrido na recuperação de referidos dados ou programas ou quaisquer outros danos diretos, indiretos ou incidentais decorrentes da utilização do sistema.</p>

                            <p class="termos-one__text mb-3">7.5 O Squad é uma ferramenta de ambiente de trabalho destinada ao uso por negócios e organizações, e não para objetivos de consumidores. Assim, você reconhece e concorda pelo presente que a Lei nº 8.078/1990 não é aplicável à presente relação jurídica. </p>
                            
                             <h2 class="termos-one__text mb-2"><strong>8 Disposições Gerais</strong></h2>

                            <p class="termos-one__text mb-3">8.1 Os termos e condições poderão ser atualizados e modificados a qualquer tempo, independente de notificação prévia, cabendo ao Usuário Autorizado e ao Cliente Licenciado manterem-se atualizado através da plataforma Citrus.</p>

                            <p class="termos-one__text mb-3">8.2 O usuário autorizado declara que leu e concorda com nossa Política de Privacidade especialmente quanto as informações sobre como coletamos e usamos dados relacionados ao uso e desempenho de nossos produtos.</p>

                            <p class="termos-one__text mb-3">8.3 O Usuário Autorizado aceita que a Citrus poderá́, ao seu critério, alterar a prestação de serviços da plataforma e até mesmo interromper seu funcionamento temporariamente ou definitivamente. </p>

                            <p class="termos-one__text mb-3">8.4 Nenhuma falha ou atraso de qualquer uma das partes em exercer qualquer direito sob os Termos do Usuário Autorizado, incluindo a Política de Uso aceitável, constituirá uma renúncia a esse direito. Nenhuma renúncia sob os Termos do Usuário é efetiva a menos que executada por escrito e assinada por um representante autorizado da parte considerada a concessora da renúncia.</p>

                            <p class="termos-one__text mb-3">8.5 Se qualquer disposição dos Termos do Usuário for considerada, pelo Judiciário ou Tribunal Arbitral, como contrária à lei, as outras disposições dos Termos do Usuário permanecerão em vigor.</p>

                            <p class="termos-one__text mb-3">8.6 Não é possível atribuir seus direitos nem delegar suas obrigações no âmbito destes Termos do Usuário Autorizado, incluindo a Política de Uso aceitável, seja por aplicação da lei ou de outra forma, sem nosso consentimento prévio por escrito. Podemos atribuir estes Termos do Usuário em sua totalidade (incluindo todos os termos e condições aqui incorporados para fins de referência), sem seu consentimento, a uma coligada ou em relação com fusões, aquisições, reorganizações corporativas ou venda de todos ou grande parte dos nossos ativos.</p>

                            <p class="termos-one__text mb-3">8.7 Estes Termos do Usuário Autorizado são regidos pelas leis da República Federativa do Brasil. As partes elegem o foro da Comarca de João Pessoa, Paraíba, Brasil, com exclusão de qualquer outro, por mais privilegiado que seja, como único competente para decidir as questões que eventualmente decorram deste instrumento.</p>

                            <p class="termos-one__text mb-3">8.8 Caso tenha alguma dúvida sobre os Termos de Serviço do Usuário, você pode fazê-lo por meio do e-mail duvidas@citrussquad.com.br </p>
                            
                        </div><!-- /.banner-one__content -->
                    </div><!-- /.col-lg-5 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.banner-one -->
	</div>
</template>

<script>

export default {
	
	metaInfo: {
    title: 'Termos do Usuário Autorizado'
  },

	mounted() {
		this.active = this.selected

	}
}
</script>

<style lang="scss">


.sq-algar{


}
</style>
