<template>
	<sq-faq
		id="faq"
	/>
</template>

<script>
import SQFaq from '@/components/templates/SQFaq'
import { mapGetters } from 'vuex'

export default {
	components: {
		'sq-faq': SQFaq
	},

	props: {

		
	},
	
	computed: {
		...mapGetters({
			
		})
	},

	methods: {
	}
}
</script>
