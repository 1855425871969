<template>
	<div class="sq-faq">
	     <section class="page-faq-01">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-1.png" class="bubble-1">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-2.png" class="bubble-3">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5">
        <div class="container">
            <div class="banner-cs">
                <div class="banner-cs-title-md text-center mb-4">
                    Perguntas Frequentes
                </div>
            </div>
        </div>
    </section>
    <section class="page-faq-02">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-3.png" class="bubble-2">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-4.png" class="bubble-4">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-5.png" class="bubble-5">
        <img src="https://citrus.cx/wp-content/uploads/2020/03/banner-icon-1-6.png" class="bubble-6">
        <div class="container">
            <div class="row ml-lg-5 mr-lg-5">
                <div class="col-12 col-lg-2 p-0">
                   
                    <div class="nav flex-column nav-pills menu-faq text-center text-lg-left mb-3 mb-lg-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active" id="v-pills-geral-tab" data-toggle="pill" href="#v-pills-geral" role="tab" aria-controls="v-pills-geral" aria-selected="true">Geral</a>
                        <a class="nav-link" id="v-pills-seguranca-tab" data-toggle="pill" href="#v-pills-seguranca" role="tab" aria-controls="v-pills-seguranca" aria-selected="false">Conta</a>
                        <a class="nav-link" id="v-pills-servidor-tab" data-toggle="pill" href="#v-pills-servidor" role="tab" aria-controls="v-pills-servidor" aria-selected="false">Interações</a>
                        <a class="nav-link" id="v-pills-privacidade-tab" data-toggle="pill" href="#v-pills-privacidade" role="tab" aria-controls="v-pills-privacidade" aria-selected="false">Grupos</a>
                        <a class="nav-link" id="v-pills-comofunciona-tab" data-toggle="pill" href="#v-pills-comofunciona" role="tab" aria-controls="v-pills-comofunciona" aria-selected="false">Configurações</a>
                    </div>

                </div>
                <div class="col-12 col-lg-10">
                    <div class="card-cs-faq">
                        
                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="v-pills-geral" role="tabpanel" aria-labelledby="v-pills-geral-tab">
                             
                                <div class="card-cs-faq-title">
                                    Geral
                                </div>
                                <div class="card-cs-faq-body">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            o que é Squad ?
                                        </div>
                                        <div class="banner-cs-body">
                                            O Squad é uma ferramenta de colaboração da Citrus.cx, que busca facilitar os processos internos de comunicação de uma companhia através da disponibilização de recursos para videoconferência e troca de mensagens e arquivos via chat. Os recursos podem ser utilizados tanto para conversas individuais como em grupo. A plataforma busca otimizar o trabalho em equipe, de forma simples e intuitiva.
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Quais são os recursos disponíveis no Squad?
                                        </div>
                                        <div class="banner-cs-body">
                                            Para otimizar a comunicação com o seu time, o Squad conta com:

                                            <ul>
                                                <li>
                                                    Chat individual
                                                    <ul>
                                                        <li>Troca de mensagens diretas entre 2 usuários.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Chat em grupo

                                                    <ul>
                                                        <li>Troca de mensagens diretas entre 3 ou mais usuários.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Grupos de trabalho
                                                    <ul>
                                                        <li>Local colaborativo para troca de mensagens entre usuários selecionados.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Videoconferência individual
                                                    <ul>
                                                        <li>Chamada por voz e/ou vídeo entre 2 usuários.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Videoconferência em grupo
                                                    <ul>
                                                        <li>Chamada por voz e/ou vídeo entre 3 ou mais usuários.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Envio e armazenamento de arquivos

                                                    <ul>
                                                        <li>Envio e armazenamento de arquivos relevantes dentro de chats individuais, em grupo ou grupo de trabalho.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Live Vídeos

                                                    <ul>
                                                        <li>Transmissão ao vivo de vídeos para colaboradores a partir de link único.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>
                            <div class="tab-pane fade" id="v-pills-seguranca" role="tabpanel" aria-labelledby="v-pills-seguranca-tab">
                                <div class="card-cs-faq-title">
                                    Conta
                                </div>
                                <div class="card-cs-faq-body">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Como acessar minha conta?
                                        </div>
                                        <div class="banner-cs-body">
                                           Para logar em sua conta é simples. Entre no link enviado para o seu email e coloque seu Login e Senha. É importante lembrar de permitir que o Squad tenha acesso à sua câmera e microfone para conseguir se comunicar com seu time. Por fim, salve suas configurações.

                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Perdi minha senha. Como recuperar?
                                        </div>
                                        <div class="banner-cs-body">
                                            Para recuperar sua senha é super rápido! Na tela de Login do Squad você confere a opção <strong>Esqueceu a Senha?</strong> abaixo do campo de preenchimento da sua Senha. Você será redirecionado para uma página solicitando seu email e depois basta seguir os passos apresentados.                                            
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Posso alterar minha senha atual?
                                        </div>
                                        <div class="banner-cs-body">
                                            Ainda não. Como estamos em versão beta, essa funcionalidade está sendo trabalhada como prioridade por nosso time.                                         
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="v-pills-servidor" role="tabpanel" aria-labelledby="v-pills-servidor-tab">
                                <div class="card-cs-faq-title">
                                    Interações
                                </div>
                                <div class="card-cs-faq-body">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Posso realizar ligações de voz com outras pessoas no Squad?
                                        </div>
                                        <div class="banner-cs-body">
                                           Sim. Existem duas formas de realizar ligações por voz.
                                           <strong>Para outros usuários do Squad:</strong> Abra o chat do contato desejado. No topo à direita do chat você irá visualizar o ícone laranja de um telefone. Basta clicar nele e você estará efetuando a chamada.

                                           <strong>Para pessoas que não possuam Squad:</strong> Na barra lateral do seu Squad você vai localizar o seguinte ícone ao lado do campo <i>"Buscar"</i>: <img style="width: 19px;margin-top: -2px;" src="https://citrus.cx/wp-content/uploads/2020/03/dialer.png">. Ao acessar, será aberto o teclado numérico para você discar o número desejado e efetuar sua ligação.

                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Como realizo chamadas de vídeo com outros usuários do Squad?
                                        </div>
                                        <div class="banner-cs-body">
                                            Para realizar uma chamada em vídeo, abra o chat do contato ou grupo que deseja fazer a chamada. No topo à direita do chat você irá visualizar o ícone verde de uma câmera. Basta clicar nele e você estará efetuando a chamada em vídeo.                                            
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            É possível conversar via chat com alguém enquanto estou numa chamada de voz ou vídeo?
                                        </div>
                                        <div class="banner-cs-body">
                                            Sim. Em todos os tipos de ligação é possível acessar o chat, a qualquer momento, para enviar mensagens de texto, arquivos e ter uma melhor experiência de colaboração. No caso das chamadas em áudio, o chat continuará aberto para você. Já para as chamadas em vídeo, basta acessar o ícone verde do chat no topo à direita da sua tela, e ele será aberto para você.                                         
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Como faço para compartilhar arquivos com outros usuários?
                                        </div>
                                        <div class="banner-cs-body">
                                            É super simples compartilhar qualquer coisa no Squad. Com o chat da pessoa ou grupo aberto, basta ir na parte inferior da sua tela e acessar o ícone do Clip. Em seguida, escolha o arquivo desejado e pronto! O mesmo estará compartilhado com seu contato.                                         
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Onde visualizo os arquivos compartilhados?
                                        </div>
                                        <div class="banner-cs-body">
                                            Em todos os chats você pode visualizar, no topo (junto ao nome do grupo ou usuário), a opção <strong>Arquivos</strong>. Ao clicar nela, será aberto uma barra na lateral esquerda com todos os arquivos compartilhados por você ou pelo contato no chat.                                        
                                        </div>
                                    </div>
                                     <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Caso eu esteja em uma videoconferência e a minha internet cair, ao retornar para o Squad eu ingresso na call automaticamente?
                                        </div>
                                        <div class="banner-cs-body">
                                            Caso você enfrente problemas com a internet durante uma call, você deverá acessar a conversa ou grupo em que a mesma estava acontecendo, e ingressar novamente na mesma clicando no ícone do telefone (voz) ou câmera (vídeo).                                        
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="tab-pane fade" id="v-pills-privacidade" role="tabpanel" aria-labelledby="v-pills-privacidade-tab">

                               <div class="card-cs-faq-title">
                                    Grupos
                                </div>
                                <div class="card-cs-faq-body">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Como faço para criar um novo grupo?
                                        </div>
                                        <div class="banner-cs-body">
                                          Para criar um novo grupo de trabalho, vá na barra lateral do seu Squad e acesse a aba <strong>Chat</strong>. Lá você encontrará a opção <strong>+Novo</strong> e clicando nela você poderá escolher o nome do grupo e os integrantes a serem adicionados nele.

                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Posso adicionar ou excluir um integrante do grupo?
                                        </div>
                                        <div class="banner-cs-body">
                                            Como administrador do grupo, você pode tanto adicionar novos integrantes como excluí-los. Para isso, acesse o grupo criado, e no topo dele você encontrará a opção indicando o nº de participantes. Ao clicar nessa opção, abrirá uma barra na lateral direita com todos os membro. Para adicionar alguém é só clicar na opção <strong>Convidar Mais</strong>, e para excluir alguém basta clicar no X ao lado do contato.                                            
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Até quantos usuários podem ingressar em uma mesma videoconferência?
                                        </div>
                                        <div class="banner-cs-body">
                                            Ainda estamos em versão beta, mas cada videoconferência suporta até 20 usuários ativos.                                         
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Como faço para ingressar em uma reunião que já havia começado?
                                        </div>
                                        <div class="banner-cs-body">
                                            É fácil ;) Basta ingressar na conversa em que a reunião está acontecendo e selecionar a opção de vídeo ou voz que se encontra no canto superior direito do chat. Isso permite com que você entre em reuniões mesmo após o início da mesma.                                         
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="v-pills-comofunciona" role="tabpanel" aria-labelledby="v-pills-comofunciona-tab">

                                <div class="card-cs-faq-title">
                                    Configurações
                                </div>
                                <div class="card-cs-faq-body">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Existem requisitos básicos para utilizar o Squad?
                                        </div>
                                        <div class="banner-cs-body">
                                           O único requisito para utilizar o Squad com toda a sua potência é estar conectado à uma boa conexão de internet.
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Quais sistemas operacionais o Squad sustenta?
                                        </div>
                                        <div class="banner-cs-body">
                                            O Squad funciona em todos os sistemas operacionais - até os mais antigos!                                            
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            O Squad funciona em todos os navegadores de internet?
                                        </div>
                                        <div class="banner-cs-body">
                                            O Squad, em sua primeira versão, funciona tanto no Google Chrome como no Mozilla Firefox. Mas não se preocupe ;) estamos trabalhando a todo vapor para habilitar nossa plataforma em todos os navegadores disponíveis.                                         
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Qual o tipo de conexão a internet que tenho que utilizar para acessar o Squad?
                                        </div>
                                        <div class="banner-cs-body">
                                            Para acessar o Squad, você poderá utilizar sua conexão via Wi-Fi, 3G ou 4G. Nossa recomendação para videoconferências, contudo, é ter disponível um Wi-Fi de, no mínimo, 5MB, ou um 4G com alta velocidade.                                         
                                        </div>
                                    </div>
                                    <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Como faço para ajustar a qualidade de meu vídeo em calls?
                                        </div>
                                        <div class="banner-cs-body">
                                            O Squad está configurado para operar com uma tecnologia avançada que detecta automaticamente o tipo de banda larga que você está conectado e ajusta a qualidade do seu vídeo para realização de calls.                                        
                                        </div>
                                    </div>
                                     <hr class="divider">
                                    <div class="banner-cs">
                                        <div class="banner-cs-title-sm">
                                            Posso realizar uma videoconferência apenas utilizando o recurso de voz?
                                        </div>
                                        <div class="banner-cs-body">
                                            Sim. Ao receber um convite para videoconferência, basta clicar no ícone do telefone. Assim, você ingressará na call apenas com o recurso de voz ativo.                                        
                                        </div>
                                    </div>
                                </div>

                            </div>
                           
                        </div>

                    </div>
                    <div class="card-cs-faq">
                        <div class="card-cs-faq-body">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="banner-cs m-3">
                                        <div class="banner-cs-title-md text-center mb-1">
                                            Ainda tem dúvidas?
                                        </div>
                                        <div class="banner-cs-body text-center">
                                          
                                            <div class="d-flex justify-content-center mt-3">
                                                <a href="https://www.youtube.com/watch?v=YyIPpkD--q0&list=PLGxTDuY5JQ7IgrN-luQkHiYKYI5DYWUiQ" target="_blank" class="header-one__btn btnLight">Assista aos vídeos</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="banner-cs m-3">
                                        <div class="banner-cs-title-md text-center mb-1">
                                            Precisa de ajuda?
                                        </div>
                                        <div class="banner-cs-body text-center">
                                           
                                            <div class="d-flex justify-content-center mt-3">
                                                <a href="https://materiais.citrus.cx/citrus-squad-fale-conosco" target="_blank" class="header-one__btn btnLight">Entre em contato</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
	</div>
</template>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>
<script>

export default {
	
	metaInfo: {
      title: 'FAQ Squad',
      keywords: 'colaboração, trabalho remoto, simultâneo, à distância, trabalho em equipe'
    },
	props: {
		hero: {
			type: Object,
			default: () => ({})
		},
		
	},
	data: () => ({
		active: null,
		previous: null,
		initialized: [],
	}),
	mounted() {
		this.active = this.selected
	}
}
</script>

<style lang="scss">

.sq-faq{
    .page-faq-01 {
        position: relative;
        margin-top: 160px;
    }

    .page-faq-01 .bubble-1 {
        position: absolute;
        top: -13%;
        left: 10%;
    }

    .page-faq-01 .bubble-2 {
        position: absolute;
        top: 100%;
        left: 90%;
    }

    .page-faq-01 .bubble-3 {
        position: absolute;
        top: -18%;
        left: 90%;
    }

    .page-faq-01 .bubble-4 {
        position: absolute;
        top: 50%;
        left: 75%;
    }

    .page-faq-01 .bubble-5 {
        position: absolute;
        top: 42%;
        left: 20%;
    }

    .page-faq-01 [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }

    .page-faq-02 {
        position: relative;
        margin-bottom: 100px;
    }

    .page-faq-02 .bubble-2 {
        position: absolute;
        top: 100%;
        left: 90%;
    }

    .page-faq-02 .bubble-4 {
        position: absolute;
        top: 50%;
        left: 88%;
    }

    .page-faq-02 .bubble-5 {
        position: absolute;
        top: 44%;
        left: 13%;
    }

    .page-faq-02 .bubble-6 {
        position: absolute;
        top: 100%;
        left: 6%;
    }

    .page-faq-02 [class*=bubble-] {
        position: absolute;
        -webkit-animation-name: bubbleMover;
        animation-name: bubbleMover;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-perspective: 400px;
        perspective: 400px;
    }

    .card-cs-faq {
        padding: 30px;
        margin-bottom: 10px;
        border-radius: 4px;
        background-color: white;
        -webkit-box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
        box-shadow: 10px 10px 60px 10px rgba(46, 61, 98, 0.1);
        overflow: hidden;
        position: relative;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
    }

    .card-cs-faq-title {
        margin: 0;
        font-weight: bold;
        color: #9cb72e;
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .card-cs-faq-body {
        margin: 0;
    }

    .card-cs-faq-body .banner-cs {
        padding: 0 !important;
    }

    .card-cs-faq-body .banner-cs-title-md {
        font-size: 20px;
        line-height: 30px;
    }

    .card-cs-faq-body .banner-cs-title-sm {
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 10px;
    }

    .card-cs-faq-body .banner-cs-body {
        font-size: 12px;
        line-height: 21px;
        margin: 0;
        margin-bottom: 10px;
    }

    .card-cs-faq-body .divider {
        margin: 25px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.03);
    }

    .card-cs-faq-body .header-one__btn {
        padding: 14px 35px;
    }

    .menu-faq {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        list-style-type: none;
        margin-top: 20px;
    }

    .menu-faq .nav-link {
        margin-top: 8px;
        color: rgb(141, 147, 153);
        transition-duration: .2s;
    }

    .menu-faq .nav-link:hover {
        font-size: 16px;
        transition-duration: .2s;
    }

    .menu-faq .nav-link.active {
        color: #9cb72e;
        transition-duration: .2s;
        font-size: 15px;
        background-color: transparent;
    }

    .menu-faq .nav-link.active::before { 
        margin-right: 3px;
        color:rgb(175, 180, 185);
    }
}
</style>
